import { IQuestion } from "../../../interfaces/domain/IQuestion";
import EditQuestionForm from "./EditQuestionForm";

interface IEditQuestionProps {
  question: IQuestion;
}

const EditQuestionFormContainer: React.FunctionComponent<IEditQuestionProps> = (props) => {
  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-6 mb-3">
          <EditQuestionForm question={props.question}></EditQuestionForm>
        </div>

      </div>
    </div>
  )
}

export default EditQuestionFormContainer;

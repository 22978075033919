import React from "react";
import { useParams } from "react-router-dom";

import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import EditRegion from "../components/Region/EditRegion/EditRegion";

const RegionPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditRegion id={Number(id)}></EditRegion>;
};

export default RegionPage;

import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { DELETE_CAPTIVITY_MUTATION } from "../../../graphql/mutations";
import { GET_PERSON_QUERY } from "../../../graphql/queries";
import { GetFormattedDateByIDateObject } from "../../../helpers/dateHelpers";
import { GetReadableEndingName, GetReadableverifiedFromSourceName, GetReadableCertaintyName } from "../../../helpers/enumHelpers";
import { ICaptivity } from "../../../interfaces/domain/ICaptivity";
import { IDeleteCaptivityMutationInput, IDeleteCaptivityMutationResult } from "../../../interfaces/mutations/IDeleteCaptivityMutation";
import EditCaptivityForm from "../EditCaptivity/EditCaptivityForm";
import EventsList from "../../Event/Events/EventsList";
import { IEvent } from "../../../interfaces/domain/IEvent";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface CaptivitiesListProps {
  captivities: Array<ICaptivity>;
  events: Array<IEvent>;
  personId: number;
}

const CaptivitiesList: React.FunctionComponent<CaptivitiesListProps> = (props) => {
  const [selectedEditCaptivity, setSelectedEditCaptivity] = useState<ICaptivity | undefined>(undefined);
  const [deleteCaptivity] = useMutation<IDeleteCaptivityMutationResult, IDeleteCaptivityMutationInput>(DELETE_CAPTIVITY_MUTATION);

  const handleCloseModal = () => setSelectedEditCaptivity(undefined);
  const handleShowModal = (captivity: ICaptivity) => setSelectedEditCaptivity(captivity);

  const onDeleteCaptivity = async (captivityId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette fangeoppholdet?`);

    if (deleteConfirmed) {
      await deleteCaptivity({
        variables: {
          input: {
            id: captivityId,
          },
        },
        refetchQueries: [{ query: GET_PERSON_QUERY, variables: { filter: { id: props.personId } } }],
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteCaptivity)) {
            handleMutationSuccess(result.data);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  return (
    <div className="CaptivitiesList">
      <h3 className="mb-4">Fangenskap</h3>

      <h5>Arrestasjon</h5>
      <EventsList events={props.events} capativityRelatedEvent={true} personId={props.personId} />

      <h5>Fangeoppphold</h5>

      <table className="table table-hover table-bordered mb-4">
        <thead>
          <tr>
            <th scope="col" style={{ width: "80px" }}>
              #
            </th>
            <th scope="col">Fangested</th>
            <th scope="col" style={{ width: "220px" }}>
              Fra dato
            </th>
            <th scope="col" style={{ width: "220px" }}>
              Til dato
            </th>
            <th scope="col" style={{ width: "160px" }}>
              Avslutningstype
            </th>
            <th scope="col" style={{ width: "100px" }}>
              Kryssjekk
            </th>
            <th scope="col" style={{ width: "140px" }}></th>
          </tr>
        </thead>

        <tbody>
          {props.captivities.map(
            (captivity) =>
              !captivity.capativityAfterRelease && (
                <tr key={captivity.id}>
                  <td>{captivity.displayOrder}</td>
                  <td>{captivity.prisonCamp.name}</td>
                  <td>
                    {GetFormattedDateByIDateObject(captivity.startDate)} ({GetReadableCertaintyName(captivity.startDate.certainty)})
                  </td>
                  <td>
                    {GetFormattedDateByIDateObject(captivity.endDate)} ({GetReadableCertaintyName(captivity.endDate.certainty)})
                  </td>
                  <td>{GetReadableEndingName(captivity.ending)}</td>
                  <td>{GetReadableverifiedFromSourceName(captivity.verifiedFromSource)}</td>
                  <td className="text-right">
                    <button
                      className="btn btn-sm btn-secondary mr-2"
                      onClick={() => {
                        handleShowModal(captivity);
                      }}>
                      Rediger
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        onDeleteCaptivity(captivity.id);
                      }}>
                      <FiTrash2 />
                    </button>
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>

      <h5>Etter befrielsen</h5>

      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col" style={{ width: "80px" }}>
              #
            </th>
            <th scope="col">Fangested</th>
            <th scope="col" style={{ width: "220px" }}>
              Fra dato
            </th>
            <th scope="col" style={{ width: "220px" }}>
              Til dato
            </th>
            <th scope="col" style={{ width: "160px" }}>
              Avslutningstype
            </th>
            <th scope="col" style={{ width: "100px" }}>
              Kryssjekk
            </th>
            <th scope="col" style={{ width: "140px" }}></th>
          </tr>
        </thead>

        <tbody>
          {props.captivities.map(
            (captivity) =>
              captivity.capativityAfterRelease && (
                <tr key={captivity.id}>
                  <td>{captivity.displayOrder}</td>
                  <td>{captivity.prisonCamp.name}</td>
                  <td>
                    {GetFormattedDateByIDateObject(captivity.startDate)} ({GetReadableCertaintyName(captivity.startDate.certainty)})
                  </td>
                  <td>
                    {GetFormattedDateByIDateObject(captivity.endDate)} ({GetReadableCertaintyName(captivity.endDate.certainty)})
                  </td>
                  <td>{GetReadableEndingName(captivity.ending)}</td>
                  <td>{GetReadableverifiedFromSourceName(captivity.verifiedFromSource)}</td>
                  <td className="text-right">
                    <button
                      className="btn btn-sm btn-secondary mr-2"
                      onClick={() => {
                        handleShowModal(captivity);
                      }}>
                      Rediger
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        onDeleteCaptivity(captivity.id);
                      }}>
                      <FiTrash2 />
                    </button>
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>

      {selectedEditCaptivity && (
        <Modal show={!!selectedEditCaptivity} dialogClassName="vw-100" size="xl" onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Rediger fangeopphold</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditCaptivityForm
              captivity={selectedEditCaptivity}
              personId={props.personId}
              handleCloseModal={handleCloseModal}></EditCaptivityForm>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default CaptivitiesList;

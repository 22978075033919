import React from "react";
import Search from "../../_common/Search/Search";
import { SearchFilterObjectTypeEnum } from "../../../interfaces/algolia/ISearchResultHit";
import { IPageSource } from "../../../interfaces/domain/common/ISearch";

interface ReferenceHistoriesProps {
  referenceId: number;
}

const ReferenceHistories: React.FunctionComponent<ReferenceHistoriesProps> = (props) => {
  const historiesSearchFilter = `${SearchFilterObjectTypeEnum.History} AND references.id:${props.referenceId}`;
  const pageSource: IPageSource = { sourceType: "reference", id: props.referenceId };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="shadow-sm card">
            <div className="card-body">
              <h5 className="mb-3">Tilknyttede historier</h5>
              <Search pageSource={pageSource} defaultFilters={historiesSearchFilter} hideSearchFilter hideSearchBox></Search>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferenceHistories;

import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { GET_EVENT_TYPE_ROUTE } from "../../../lib/Router/routes";
import { GET_EVENT_TYPES_QUERY } from "../../../graphql/queries";
import { IGetEventTypesQueryResult } from "../../../interfaces/queries/IGetEventTypesQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const EventTypesList: React.FunctionComponent = (props) => {
  const { loading, error, data } = useQuery<IGetEventTypesQueryResult>(GET_EVENT_TYPES_QUERY);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.eventTypes || !data.eventTypes.length) return <DataNotFound />;

  return (
    <div className="EventTypesList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Navn</th>
          </tr>
        </thead>

        <tbody>
          {data.eventTypes.map((eventType) => (
            <tr key={eventType.id}>
              <td>
                <Link to={GET_EVENT_TYPE_ROUTE(eventType.id)}>{eventType.name}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventTypesList;

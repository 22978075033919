import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../lib/Router/routes";
import { UPDATE_REGION_MUTATION } from "../../../graphql/mutations";
import { GET_REGION_QUERY, GET_COUNTRY_QUERY } from "../../../graphql/queries";
import { regionSchema } from "../../../helpers/validators/regionSchema";
import { IRegion } from "../../../interfaces/domain/IRegion";
import { IRegionForm } from "../../../interfaces/forms/IRegionForm";
import { IUpdateRegionMutationInput, IUpdateRegionMutationResult } from "../../../interfaces/mutations/IUpdateRegionMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import CountriesSelectInput from "../../Country/CountriesSelectInput/CountriesSelectInput";
import { IGetCountryQueryResult, IGetCountryQueryFilter } from "../../../interfaces/queries/IGetCountryQuery";
import QueryError from "../../_common/QueryError/QueryError";
import Loading from "../../_common/Loading/Loading";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import Select from "react-select";
import { getYearOfOriginSelectOptions } from "../../../helpers/locationHelpers";

interface IEditRegionFormProps {
  region: IRegion;
}

const EditRegionForm: React.FunctionComponent<IEditRegionFormProps> = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState, getValues, control } = useForm<IRegionForm>({
    validationSchema: regionSchema,
    mode: "onChange",
    defaultValues: {
      name: props.region.name,
      code: props.region.code,
      yearOfOrigin: mapToSelectOption(props.region.yearOfOrigin, props.region.yearOfOrigin.toString()),
      country: mapToSelectOption(props.region.country.id, props.region.country.name),
    },
  });

  const [updateRegion] = useMutation<IUpdateRegionMutationResult, IUpdateRegionMutationInput>(UPDATE_REGION_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateRegion({
      variables: {
        input: {
          id: props.region.id,
          name: formValues.name,
          code: formValues.code,
          yearOfOrigin: formValues.yearOfOrigin.value as number,
          countryId: formValues.country.value as number,
        },
      },
      refetchQueries: [{ query: GET_REGION_QUERY, variables: { filter: { id: props.region.id } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateRegion)) {
          handleMutationSuccess(result.data);

          history.push(ROUTES.ADMIN.REGIONS_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  const { loading, error, data } = useQuery<IGetCountryQueryResult, IGetCountryQueryFilter>(GET_COUNTRY_QUERY, {
    variables: { filter: { id: props.region.country.id } },
  });

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.country) return <DataNotFound />;

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="yearOfOrigin">
          Opprinnelsesår
        </label>

        <Controller
          as={Select}
          control={control}
          name="yearOfOrigin"
          value={getValues().yearOfOrigin}
          options={getYearOfOriginSelectOptions()}
          placeholder="Velg..."
          isDisabled
        />
      </div>

      <CountriesSelectInput
        fieldName="country"
        label="Land"
        required
        control={control}
        errors={errors}
        currentValue={getValues().country}
        selectedYearOfOrigin={getValues().yearOfOrigin.value as number}
      />

      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <div className="form-group">
        <label className="required-field" htmlFor="code">
          Fylkekode
        </label>
        <input type="text" name="code" ref={register} className={`form-control ${errors.code ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.code}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditRegionForm;

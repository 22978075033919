import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { DELETE_INDUSTRY_MUTATION } from "../../../graphql/mutations";
import { IDeleteIndustryMutationResult, IDeleteIndustryMutationInput } from "../../../interfaces/mutations/IDeleteIndustryMutation";
import { GET_INDUSTRY_QUERY } from "../../../graphql/queries";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { IGetIndustryQueryFilter, IGetIndustryQueryResult } from "../../../interfaces/queries/IGetIndustryQuery";
import { ROUTES } from "../../../lib/Router/routes";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import EditIndustryForm from "./EditIndustryForm";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GET_PROFESSION_ROUTE } from "../../../lib/Router/routes";

interface IEditIndustryProps {
  id: number;
}

const EditIndustry: React.FunctionComponent<IEditIndustryProps> = (props) => {
  const history = useHistory();

  const { loading, error, data } = useQuery<IGetIndustryQueryResult, IGetIndustryQueryFilter>(GET_INDUSTRY_QUERY, {
    variables: { filter: { id: props.id } },
  });

  const [deleteIndustry] = useMutation<IDeleteIndustryMutationResult, IDeleteIndustryMutationInput>(DELETE_INDUSTRY_MUTATION);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.industry) return <DataNotFound />;

  const onDeleteIndustry = async (industryId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette bransje: ${data.industry.name}?`);

    if (deleteConfirmed) {
      await deleteIndustry({
        variables: {
          input: {
            id: industryId,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteIndustry)) {
            handleMutationSuccess(result.data);
            history.push(ROUTES.ADMIN.INDUSTRIES_ROUTE);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">{data.industry.name}</h1>

        <div>
          <ButtonToolbar className="d-flex justify-content-end">
            <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
              <Dropdown.Item
                eventKey="1"
                disabled={data.industry.canBeDeleted === false}
                className={data.industry.canBeDeleted === false ? "" : "text-danger"}
                onClick={() => {
                  onDeleteIndustry(data.industry.id);
                }}>
                <FiTrash2 /> Slett bransje
              </Dropdown.Item>
            </DropdownButton>
          </ButtonToolbar>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="card shadow-sm">
            <div className="card-body">
              <EditIndustryForm industry={data.industry}></EditIndustryForm>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="mb-3">Tilknyttede yrker</h5>
              {data.industry.industryProfessions ? (
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Navn</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.industry.industryProfessions.map((profession: any) => (
                      <tr key={profession.id}>
                        <td>
                          <Link to={GET_PROFESSION_ROUTE(profession.id)}>{profession.name}</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Ingen yrker er koblet til denne bransjen</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditIndustry;

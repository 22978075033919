import { useMutation } from "@apollo/client";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { UPDATE_CAPTIVITY_MUTATION } from "../../../graphql/mutations";
import { GET_PERSON_QUERY } from "../../../graphql/queries";
import { GetEndingEnumAsSelectOptions, GetReadableEndingName } from "../../../helpers/enumHelpers";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import { captivitySchema } from "../../../helpers/validators/captivitySchema";
import { IEndingEnum } from "../../../interfaces/domain/enums/IEndingEnum";
import { ICaptivity } from "../../../interfaces/domain/ICaptivity";
import { IHistoryOption } from "../../../interfaces/domain/IHistory";
import { ICaptivityForm } from "../../../interfaces/forms/ICaptivityForm";
import { IUpdateCaptivityMutationInput, IUpdateCaptivityMutationResult } from "../../../interfaces/mutations/IUpdateCaptivityMutation";
import HistoriesSelectInput from "../../History/HistoriesSelectInput/HistoriesSelectInput";
import PrisonCampSelectInput from "../../PrisonCamp/PrisonCampSelectInput/PrisonCampSelectInput";
import DateFields from "../../_common/Form/DateFields/DateFields";
import FieldError from "../../_common/Form/FieldError/FieldError";
import FormSubTitle from "../../_common/Form/FormSubTitle/FormSubTitle";
import PublicRemarkField from "../../_common/Form/RemarksFields/PublicRemarkField";

interface IEditCaptivityFormProps {
  captivity: ICaptivity;
  personId: number;
  handleCloseModal: () => void;
}

const EditCaptivityForm: React.FunctionComponent<IEditCaptivityFormProps> = (props) => {
  const { register, handleSubmit, errors, formState, getValues, control } = useForm<ICaptivityForm>({
    validationSchema: captivitySchema,
    mode: "onChange",
    defaultValues: {
      prisonerNumber: props.captivity.prisonerNumber,
      startDate: props.captivity.startDate,
      endDate: props.captivity.endDate,
      remarks: props.captivity.remarks,
      ending: mapToSelectOption(props.captivity.ending, GetReadableEndingName(props.captivity.ending)),
      verifiedFromSource: props.captivity.verifiedFromSource,
      capativityAfterRelease: props.captivity.capativityAfterRelease,
      displayOrder: props.captivity.displayOrder,
      prisonCamp: { value: props.captivity.prisonCamp.id, label: props.captivity.prisonCamp.name },
      histories: props.captivity.histories.map((history) => {
        return mapToSelectOption(history.id, history.title);
      }),
    },
  });

  const [updateCaptivity] = useMutation<IUpdateCaptivityMutationResult, IUpdateCaptivityMutationInput>(UPDATE_CAPTIVITY_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateCaptivity({
      variables: {
        input: {
          id: props.captivity.id,
          prisonerNumber: formValues.prisonerNumber,
          startDate: formValues.startDate,
          endDate: formValues.endDate,
          remarks: formValues.remarks,
          ending: formValues.ending.value as IEndingEnum,
          verifiedFromSource: formValues.verifiedFromSource,
          capativityAfterRelease: formValues.capativityAfterRelease,
          displayOrder: formValues.displayOrder,
          prisonCamp: { id: formValues.prisonCamp.value as number, name: formValues.prisonCamp.label },
          histories: formValues.histories?.map((history) => {
            return { id: history.value, title: history.label } as IHistoryOption;
          }),
        },
      },
      refetchQueries: [{ query: GET_PERSON_QUERY, variables: { filter: { id: props.personId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateCaptivity)) {
          handleMutationSuccess(result.data);

          props.handleCloseModal();
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-lg-3">
          <div className="form-group">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                placeholder="verifiedFromSource"
                id="verifiedFromSource"
                name="verifiedFromSource"
                ref={register}
              />
              <label className="form-check-label" htmlFor="verifiedFromSource">
                Kryssjekket mot primærkilde?
              </label>
            </div>
          </div>

          <div className="form-group">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                placeholder="capativityAfterRelease"
                id="capativityAfterRelease"
                name="capativityAfterRelease"
                ref={register}
              />
              <label className="form-check-label" htmlFor="capativityAfterRelease">
                Fangenskap etter befrielsen?
              </label>
            </div>
          </div>

          <div className="form-group">
            <label className="required-field" htmlFor="name">
              Visningsrekkefølge
            </label>
            <input
              type="number"
              name="displayOrder"
              ref={register}
              className={`form-control ${errors.displayOrder ? "is-invalid" : "valid"}`}
            />

            <FieldError error={errors.displayOrder}></FieldError>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col">
          <PrisonCampSelectInput
            fieldName="prisonCamp"
            required
            label="Fangested"
            control={control}
            errors={errors}
            currentValue={getValues().prisonCamp}
          />
        </div>

        <div className="col form-group">
          <label htmlFor="prisonerNumber">Fangenummer</label>
          <input
            type="text"
            name="prisonerNumber"
            ref={register}
            className={`form-control ${errors.prisonerNumber ? "is-invalid" : "valid"}`}
          />

          <FieldError error={errors.prisonerNumber}></FieldError>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-6">
          <label htmlFor="startDate">Når startet fangeoppholdet?</label>
          <DateFields fieldName="startDate" register={register} errors={errors.startDate}></DateFields>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-6">
          <label htmlFor="endDate">Når ble fangeoppholdet avsluttet?</label>
          <DateFields fieldName="endDate" register={register} errors={errors.endDate}></DateFields>
        </div>

        <div className="col-lg-6">
          <div className="form-group">
            <label className="required-field" htmlFor="ending">
              Hvordan avsluttet fangeoppholdet?
            </label>

            <Controller
              as={Select}
              control={control}
              name="ending"
              value={getValues().ending}
              options={GetEndingEnumAsSelectOptions()}
              placeholder="Velg..."
            />
          </div>
        </div>
      </div>

      <FormSubTitle>Notater</FormSubTitle>
      <PublicRemarkField register={register} errors={errors}></PublicRemarkField>

      <div className="form-group">
        <FormSubTitle>Tilknyttede historier</FormSubTitle>

        <HistoriesSelectInput fieldName="histories" control={control} errors={errors} currentValues={getValues().histories} />
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditCaptivityForm;

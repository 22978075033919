import { ApolloLink, ApolloProvider, concat, HttpLink, InMemoryCache } from "@apollo/client";
import { ApolloClient, DefaultOptions } from "@apollo/client";
import React from "react";
import { ENVIRONMENT_API_PATH } from "../helpers/environmentHelpers";

const defaultApolloClientOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

const cache = new InMemoryCache();

const link = new HttpLink({
  uri: ENVIRONMENT_API_PATH,
  credentials: "include",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, link),
  cache: cache,
  defaultOptions: defaultApolloClientOptions,
});

const ApolloWrapper: React.FunctionComponent = (props) => {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default ApolloWrapper;

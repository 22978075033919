import React from "react";
import { IAudit } from "../../../interfaces/domain/base/IAuditable";
import { GetFormattedDateTimeLong } from "../../../helpers/dateHelpers";

interface AuditProps {
  audit: IAudit;
}

const Audit: React.FunctionComponent<AuditProps> = props => {
  return (
    <div className="text-muted">
      <small>Sist oppdatert: {GetFormattedDateTimeLong(props.audit.updatedAt)}</small>
      {props.audit.updatedBy && <small> Av: {props.audit.updatedBy}</small>}
    </div>
  );
};

export default Audit;

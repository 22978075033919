import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Button, ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { NavLink, useHistory } from "react-router-dom";
import {
  GET_PERSON_CAPTIVITIES_ROUTE,
  GET_PERSON_EVENTS_ROUTE,
  GET_PERSON_ROUTE,
  GET_PERSON_REFERENCES_ROUTE,
  ROUTES,
} from "../../../lib/Router/routes";
import { DELETE_PERSON_MUTATION, TOGGLE_PUBLISH_PERSON_MUTATION } from "../../../graphql/mutations";
import { GET_PERSON_QUERY } from "../../../graphql/queries";
import { GetPersonFullName } from "../../../helpers/personHelpers";
import { IDeletePersonMutationInput, IDeletePersonMutationResult } from "../../../interfaces/mutations/IDeletePersonMutation";
import {
  ITogglePublishPersonMutationResult,
  ITogglePublishPersonMutationInput,
} from "../../../interfaces/mutations/ITogglePublishPersonMutation";
import { IGetPersonQueryFilter, IGetPersonQueryResult } from "../../../interfaces/queries/IGetPersonQuery";
import { PersonRouter } from "../../../lib/Router/Router";
import Audit from "../../_common/Audit/Audit";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import PublishStatus from "../../_common/PublishStatus/PublishStatus";
import QueryError from "../../_common/QueryError/QueryError";
import { useAuth } from "../../../lib/hooks/useAuth";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface IEditPersonProps {
  id: number;
}

const EditPerson: React.FunctionComponent<IEditPersonProps> = (props) => {
  const { isAdmin } = useAuth();
  const history = useHistory();

  const { loading, error, data } = useQuery<IGetPersonQueryResult, IGetPersonQueryFilter>(GET_PERSON_QUERY, {
    variables: { filter: { id: props.id } },
  });

  const [deletePerson] = useMutation<IDeletePersonMutationResult, IDeletePersonMutationInput>(DELETE_PERSON_MUTATION);
  const [togglePublishPerson, { loading: togglePublishPersonLoading }] = useMutation<
    ITogglePublishPersonMutationResult,
    ITogglePublishPersonMutationInput
  >(TOGGLE_PUBLISH_PERSON_MUTATION);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.person) return <DataNotFound />;

  const onDeletePerson = async (personId: number) => {
    const deleteConfirmed = window.confirm(
      `Er du sikker på at du vil slette person: ${GetPersonFullName(data.person.firstName, data.person.middleName, data.person.lastName)}?`
    );

    if (deleteConfirmed) {
      await deletePerson({
        variables: {
          input: {
            id: personId,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deletePerson)) {
            handleMutationSuccess(result.data);
            history.push(ROUTES.AUTHORIZED.PERSONS_ROUTE);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  const onTogglePublishPerson = async (personId: number, publish: boolean) => {
    await togglePublishPerson({
      variables: {
        input: {
          id: personId,
          publish: publish,
        },
      },
      refetchQueries: [{ query: GET_PERSON_QUERY, variables: { filter: { id: personId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.togglePublishPerson)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2">
        <h1 className="h2">{GetPersonFullName(data.person.firstName, data.person.middleName, data.person.lastName)}</h1>

        <div>
          <ButtonToolbar className="d-flex justify-content-end">
            {data.person.publishStatus.isPublished ? (
              <Button
                variant="warning"
                size="sm"
                className="mr-1"
                disabled={togglePublishPersonLoading}
                onClick={(event: any) => {
                  event.preventDefault();
                  onTogglePublishPerson(data.person.id, !data.person.publishStatus.isPublished);
                }}>
                {togglePublishPersonLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span> Avpubliserer...</span>
                  </>
                ) : (
                  <span> Avpubliser</span>
                )}
              </Button>
            ) : (
              <Button
                variant="success"
                size="sm"
                className="mr-1"
                disabled={togglePublishPersonLoading}
                onClick={(event: any) => {
                  event.preventDefault();
                  onTogglePublishPerson(data.person.id, !data.person.publishStatus.isPublished);
                }}>
                {togglePublishPersonLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span> Publiserer...</span>
                  </>
                ) : (
                  <span> Publiser</span>
                )}
              </Button>
            )}

            {isAdmin() && (
              <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
                <Dropdown.Item
                  className="text-danger"
                  eventKey="1"
                  onClick={() => {
                    onDeletePerson(data.person.id);
                  }}>
                  <FiTrash2 /> Slett person
                </Dropdown.Item>
              </DropdownButton>
            )}
          </ButtonToolbar>

          <Audit audit={data.person.audit}></Audit>
          <PublishStatus publishStatus={data.person.publishStatus}></PublishStatus>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <NavLink to={GET_PERSON_ROUTE(data.person.id)} exact className="nav-link" activeClassName="active bg-white">
                Generelt
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={GET_PERSON_CAPTIVITIES_ROUTE(data.person.id)} exact className="nav-link" activeClassName="active bg-white">
                Fangenskap
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={GET_PERSON_EVENTS_ROUTE(data.person.id)} exact className="nav-link" activeClassName="active bg-white">
                Andre hendelser
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={GET_PERSON_REFERENCES_ROUTE(data.person.id)} exact className="nav-link" activeClassName="active bg-white">
                Referanser
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <PersonRouter person={data.person}></PersonRouter>
        </div>
      </div>
    </div>
  );
};

export default EditPerson;

import { useQuery } from "@apollo/client";
import React from "react";
import { Controller } from "react-hook-form";
import { Control, FieldError, NestDataObject } from "react-hook-form/dist/types";
import Select from "react-select";
import { GET_COUNTRIES_QUERY } from "../../../graphql/queries";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import { ISelectOption } from "../../../interfaces/domain/common/ISelectOption";
import { IGetCountriesQueryResult } from "../../../interfaces/queries/IGetCountriesQuery";
import FieldValidationError from "../../_common/Form/FieldError/FieldError";
import QueryError from "../../_common/QueryError/QueryError";

interface CountriesSelectInputProps {
  selectedYearOfOrigin?: number;
  disabled?: boolean;
  requiredField?: boolean;
  isClearable?: boolean;
  fieldName: string;
  errors: NestDataObject<any, FieldError>;
  currentValue?: ISelectOption;
  control: Control<any>;
  label?: string;
  required?: boolean;
  onChange?: (value: ISelectOption) => void;
}

const CountriesSelectInput: React.FunctionComponent<CountriesSelectInputProps> = (props) => {
  const { loading, error, data } = useQuery<IGetCountriesQueryResult>(GET_COUNTRIES_QUERY, {
    variables: { filter: { yearOfOrigin: props.selectedYearOfOrigin } },
  });

  if (error) return <QueryError apolloError={error} />;

  const countries = !data || !data.countries || !data.countries.length ? [] : data.countries;

  return (
    <div className="form-group">
      {props.label && (
        <label className={props.required ? "required-field" : ""} htmlFor={props.fieldName}>
          {props.label}
        </label>
      )}

      <Controller
        as={Select}
        control={props.control}
        name={props.fieldName}
        value={props.currentValue ? props.currentValue : null}
        options={countries.map((country) => mapToSelectOption(country.id, country.name))}
        required={props.required}
        placeholder="Velg..."
        isLoading={loading}
        loadingMessage={() => {
          return "Henter...";
        }}
        noOptionsMessage={() => {
          return "Ingen valg";
        }}
        isDisabled={props.disabled}
        isClearable={props.isClearable}
        onChange={([value]) => {
          if (props.onChange) {
            props.onChange(value);
          }

          return value;
        }}
      />

      <FieldValidationError error={props.errors[props.fieldName]}></FieldValidationError>
    </div>
  );
};

export default CountriesSelectInput;

import { useParams } from "react-router-dom";
import TeachingSubjects from "../components/TeachingSubject/TeachingSubjects/TeachingSubjects"
import PageNotFound from "../components/_common/PageNotFound/PageNotFound";

const TeachingSubjectsPage: React.FunctionComponent = () => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }


  return (<TeachingSubjects teachingCategoryId={Number(id)} />
  )
}

export default TeachingSubjectsPage;

import { useQuery } from "@apollo/client";
import React from "react";
import { GET_DISTRICT_QUERY } from "../../../graphql/queries";
import { IGetDistrictQueryFilter, IGetDistrictQueryResult } from "../../../interfaces/queries/IGetDistrictQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import EditDistrictForm from "./EditDistrictForm";

interface IEditDistrictProps {
  id: number;
}

const EditDistrict: React.FunctionComponent<IEditDistrictProps> = (props) => {
  const { loading, error, data } = useQuery<IGetDistrictQueryResult, IGetDistrictQueryFilter>(GET_DISTRICT_QUERY, {
    variables: { filter: { id: props.id } },
  });

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.district) return <DataNotFound />;

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">{data.district.name}</h1>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="card shadow-sm">
            <div className="card-body">
              <EditDistrictForm district={data.district}></EditDistrictForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDistrict;

import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";

import { GET_PROFESSION_ROUTE } from "../../../lib/Router/routes";
import { GET_PROFESSIONS_QUERY } from "../../../graphql/queries";
import { IGetProfessionsQueryResult } from "../../../interfaces/queries/IGetProfessionsQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const ProfessionsList: React.FunctionComponent = (props) => {
  const { loading, error, data } = useQuery<IGetProfessionsQueryResult>(GET_PROFESSIONS_QUERY);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.professions || !data.professions.length) return <DataNotFound />;

  return (
    <div className="ProfessionsList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Navn</th>
          </tr>
        </thead>

        <tbody>
          {data.professions.map((profession) => (
            <tr key={profession.id}>
              <td>
                <Link to={GET_PROFESSION_ROUTE(profession.id)}>{profession.name}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProfessionsList;

import { useMutation } from "@apollo/client";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { UPDATE_DISTRICT_MUTATION } from "../../../graphql/mutations";
import { GET_DISTRICT_QUERY } from "../../../graphql/queries";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { getYearOfOriginSelectOptions } from "../../../helpers/locationHelpers";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import { districtSchema } from "../../../helpers/validators/districtSchema";
import { IDistrict } from "../../../interfaces/domain/IDistrict";
import { IDistrictForm } from "../../../interfaces/forms/IDistrictForm";
import { IUpdateDistrictMutationInput, IUpdateDistrictMutationResult } from "../../../interfaces/mutations/IUpdateDistrictMutation";
import { ROUTES } from "../../../lib/Router/routes";
import CountriesSelectInput from "../../Country/CountriesSelectInput/CountriesSelectInput";
import RegionsSelectInput from "../../Region/RegionsSelectInput/RegionsSelectInput";
import FieldError from "../../_common/Form/FieldError/FieldError";

interface IEditDistrictFormProps {
  district: IDistrict;
}

var countryChanged: boolean = false;

const EditDistrictForm: React.FunctionComponent<IEditDistrictFormProps> = (props) => {
  const history = useHistory();
  const [updateDistrict] = useMutation<IUpdateDistrictMutationResult, IUpdateDistrictMutationInput>(UPDATE_DISTRICT_MUTATION);

  const { register, handleSubmit, errors, formState, watch, getValues, control, setValue } = useForm<IDistrictForm>({
    validationSchema: districtSchema,
    mode: "onChange",
    defaultValues: {
      name: props.district.name,
      code: props.district.code,
      yearOfOrigin: mapToSelectOption(props.district.yearOfOrigin, props.district.yearOfOrigin.toString()),
      country: mapToSelectOption(props.district.region.country.id, props.district.region.country.name),
      region: mapToSelectOption(props.district.region.id, props.district.region.name),
    },
  });

  const watchYearOfOrigin = watch("yearOfOrigin");
  const watchCountry = watch("country");

  const onSubmit = handleSubmit(async (formValues) => {
    await updateDistrict({
      variables: {
        input: {
          id: props.district.id,
          name: formValues.name,
          code: formValues.code,
          yearOfOrigin: formValues.yearOfOrigin.value as number,
          regionId: formValues.region.value as number,
        },
      },
      refetchQueries: [{ query: GET_DISTRICT_QUERY, variables: { filter: { id: props.district.id } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateDistrict)) {
          handleMutationSuccess(result.data);

          history.push(ROUTES.ADMIN.DISTRICTS_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="yearOfOrigin">
          Opprinnelsesår
        </label>

        <Controller
          as={Select}
          control={control}
          name="yearOfOrigin"
          value={getValues().yearOfOrigin}
          options={getYearOfOriginSelectOptions()}
          placeholder="Velg..."
          isDisabled
        />
      </div>

      <CountriesSelectInput
        fieldName="country"
        label="Land"
        required
        control={control}
        errors={errors}
        currentValue={getValues().country}
        disabled={!watchYearOfOrigin}
        selectedYearOfOrigin={getValues().yearOfOrigin.value as number}
        onChange={(value) => {
          setValue("region", null!);
        }}
      />

      <RegionsSelectInput
        fieldName="region"
        label="Fylke"
        required
        control={control}
        errors={errors}
        currentValue={getValues().region}
        disabled={!watchCountry}
        selectedYearOfOrigin={getValues().yearOfOrigin ? (getValues().yearOfOrigin.value as number) : undefined}
        countryId={getValues().country.value as number}
      />

      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <div className="form-group">
        <label className="required-field" htmlFor="code">
          Kommunekode
        </label>
        <input type="text" name="code" ref={register} className={`form-control ${errors.code ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.code}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting || countryChanged === true} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditDistrictForm;

import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { UPDATE_ROLE_MUTATION } from "../../../graphql/mutations";
import { roleSchema } from "../../../helpers/validators/roleSchema";
import { IRole } from "../../../interfaces/domain/IRole";
import { IRoleForm } from "../../../interfaces/forms/IRoleForm";
import { IUpdateRoleMutationInput, IUpdateRoleMutationResult } from "../../../interfaces/mutations/IUpdateRoleMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { ROUTES } from "../../../lib/Router/routes";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface IEditRoleFormProps {
  role: IRole;
}

const EditRoleForm: React.FunctionComponent<IEditRoleFormProps> = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState } = useForm<IRoleForm>({
    validationSchema: roleSchema,
    mode: "onChange",
    defaultValues: {
      name: props.role.name,
    },
  });

  const [updateRole] = useMutation<IUpdateRoleMutationResult, IUpdateRoleMutationInput>(UPDATE_ROLE_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateRole({
      variables: {
        input: {
          id: props.role.id,
          name: formValues.name,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateRole)) {
          handleMutationSuccess(result.data);

          history.push(ROUTES.ADMIN.ROLES_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditRoleForm;

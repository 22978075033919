import React from "react";
import { FiPlusCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { SearchFilterObjectTypeEnum } from "../../../interfaces/algolia/ISearchResultHit";
import { ROUTES } from "../../../lib/Router/routes";
import Search from "../../_common/Search/Search";

const Persons: React.FunctionComponent = (props) => {
  return (
    <div>
      <div className="flex-wrap pb-2 mb-3 d-flex justify-content-between flex-md-nowrap align-items-center border-bottom">
        <h1 className="h2">Personer</h1>
        <div className="mb-2 btn-toolbar mb-md-0">
          <NavLink to={ROUTES.AUTHORIZED.PERSON_CREATE_ROUTE} className="btn btn-sm btn-outline-dark" role="button" exact>
            <FiPlusCircle /> Legg til
          </NavLink>
        </div>
      </div>

      <Search objectType={SearchFilterObjectTypeEnum.Person}></Search>
    </div>
  );
};

export default Persons;

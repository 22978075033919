import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { UPDATE_EVENT_TYPE_MUTATION } from "../../../graphql/mutations";
import { eventTypeSchema } from "../../../helpers/validators/eventTypeSchema";
import { IEventType } from "../../../interfaces/domain/IEventType";
import { IEventTypeForm } from "../../../interfaces/forms/IEventTypeForm";
import { IUpdateEventTypeMutationInput, IUpdateEventTypeMutationResult } from "../../../interfaces/mutations/IUpdateEventTypeMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { ROUTES } from "../../../lib/Router/routes";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface IEditEventTypeFormProps {
  eventType: IEventType;
}

const EditEventTypeForm: React.FunctionComponent<IEditEventTypeFormProps> = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState } = useForm<IEventTypeForm>({
    validationSchema: eventTypeSchema,
    mode: "onChange",
    defaultValues: {
      name: props.eventType.name,
    },
  });

  const [updateEventType] = useMutation<IUpdateEventTypeMutationResult, IUpdateEventTypeMutationInput>(UPDATE_EVENT_TYPE_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateEventType({
      variables: {
        input: {
          id: props.eventType.id,
          name: formValues.name,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateEventType)) {
          handleMutationSuccess(result.data);

          history.push(ROUTES.ADMIN.EVENT_TYPES_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditEventTypeForm;

import React from "react";
import { useParams } from "react-router-dom";
import EditTeachingSubject from "../components/TeachingSubject/EditTeachingSubject/EditTeachingSubject";
import PageNotFound from "../components/_common/PageNotFound/PageNotFound";

const TeachingSubjectPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditTeachingSubject id={Number(id)}></EditTeachingSubject>
};

export default TeachingSubjectPage;

import "./Layout.scss";

import React from "react";

import Navbar from "../../Navbar/Navbar";
import Sidebar from "../../Sidebar/Sidebar";

const Layout: React.FunctionComponent = props => {
  return (
    <div className="Layout">
      <Navbar></Navbar>

      <div className="container-fluid">
        <div className="row">
          <div className="Layout__Sidebar col-md-3 col-lg-2 d-none d-md-block bg-light">
            <Sidebar></Sidebar>
          </div>

          <main role="main" className="Layout__Main col-md-9 ml-sm-auto col-lg-10 pt-3 pb-3 px-4">
            {props.children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;

import { useMutation } from "@apollo/client";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { UPDATE_EVENT_MUTATION } from "../../../graphql/mutations";
import { GET_PERSON_QUERY } from "../../../graphql/queries";
import { GetEndingEnumAsSelectOptions, GetReadableEndingName } from "../../../helpers/enumHelpers";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { mapLocationFormToLocationMutationInput, mapLocationToLocationFormInput } from "../../../helpers/locationHelpers";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import { eventSchema } from "../../../helpers/validators/eventSchema";
import { IEndingEnum } from "../../../interfaces/domain/enums/IEndingEnum";
import { IEvent } from "../../../interfaces/domain/IEvent";
import { IEventType } from "../../../interfaces/domain/IEventType";
import { IHistoryOption } from "../../../interfaces/domain/IHistory";
import { IEventForm } from "../../../interfaces/forms/IEventForm";
import { IUpdateEventMutationInput, IUpdateEventMutationResult } from "../../../interfaces/mutations/IUpdateEventMutation";
import EventTypeSelectInput from "../../EventType/EventTypeSelectInput/EventTypeSelectInput";
import HistoriesSelectInput from "../../History/HistoriesSelectInput/HistoriesSelectInput";
import CoordinatesFields from "../../_common/Form/CoordinatesFields/CoordinatesFields";
import DateFields from "../../_common/Form/DateFields/DateFields";
import FieldError from "../../_common/Form/FieldError/FieldError";
import FormSubTitle from "../../_common/Form/FormSubTitle/FormSubTitle";
import LocationAddressArea from "../../_common/Form/LocationFields/LocationAddressArea";
import LocationFields from "../../_common/Form/LocationFields/LocationFields";
import RemarksFields from "../../_common/Form/RemarksFields/RemarksFields";

interface IEditEventFormProps {
  event: IEvent;
  personId: number;
  handleCloseModal: () => void;
}

const EditEventForm: React.FunctionComponent<IEditEventFormProps> = (props) => {
  const { register, handleSubmit, errors, formState, getValues, setValue, control } = useForm<IEventForm>({
    validationSchema: eventSchema,
    mode: "onChange",
    defaultValues: {
      verifiedFromSource: props.event.verifiedFromSource,
      startDate: props.event.startDate,
      endDate: props.event.endDate,
      location1947: mapLocationToLocationFormInput(props.event.location1947),
      location2020: mapLocationToLocationFormInput(props.event.location2020),
      addressArea: {
        address: props.event.locationAddress,
        area: props.event.locationArea,
      },
      coordinates: {
        latitude: props.event.coordinates.latitude,
        longitude: props.event.coordinates.longitude,
      },
      remarks: props.event.remarks,
      ending: props.event.ending ? mapToSelectOption(props.event.ending, GetReadableEndingName(props.event.ending)) : undefined,
      displayOrder: props.event.displayOrder,
      eventType: mapToSelectOption(props.event.eventType.id, props.event.eventType.name),
      histories: props.event.histories.map((history) => {
        return mapToSelectOption(history.id, history.title);
      }),
    },
  });

  const [updateEvent] = useMutation<IUpdateEventMutationResult, IUpdateEventMutationInput>(UPDATE_EVENT_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateEvent({
      variables: {
        input: {
          id: props.event.id,
          verifiedFromSource: formValues.verifiedFromSource,
          startDate: formValues.startDate,
          endDate: formValues.endDate,
          location1947: mapLocationFormToLocationMutationInput(formValues.location1947),
          location2020: mapLocationFormToLocationMutationInput(formValues.location2020),
          addressArea: {
            address: formValues.addressArea.address ? formValues.addressArea.address : undefined,
            area: formValues.addressArea.area ? formValues.addressArea.area : undefined,
          },
          coordinates: formValues.coordinates,
          remarks: formValues.remarks,
          ending: formValues.ending ? (formValues.ending.value as IEndingEnum) : undefined,
          displayOrder: formValues.displayOrder,
          eventType: { id: formValues.eventType.value, name: formValues.eventType.label } as IEventType,
          histories: formValues.histories?.map((history) => {
            return { id: history.value, title: history.label } as IHistoryOption;
          }),
        },
      },
      refetchQueries: [{ query: GET_PERSON_QUERY, variables: { filter: { id: props.personId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateEvent)) {
          handleMutationSuccess(result.data);

          props.handleCloseModal();
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            placeholder="verifiedFromSource"
            id="verifiedFromSource"
            name="verifiedFromSource"
            ref={register}
          />
          <label className="form-check-label" htmlFor="verifiedFromSource">
            Kryssjekket mot primærkilde?
          </label>
        </div>

        <FieldError error={errors.verifiedFromSource}></FieldError>
      </div>

      <div className="form-row">
        <div className="col-lg-4">
          <div className="form-group">
            <label className="required-field" htmlFor="name">
              Visningsrekkefølge
            </label>
            <input
              type="number"
              name="displayOrder"
              ref={register}
              className={`form-control ${errors.displayOrder ? "is-invalid" : "valid"}`}
            />

            <FieldError error={errors.displayOrder}></FieldError>
          </div>
        </div>

        <div className="col-lg-4">
          <EventTypeSelectInput
            fieldName="eventType"
            label="Hendelsestype"
            required
            control={control}
            errors={errors}
            currentValue={getValues().eventType}
          />
        </div>

        <div className="col-lg-4">
          <div className="form-group">
            <label htmlFor="ending">Hvordan avsluttet hendelsen?</label>

            <Controller
              as={Select}
              control={control}
              name="ending"
              value={getValues().ending}
              options={GetEndingEnumAsSelectOptions()}
              placeholder="Velg..."
              isClearable
            />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col">
          <label htmlFor="startDate">Når startet hendelsen?</label>
          <DateFields fieldName="startDate" register={register} errors={errors.startDate}></DateFields>
        </div>
        <div className="col">
          <label htmlFor="endDate">Når ble hendelsen avsluttet?</label>
          <DateFields fieldName="endDate" register={register} errors={errors.endDate}></DateFields>
        </div>
      </div>

      <FormSubTitle>Lokasjon</FormSubTitle>

      <LocationAddressArea register={register} errors={errors} setValue={setValue} fieldName="addressArea"></LocationAddressArea>

      <h6>1947</h6>

      <LocationFields
        fieldName="location1947"
        control={control}
        errors={errors}
        currentValues={getValues().location1947}
        onChange={(value) => {
          setValue("location1947", value, true);
        }}
        yearOfOrigin={1947}></LocationFields>

      <h6>2020</h6>

      <LocationFields
        fieldName="location2020"
        control={control}
        errors={errors}
        currentValues={getValues().location2020}
        onChange={(value) => {
          setValue("location2020", value, true);
        }}
        yearOfOrigin={2020}></LocationFields>

      <div className="form-row">
        <div className="col">
          <FormSubTitle>Koordinater</FormSubTitle>

          <CoordinatesFields register={register} errors={errors}></CoordinatesFields>
        </div>
        <div className="col">
          <div className="form-group">
            <FormSubTitle>Tilknyttede historier</FormSubTitle>

            <HistoriesSelectInput
              fieldName="histories"
              label={"Du kan koble så mange historier som du ønsker"}
              control={control}
              errors={errors}
              currentValues={getValues().histories}
            />
          </div>
        </div>
      </div>

      <FormSubTitle>Notater</FormSubTitle>

      <RemarksFields register={register} errors={errors}></RemarksFields>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditEventForm;

import React from "react";
import { useParams } from "react-router-dom";
import EditQuestion from "../components/Question/EditQuestion/EditQuestion"
import PageNotFound from "../components/_common/PageNotFound/PageNotFound";


const QuestionPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditQuestion id={Number(id)}></EditQuestion>
};

export default QuestionPage;

import React from 'react';

import CreateRoleForm from './CreateRoleForm';

const CreateRole: React.FunctionComponent = props => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Legg til personrolle</h1>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="card shadow-sm">
            <div className="card-body">
              <CreateRoleForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRole;

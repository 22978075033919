import React from "react";

import CreatePrisonCampForm from "./CreatePrisonCampForm";

const CreatePrisonCamp: React.FunctionComponent = props => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Legg til fangested</h1>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <CreatePrisonCampForm />
        </div>
      </div>
    </div>
  );
};

export default CreatePrisonCamp;

import React from "react";
import { useParams } from "react-router-dom";

import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import EditArticle from "../components/Article/EditArticle/EditArticle";

const ArticlePage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditArticle id={Number(id)}></EditArticle>;
};

export default ArticlePage;

import { useQuery } from "@apollo/client";
import React from "react";
import { Controller } from "react-hook-form";
import { Control, FieldError, NestDataObject } from "react-hook-form/dist/types";
import Select from "react-select";
import { GET_PRISON_CAMP_CATEGORIES_QUERY } from "../../../graphql/queries";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import { ISelectOption } from "../../../interfaces/domain/common/ISelectOption";
import { IGetPrisonCampCategoriesQueryResult } from "../../../interfaces/queries/IGetPrisonCampCategoriesQuery";
import FieldValidationError from "../../_common/Form/FieldError/FieldError";
import QueryError from "../../_common/QueryError/QueryError";

interface PrisonCampCategoriesSelectInputProps {
  fieldName: string;
  errors: NestDataObject<any, FieldError>;
  currentValues: Array<ISelectOption>;
  control: Control<any>;
  label?: string;
  required?: boolean;
}

const PrisonCampCategoriesSelectInput: React.FunctionComponent<PrisonCampCategoriesSelectInputProps> = (props) => {
  const { loading, error, data } = useQuery<IGetPrisonCampCategoriesQueryResult>(GET_PRISON_CAMP_CATEGORIES_QUERY);

  if (error) return <QueryError apolloError={error} />;

  const prisonCampCategories = !data || !data.prisonCampCategories || !data.prisonCampCategories.length ? [] : data.prisonCampCategories;

  return (
    <div className="form-group">
      {props.label && (
        <label className={props.required ? "required-field" : ""} htmlFor={props.fieldName}>
          {props.label}
        </label>
      )}

      <Controller
        as={Select}
        control={props.control}
        name={props.fieldName}
        value={props.currentValues ? props.currentValues : null}
        options={prisonCampCategories.map((prisonCampCategory) => mapToSelectOption(prisonCampCategory.id, prisonCampCategory.name))}
        required={props.required}
        placeholder="Velg..."
        isMulti
        isLoading={loading}
        loadingMessage={() => {
          return "Henter...";
        }}
        noOptionsMessage={() => {
          return "Ingen valg";
        }}
      />

      <FieldValidationError error={props.errors[props.fieldName]}></FieldValidationError>
    </div>
  );
};

export default PrisonCampCategoriesSelectInput;

import React from "react";
import { FieldError as FormFieldError } from "react-hook-form/dist/types";

interface FieldErrorProps {
  error?: FormFieldError;
}

const FieldError: React.FunctionComponent<FieldErrorProps> = (props) => {
  if (props.error) {
    return <div className="invalid-feedback">{props.children ? props.children : props.error.message}</div>;
  }

  return null;
};

export default FieldError;

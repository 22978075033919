import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import { DragDrop } from "@uppy/react";
import React, { useState } from "react";
import { IFileUpload } from "../../../interfaces/domain/IAttachment";
import { getAllowedFileUploadTypes } from "../../../helpers/fileHelpers";
import { getFileUploadLimit } from "../../../helpers/environmentHelpers";

interface FileUploaderProps {
  onAddFile: (uplodFile: IFileUpload) => void;
}

const FileUploader: React.FunctionComponent<FileUploaderProps> = props => {
  const [selectedFileName, setSelectedFileName] = useState<string | undefined>(undefined);

  const uppy = Uppy({
    restrictions: {
      maxFileSize: getFileUploadLimit(),
      maxNumberOfFiles: 1,
      allowedFileTypes: getAllowedFileUploadTypes()
    },
    autoProceed: false
  });

  uppy.on("file-added", async file => {
    const fileData = file.data;

    if (!fileData) return;

    let reader = new FileReader();

    reader.onload = function(event) {
      if (event && event.target) {
        const contents = event.target.result as string;
        const base64result = contents.split(",")[1];

        let FileMetadata: IFileUpload = {
          fileName: file.name,
          fileLength: file.size,
          fileContentType: file.type,
          fileAsBase64: base64result
        };

        setSelectedFileName(file.name);

        props.onAddFile(FileMetadata);
      }
    };

    reader.onerror = function(event) {
      console.error("File could not be read!");
    };

    reader.readAsDataURL(file.data);
  });

  return (
    <div className="required-field">
      <DragDrop
        uppy={uppy}
        locale={{
          strings: {
            dropHereOr: selectedFileName ? `Valgt fil: ${selectedFileName}` : "Dra fil hit - eller %{browse}",
            browse: "klikk her for å bla gjennom"
          }
        }}
      />
    </div>
  );
};

export default FileUploader;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiPlusCircle } from "react-icons/fi";
import { IEntityReference } from "../../../interfaces/domain/IEntityReference";
import AddEntityReference from "../EntityReferences/AddEntityReference";
import { IEntityReferenceTypeEnum } from "../../../interfaces/forms/IEntityReferenceForm";
import EntityReferencesList from "./EntityReferencesList";

interface EntityReferencesProps {
  entityReferences: Array<IEntityReference>;
  entityType: IEntityReferenceTypeEnum;
  entityId: number;
}

const EntityReferences: React.FunctionComponent<EntityReferencesProps> = props => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <div>
      <div className="mb-3 text-right">
        <button className="btn btn-sm btn-outline-dark" onClick={handleShowModal}>
          <FiPlusCircle /> Legg til ny referansekobling
        </button>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow-sm">
            <div className="card-body">
              <EntityReferencesList
                entityReferences={props.entityReferences}
                entityType={props.entityType}
                entityId={props.entityId}></EntityReferencesList>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} dialogClassName="vw-100" size="lg" onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Legg til referansekobling</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AddEntityReference entityType={props.entityType} entityId={props.entityId} handleClose={handleCloseModal}></AddEntityReference>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EntityReferences;

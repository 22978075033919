import React from "react";

import CreateReferenceForm from "./CreateReferenceForm";

const CreateReference: React.FunctionComponent = props => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Legg til referanse</h1>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <CreateReferenceForm />
        </div>
      </div>
    </div>
  );
};

export default CreateReference;

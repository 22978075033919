import { useMutation, useQuery } from "@apollo/client";
import { GET_TEACHING_CATEGORY_QUERY } from "../../../graphql/queries";
import { IGetTeachingCategoryQueryFilter, IGetTeachingCategoryQueryResult } from "../../../interfaces/queries/IGetTeachingCategoryQuery";
import { useAuth } from "../../../lib/hooks/useAuth";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { Button, ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { IDeleteTeachingCategoryMutationInput, IDeleteTeachingCategoryMutationResult } from "../../../interfaces/mutations/IDeleteTeachingCategoryMutation";
import { DELETE_TEACHING_CATEGORY_MUTATION, TOGGLE_PUBLISH_TEACHING_CATEGORY_MUTATION } from "../../../graphql/mutations";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../lib/Router/routes";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { FiTrash2 } from "react-icons/fi";
import EditTeachingCategoryFormContainer from "./EditTeachingCategoryFormContainer";
import { ITogglePublishTeachingCategoryMutationInput, ITogglePublishTeachingCategoryMutationResult } from "../../../interfaces/mutations/ITogglePublishTeachingCategoryMutation";
import Audit from "../../_common/Audit/Audit";
import PublishStatus from "../../_common/PublishStatus/PublishStatus";

interface IEditTeachingCategoryProps {
  id: number;
}

const EditTeachingCategory: React.FunctionComponent<IEditTeachingCategoryProps> = (props) => {
  const { isAdmin } = useAuth();
  const history = useHistory();

  const { loading, error, data } = useQuery<IGetTeachingCategoryQueryResult, IGetTeachingCategoryQueryFilter>(GET_TEACHING_CATEGORY_QUERY, {
    variables: { filter: { id: props.id } },
  });

  const [deleteTeachingCategory] = useMutation<IDeleteTeachingCategoryMutationResult, IDeleteTeachingCategoryMutationInput>(DELETE_TEACHING_CATEGORY_MUTATION);
  const [togglePublishTeachingCategory, { loading: togglePublishTeachingCategoryLoading }] = useMutation<
    ITogglePublishTeachingCategoryMutationResult,
    ITogglePublishTeachingCategoryMutationInput
  >(TOGGLE_PUBLISH_TEACHING_CATEGORY_MUTATION);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.teachingCategory) return <DataNotFound />;

  const onDeleteTeachingCategory = async (teachingCategoryId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette kategori: ${data.teachingCategory.title}?`);

    if (deleteConfirmed) {
      await deleteTeachingCategory({
        variables: {
          input: {
            id: teachingCategoryId,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteTeachingCategory)) {
            handleMutationSuccess(result.data);

            history.push(ROUTES.AUTHORIZED.TEACHING_MODULE_ROUTE);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  const onTogglePublishTeachingCategory = async (teachingCategoryId: number, publish: boolean) => {
    await togglePublishTeachingCategory({
      variables: {
        input: {
          id: teachingCategoryId,
          publish: publish,
        },
      },
      refetchQueries: [{ query: GET_TEACHING_CATEGORY_QUERY, variables: { filter: { id: teachingCategoryId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.togglePublishTeachingCategory)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <h1 className="h1">{data.teachingCategory.title}</h1>

        <div>
          {isAdmin() && (
            <ButtonToolbar className="d-flex justify-content-end">
              {data.teachingCategory.publishStatus.isPublished ? (
                <Button
                  variant="warning"
                  size="sm"
                  className="mr-1"
                  disabled={togglePublishTeachingCategoryLoading}
                  onClick={(event: any) => {
                    event.preventDefault();
                    onTogglePublishTeachingCategory(data.teachingCategory.id, !data.teachingCategory.publishStatus.isPublished);
                  }}>
                  {togglePublishTeachingCategoryLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Avpubliserer...</span>
                    </>
                  ) : (
                    <span> Avpubliser</span>
                  )}
                </Button>
              ) : (
                <Button
                  variant="success"
                  size="sm"
                  className="mr-1"
                  disabled={togglePublishTeachingCategoryLoading}
                  onClick={(event: any) => {
                    event.preventDefault();
                    onTogglePublishTeachingCategory(data.teachingCategory.id, !data.teachingCategory.publishStatus.isPublished);
                  }}>
                  {togglePublishTeachingCategoryLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Publiserer...</span>
                    </>
                  ) : (
                    <span> Publiser</span>
                  )}
                </Button>
              )}
              <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
                <Dropdown.Item
                  className="text-danger"
                  eventKey="1"
                  onClick={() => {
                    onDeleteTeachingCategory(data.teachingCategory.id);
                  }}>
                  <FiTrash2 /> Slett kategori
                </Dropdown.Item>
              </DropdownButton>
            </ButtonToolbar>
          )}

          <Audit audit={data.teachingCategory.audit}></Audit>
          <PublishStatus publishStatus={data.teachingCategory.publishStatus}></PublishStatus>
        </div>
      </div>

      <EditTeachingCategoryFormContainer teachingCategory={data.teachingCategory} />
    </div>
  )
}

export default EditTeachingCategory

import algoliasearch from "algoliasearch";
import {
  ENVIRONMENT_ALGOLIA_APP_ID,
  ENVIRONMENT_ALGOLIA_SEARCH_API_KEY,
  ENVIRONMENT_ALGOLIA_INDEX_NAME
} from "../../helpers/environmentHelpers";

const searchClient = algoliasearch(ENVIRONMENT_ALGOLIA_APP_ID, ENVIRONMENT_ALGOLIA_SEARCH_API_KEY);
export const searchIndex = searchClient.initIndex(ENVIRONMENT_ALGOLIA_INDEX_NAME);

export default searchClient;

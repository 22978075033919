import { ITeachingSubject } from "../../../interfaces/domain/ITeachingSubject";
import AssignmentsColumn from "../../Assignment/Assignments/AssignmentsColumn";
import EditTeachingSubjectForm from "./EditTeachingSubjectForm";

interface IEditTeachingSubjectProps {
  teachingSubject: ITeachingSubject;
}

const EditTeachingSubjectFormContainer: React.FunctionComponent<IEditTeachingSubjectProps> = (props) => {
  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-6 mb-3">
          <EditTeachingSubjectForm teachingSubject={props.teachingSubject}></EditTeachingSubjectForm>
        </div>

        <div className="col-lg-6">
          {<AssignmentsColumn teachingSubjectId={props.teachingSubject.id} assignments={props.teachingSubject.assignments}></AssignmentsColumn>}
        </div>
      </div>
    </div>
  )
}

export default EditTeachingSubjectFormContainer;

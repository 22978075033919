import "instantsearch.css/themes/algolia.css";
import React from "react";
import { connectHits } from "react-instantsearch-dom";
import SearchResultHit from "./SearchResultHit";
import { ISearchResultHit } from "../../../interfaces/algolia/ISearchResultHit";
import { IPageSource } from "../../../interfaces/domain/common/ISearch";

interface SearchResultHitsProps {
  hits: Array<any>;
  searchState: any;
  onClickHit?: () => void;
  hideIcon?: boolean;
  pageSource?: IPageSource;
}

const SearchResultHits: React.FunctionComponent<SearchResultHitsProps> = (props) => {
  if (!props.hits || !props.hits.length) {
    return <div className="card-body text-muted">Ingen treff</div>;
  }

  return (
    <ul className="list-group list-group-flush">
      {props.hits.map((hit: ISearchResultHit) => (
        <SearchResultHit onClick={props.onClickHit} key={hit.objectID} pageSource={props.pageSource} hit={hit} hideIcon={props.hideIcon} />
      ))}
    </ul>
  );
};

export default connectHits(SearchResultHits);

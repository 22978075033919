import React from "react";
import { FiHelpCircle } from "react-icons/fi";

interface DataNotFoundProps {
  message?: string;
}

const DataNotFound: React.FunctionComponent<DataNotFoundProps> = props => {
  const message = props.message ? props.message : "Ingen data funnet";

  return (
    <div className="d-flex justify-content-center">
      <div className="m-5 text-center">
        <h1 className="display-3 text-warning">
          <FiHelpCircle></FiHelpCircle>
        </h1>
        <p className="lead">{message}</p>
      </div>
    </div>
  );
};

export default DataNotFound;

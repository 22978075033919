import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { GET_TEACHING_CATEGORIES_QUERY } from "../../../graphql/queries";
import { IGetTeachingCategoriesQueryResult } from "../../../interfaces/queries/IGetTeachingCategoriesQuery";
import { GET_TEACHING_CATEGORY_ROUTE } from "../../../lib/Router/routes";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const TeachingCategoriesList: React.FunctionComponent = () => {
  const { loading, error, data } = useQuery<IGetTeachingCategoriesQueryResult>(GET_TEACHING_CATEGORIES_QUERY);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.teachingCategories || !data.teachingCategories.length) return <DataNotFound />;

  return (<div className="TeachingCategoriesList">
    <table className="table table-hover table-bordered">
      <thead>
        <tr>
          <th scope="col">Tittel</th>
          <th scope="col">Fag</th>
          <th scope="col">Rekkefølge</th>
        </tr>
      </thead>

      <tbody>
        {data.teachingCategories.map((teachingCategory) => (
          <tr key={teachingCategory.id}>
            <td>
              <Link to={GET_TEACHING_CATEGORY_ROUTE(teachingCategory.id)}>{teachingCategory.title}</Link>
            </td>
            <td>{teachingCategory.title}</td>
            <td>{teachingCategory.displayOrder}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
}

export default TeachingCategoriesList;

import { ILocation, ILocationForm } from "../interfaces/domain/common/ILocation";
import { ILocationMutationInput } from "../interfaces/mutations/ILocationMutation";
import { IYearOfOriginEnum } from "../interfaces/domain/enums/IYearOfOriginEnum";
import { GetReadableYearOfOrigin } from "./enumHelpers";
import { ISelectOptions } from "../interfaces/domain/common/ISelectOption";
import { mapToSelectOption } from "./selectHelpers";

export const NOT_PROVIDED = "Ikke utfylt";

export const getFormattedLocation = (country?: string, region?: string, district?: string, prefix?: string) => {
  if (country || region || district) {
    let locationString: string[] = [];

    if (district) locationString.push(district);
    if (region) locationString.push(region);
    if (country) locationString.push(country);

    const location = locationString.join(", ");
    const text = `${prefix ? prefix : ""} ${location}`;

    return text;
  }

  return "";
};

export const hasLocation = (locationObject: ILocation) => {
  if (locationObject?.country || locationObject?.region || locationObject?.district) {
    return true;
  }
  return false;

};

export const mapLocationFormToLocationMutationInput = (location: ILocationForm): ILocationMutationInput => {
  return {
    countryId: location.country ? (location.country.value as number) : undefined,
    regionId: location.region ? (location.region.value as number) : undefined,
    districtId: location.district ? (location.district.value as number) : undefined,
  };
};

export const mapLocationToLocationFormInput = (location: ILocation): ILocationForm => {
  return {
    country: location.country ? mapToSelectOption(location.country?.id, location.country?.name) : undefined,
    region: location.region ? mapToSelectOption(location.region?.id, location.region?.name) : undefined,
    district: location.district ? mapToSelectOption(location.district?.id, location.district?.name) : undefined,
  };
};

export const getYearOfOriginSelectOptions = (): ISelectOptions => {
  return [
    { value: IYearOfOriginEnum.YEAR2020, label: GetReadableYearOfOrigin(IYearOfOriginEnum.YEAR2020).toString() },
    { value: IYearOfOriginEnum.YEAR1947, label: GetReadableYearOfOrigin(IYearOfOriginEnum.YEAR1947).toString() },
  ];
};

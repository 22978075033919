import React from "react";
import { useParams } from "react-router-dom";

import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import EditPrisonCamp from "../components/PrisonCamp/EditPrisonCamp/EditPrisonCamp";

const PrisonCampPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditPrisonCamp id={Number(id)}></EditPrisonCamp>;
};

export default PrisonCampPage;

import React from "react";
import { useParams } from "react-router-dom";

import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import EditHistory from "../components/History/EditHistory/EditHistory";

const HistoryPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditHistory id={Number(id)}></EditHistory>;
};

export default HistoryPage;

import React from "react";
import { useFieldArray } from "react-hook-form";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";

interface AliasFieldsProps {
  fieldName: string;
  register: any;
  control: any;
  errors: NestDataObject<any, FieldError>;
}

const AliasFields: React.FunctionComponent<AliasFieldsProps> = (props) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: props.fieldName,
  });

  return (
    <div>
      {fields.map((field, index) => (
        <div key={field.id} className="input-group mb-2">
          <input type="text" name={`${props.fieldName}[${index}].name`} ref={props.register()} className="form-control" />

          <div className="input-group-append">
            <button type="button" className="btn btn-outline-danger" onClick={() => remove(index)}>
              Fjern
            </button>
          </div>
        </div>
      ))}

      <button
        type="button"
        className="btn btn-outline-secondary btn-sm"
        onClick={() => {
          append({ name: "" });
        }}>
        Legg til
      </button>
    </div>
  );
};

export default AliasFields;

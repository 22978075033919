import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { Prompt, useHistory } from "react-router-dom";
import { CREATE_PRISON_CAMP_MUTATION } from "../../../graphql/mutations";
import { prisonCampSchema } from "../../../helpers/validators/prisonCampSchema";
import { IPrisonCampForm } from "../../../interfaces/forms/IPrisonCampForm";
import { ICreatePrisonCampMutationInput, ICreatePrisonCampMutationResult } from "../../../interfaces/mutations/ICreatePrisonCampMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import PrisonCampCategoriesSelectInput from "../../PrisonCampCategory/PrisonCampCategoriesSelectInput/PrisonCampCategoriesSelectInput";
import CoordinatesFields from "../../_common/Form/CoordinatesFields/CoordinatesFields";
import PrivateRemarkField from "../../_common/Form/RemarksFields/PrivateRemarkField";
import AliasFields from "../Form/AliasFields/AliasFields";
import LocationFields from "../../_common/Form/LocationFields/LocationFields";
import FormSubTitle from "../../_common/Form/FormSubTitle/FormSubTitle";
import WYSIWYGEditor from "../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";
import HistoriesSelectInput from "../../History/HistoriesSelectInput/HistoriesSelectInput";
import LocationAddressArea from "../../_common/Form/LocationFields/LocationAddressArea";
import { mapLocationFormToLocationMutationInput } from "../../../helpers/locationHelpers";
import { ROUTES } from "../../../lib/Router/routes";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";
import { IPrisonCampCategory } from "../../../interfaces/domain/IPrisonCampCategory";
import { IHistoryOption } from "../../../interfaces/domain/IHistory";

const CreatePrisonCampForm: React.FunctionComponent = () => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState, getValues, setValue, control } = useForm<IPrisonCampForm>({
    validationSchema: prisonCampSchema,
    mode: "onChange",
  });

  const [createPrisonCamp] = useMutation<ICreatePrisonCampMutationResult, ICreatePrisonCampMutationInput>(CREATE_PRISON_CAMP_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await createPrisonCamp({
      variables: {
        input: {
          name: formValues.name,
          description: formValues.description,
          remarks: formValues.remarks,
          coordinates: formValues.coordinates,
          aliases: formValues.aliases,
          location1947: mapLocationFormToLocationMutationInput(formValues.location1947),
          location2020: mapLocationFormToLocationMutationInput(formValues.location2020),
          addressArea: {
            address: formValues.addressArea.address ? formValues.addressArea.address : undefined,
            area: formValues.addressArea.area ? formValues.addressArea.area : undefined,
          },
          categories: formValues.categories?.map((category) => {
            return { id: category.value, name: category.label } as IPrisonCampCategory;
          }),
          histories: formValues.histories?.map((history) => {
            return { id: history.value, title: history.label } as IHistoryOption;
          }),
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createPrisonCamp)) {
          handleMutationSuccess(result.data);

          history.push(ROUTES.AUTHORIZED.PRISON_CAMPS_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Prompt
        when={formState.dirty && !formState.isSubmitting}
        message="Du har endringer som ikke er lagret. Er du sikker på du vil fortsette? Endringene vil gå tapt hvis du fortsetter uten å lagre.
        Trykk OK for å gå videre uten å lagre, eller trykk Avbryt for å gå tilbake."
      />
      <div className="row">
        <div className="col-lg-7">
          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <div className="form-group">
                <label className="required-field" htmlFor="name">
                  Navn
                </label>
                <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.name}></FieldError>
              </div>

              <div className="form-group">
                <FormSubTitle>Tilleggsnavn</FormSubTitle>

                <AliasFields fieldName="aliases" control={control} register={register} errors={errors}></AliasFields>
              </div>
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Lokasjon</FormSubTitle>

              <LocationAddressArea register={register} errors={errors} setValue={setValue} fieldName="addressArea"></LocationAddressArea>

              <h6>1947</h6>

              <LocationFields
                fieldName="location1947"
                control={control}
                errors={errors}
                currentValues={getValues().location1947}
                onChange={(value) => {
                  setValue("location1947", value, true);
                }}
                yearOfOrigin={1947}></LocationFields>

              <h6>2020</h6>

              <LocationFields
                fieldName="location2020"
                control={control}
                errors={errors}
                currentValues={getValues().location2020}
                onChange={(value) => {
                  setValue("location2020", value, true);
                }}
                yearOfOrigin={2020}></LocationFields>
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Beskrivelse</FormSubTitle>

              <WYSIWYGEditor
                fieldName="description"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues().description}
              />
            </div>

            <div className="card-footer">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
                  {formState.isSubmitting ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Oppretter...</span>
                    </>
                  ) : (
                    <span>Opprett</span>
                  )}
                </button>

                <div>
                  <small className="text-muted">Innholdet vil bli publisert</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5">
          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <div className="form-group">
                <FormSubTitle>
                  <div className="required-field">Type</div>
                </FormSubTitle>

                <PrisonCampCategoriesSelectInput
                  fieldName="categories"
                  label="Type"
                  control={control}
                  errors={errors}
                  currentValues={getValues().categories}
                />

                <FormSubTitle>Koordinater</FormSubTitle>

                <CoordinatesFields register={register} errors={errors}></CoordinatesFields>
              </div>
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Tilknyttede historier</FormSubTitle>

              <HistoriesSelectInput fieldName="histories" control={control} errors={errors} currentValues={getValues().histories} />
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Notater</FormSubTitle>

              <PrivateRemarkField register={register} errors={errors}></PrivateRemarkField>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreatePrisonCampForm;

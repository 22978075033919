import WYSIWYGEditor from "../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";
import { useForm } from "react-hook-form";
import { teachingModuleSchema } from "../../../helpers/validators/teachingModuleSchema";
import { ITeachingModuleForm } from "../../../interfaces/forms/ITeachingModuleForm";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { useMutation } from "@apollo/client";
import { ICreateTeachingModuleMutationInput, ICreateTeachingModuleMutationResult } from "../../../interfaces/mutations/ICreateTeachingModuleMutation";
import { CREATE_TEACHING_MODULE_MUTATION } from "../../../graphql/mutations";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { GET_TEACHING_MODULE_QUERY } from "../../../graphql/queries";

const CreateTeachingModuleForm: React.FunctionComponent = () => {
  const { register, handleSubmit, errors, formState, getValues, setValue } = useForm<ITeachingModuleForm>({
    validationSchema: teachingModuleSchema,
    mode: "onChange",
  });

  const [createTeachingModule] = useMutation<ICreateTeachingModuleMutationResult, ICreateTeachingModuleMutationInput>(
    CREATE_TEACHING_MODULE_MUTATION
  );

  const onSubmit = handleSubmit(async (formValues) => {
    await createTeachingModule({
      variables: {
        input: {
          title: formValues.title,
          lead: formValues.lead,
          body: formValues.body,
          videoUrl: formValues.videoUrl,
        }
      },
      refetchQueries: [{ query: GET_TEACHING_MODULE_QUERY }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createTeachingModule)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="form-group">
            <label className="required-field" htmlFor="title">Tittel</label>
            <input type="text" name="title" ref={register} className={`form-control ${errors.title ? "is-invalid" : "valid"}`} autoComplete="off" />
            <FieldError error={errors.title}></FieldError>
          </div>
          <div className="form-group">
            <label htmlFor="title">VideoUrl</label>
            <input type="text" name="videoUrl" ref={register} className={`form-control ${errors.videoUrl ? "is-invalid" : "valid"}`} autoComplete="off" />
            <FieldError error={errors.title}></FieldError>
          </div>
          <div className="form-group">
            <label htmlFor="title">Ingress</label>
            <textarea name="lead" ref={register} className={`form-control ${errors.lead ? "is-invalid" : "valid"}`} autoComplete="off" />
            <FieldError error={errors.title}></FieldError>
          </div>
          <WYSIWYGEditor
            fieldName="body"
            label="Innhold"
            register={register}
            errors={errors}
            setValue={setValue}
            currentValue={getValues().body}
          />
          <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Oppretter...</span>
              </>
            ) : (
              <span>Opprett</span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default CreateTeachingModuleForm;

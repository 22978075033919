import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Button } from "react-bootstrap";
import { FiCheckCircle, FiCircle } from "react-icons/fi";
import { TOGGLE_ALGOLIA_AUTOMATIC_SYNC_SETTING_MUTATION } from "../../../graphql/mutations";
import { GET_ALGOLIA_AUTOMATIC_SYNC_SETTING_QUERY } from "../../../graphql/queries";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { IToggleAlgoliaAutomaticSyncSettingMutationResult } from "../../../interfaces/mutations/IToggleAlgoliaAutomaticSyncSettingMutation";
import { IGetAlgoliaAutomaticSyncSettingQueryResult } from "../../../interfaces/queries/IGetAlgoliaAutomaticSyncSettingQuery";
import { useAuth } from "../../../lib/hooks/useAuth";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const ToggleAlgoliaAutomaticSync: React.FunctionComponent = () => {
  const { isAdmin } = useAuth();
  const { loading, error, data } = useQuery<IGetAlgoliaAutomaticSyncSettingQueryResult>(GET_ALGOLIA_AUTOMATIC_SYNC_SETTING_QUERY);
  const [toggleSetting, { loading: toggleSettingLoading }] = useMutation<IToggleAlgoliaAutomaticSyncSettingMutationResult>(
    TOGGLE_ALGOLIA_AUTOMATIC_SYNC_SETTING_MUTATION
  );

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.algoliaAutomaticSyncSetting) return <DataNotFound />;

  const settingEnabled = data.algoliaAutomaticSyncSetting.value === true;

  const onToggleSetting = async () => {
    await toggleSetting({
      refetchQueries: [{ query: GET_ALGOLIA_AUTOMATIC_SYNC_SETTING_QUERY }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.toggleAlgoliaAutomaticSyncSetting)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  };

  return (
    <div>
      <h5>Automatisk synkronisering</h5>

      <p>
        Ved oppdatering av personer, fangesteder, historier og referanser kan endringene automatisk synkroniseres mot Algolia. Dette
        medfører at endringene blir synlige i søk på administrasjonssiden og offentlig side automatisk.
      </p>

      <p>
        Dersom man skal gjøre mange endringer, kan det være hensiktsmessig å skru dette av midlertidig, for å heller kjøre en full manuell
        reindeksering på et senere tidspunkt.
      </p>

      <p>
        {settingEnabled ? (
          <strong>
            <FiCheckCircle className="text-success" /> Automatisk synkronisering er skrudd på
          </strong>
        ) : (
          <strong>
            <FiCircle className="text-danger" /> Automatisk synkronisering er skrudd av
          </strong>
        )}
      </p>

      <Button
        variant={settingEnabled ? "warning" : "primary"}
        size="sm"
        className="mr-1"
        disabled={!isAdmin || toggleSettingLoading}
        onClick={(event: any) => {
          event.preventDefault();
          onToggleSetting();
        }}>
        {toggleSettingLoading ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> {!settingEnabled ? "Aktiverer..." : "Deaktiverer..."}</span>
          </>
        ) : (
          <span> {!settingEnabled ? "Aktiver" : "Deaktiver"}</span>
        )}
      </Button>
    </div>
  );
};

export default ToggleAlgoliaAutomaticSync;

import { searchIndex } from "./searchClient";
import {
  IPersonResultHit,
  SearchFilterObjectTypeEnum,
  IPrisonCampResultHit,
  IHistoryResultHit,
  IReferenceResultHit,
} from "../../interfaces/algolia/ISearchResultHit";

const getDefaultSearchFilter = (objectType?: SearchFilterObjectTypeEnum) => {
  return {
    filters: objectType,
    hitsPerPage: 10,
  };
};

export const searchPersons = async (query: string) => {
  const { hits } = await searchIndex.search<IPersonResultHit>(query, getDefaultSearchFilter(SearchFilterObjectTypeEnum.Person));
  return hits as Array<IPersonResultHit>;
};

export const searchPrisonCamps = async (query: string) => {
  const { hits } = await searchIndex.search<IPrisonCampResultHit>(query, getDefaultSearchFilter(SearchFilterObjectTypeEnum.PrisonCamp));

  return hits as Array<IPrisonCampResultHit>;
};

export const searchHistories = async (query: string) => {
  const { hits } = await searchIndex.search<IHistoryResultHit>(query, getDefaultSearchFilter(SearchFilterObjectTypeEnum.History));

  return hits as Array<IHistoryResultHit>;
};

export const searchReferences = async (query: string) => {
  const { hits } = await searchIndex.search<IReferenceResultHit>(query, getDefaultSearchFilter(SearchFilterObjectTypeEnum.Reference));

  return hits as Array<IReferenceResultHit>;
};

export const searchReferenceIds = async (query: string) => {
  const { hits } = await searchIndex.search<IReferenceResultHit>("", {
    filters: `${SearchFilterObjectTypeEnum.Reference} AND id:${query}`,
  });

  return hits as Array<IReferenceResultHit>;

};

import React from "react";
import { IEntityReference } from "../../../interfaces/domain/IEntityReference";
import EntityReferences from "../../Reference/EntityReferences/EntityReferences";
import { IHistory } from "../../../interfaces/domain/IHistory";
import { IEntityReferenceTypeEnum } from "../../../interfaces/forms/IEntityReferenceForm";

interface HistoryReferencesProps {
  historyReferences: Array<IEntityReference<IHistory>>;
  historyId: number;
}

const HistoryReferences: React.FunctionComponent<HistoryReferencesProps> = (props) => {
  return (
    <EntityReferences
      entityReferences={props.historyReferences}
      entityType={IEntityReferenceTypeEnum.HISTORY}
      entityId={props.historyId}></EntityReferences>
  );
};

export default HistoryReferences;

import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { Prompt, useHistory } from "react-router-dom";
import { CREATE_ARTICLE_MUTATION } from "../../../../graphql/mutations";
import { articleSchema } from "../../../../helpers/validators/articleSchema";
import { IArticleForm } from "../../../../interfaces/forms/IArticleForm";
import { ICreateArticleMutationInput, ICreateArticleMutationResult } from "../../../../interfaces/mutations/ICreateArticleMutation";
import FieldError from "../../../_common/Form/FieldError/FieldError";
import WYSIWYGEditor from "../../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";
import { ROUTES } from "../../../../lib/Router/routes";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../../helpers/formHelpers";

const CreateArticleForm: React.FunctionComponent = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState, getValues, setValue } = useForm<IArticleForm>({
    validationSchema: articleSchema,
    mode: "onChange",
  });

  const [createArticle] = useMutation<ICreateArticleMutationResult, ICreateArticleMutationInput>(CREATE_ARTICLE_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await createArticle({
      variables: {
        input: {
          title: formValues.title,
          slug: formValues.slug,
          lead: formValues.lead,
          body: formValues.body,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createArticle)) {
          handleMutationSuccess(result.data);

          history.push(ROUTES.ADMIN.ARTICLES_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Prompt
        when={formState.dirty && !formState.isSubmitting}
        message="Du har endringer som ikke er lagret. Er du sikker på du vil fortsette? Endringene vil gå tapt hvis du fortsetter uten å lagre.
        Trykk OK for å gå videre uten å lagre, eller trykk Avbryt for å gå tilbake."
      />
      <div className="row">
        <div className="col-lg-8">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="form-group">
                <label className="required-field" htmlFor="title">
                  Tittel
                </label>
                <input type="text" name="title" ref={register} className={`form-control ${errors.title ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.title}></FieldError>
              </div>

              <div className="form-group">
                <label className="required-field" htmlFor="slug">
                  Unik URL-vennlig tittel
                </label>
                <input type="text" name="slug" ref={register} className={`form-control  ${errors.slug ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.slug}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="lead">Ingress</label>
                <textarea name="lead" ref={register} className={`form-control ${errors.lead ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.lead}></FieldError>
              </div>

              <WYSIWYGEditor
                fieldName="body"
                label="Innhold"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues().body}
              />
            </div>

            <div className="card-footer">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
                  {formState.isSubmitting ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Oppretter...</span>
                    </>
                  ) : (
                    <span>Opprett</span>
                  )}
                </button>

                <div>
                  <small className="text-muted">Innholdet vil bli publisert</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateArticleForm;

import React from "react";
import { Panel, RefinementList } from "react-instantsearch-dom";

const PublishStateCategory: React.FunctionComponent = (props) => {
  return (
    <Panel>
      <div className="SearchFilter__Refinement">
        <h6>Filtering</h6>
        <RefinementList
          attribute="category"
          transformItems={(items: { label: string }[]) => {
            return items.map((item: { label: string }) => ({
              ...item,
              label: item.label,
            }));
          }}
        />
      </div>
    </Panel>
  );
};

export default PublishStateCategory;

import { useParams } from "react-router-dom";
import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import CreateAssignment from "../components/Assignment/CreateAssignment/CreateAssignment";

const CreateAssignmentPage: React.FunctionComponent = () => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <CreateAssignment teachingSubjectId={id} />
}

export default CreateAssignmentPage;

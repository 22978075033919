export const GetPersonFullName = (firstName: string, middleName: string, lastName: string) => {
  if (!firstName && !middleName && !lastName) {
    return "(Ingen navn)";
  }

  return `${firstName ? firstName : ""} ${middleName ? middleName : ""} ${lastName ? lastName : ""}`;
};

export const GetPersonRolesList = (roles: any) => {
  let roleList: string[] = [];
  if (roles !== undefined && roles.data) {
    for (let value of roles.values()) {
      roleList.push(value);
    }

    return `${roleList.join(", ")}`;
  } else return "Ingen rolle";
};

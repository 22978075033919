import React from "react";
import CreateTeachingSubjectForm from "./CreateTeachingSubjectForm";

interface ICreateTeachingSubjectProps {
  teachingCategoryId: number;
}

const CreateTeachingSubject: React.FunctionComponent<ICreateTeachingSubjectProps> = (props) => {


  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Legg til fag</h1>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="card shadow-sm">
            <div className="card-body">
              <CreateTeachingSubjectForm teachingCategoryId={props.teachingCategoryId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTeachingSubject;

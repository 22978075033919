import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { GET_ARTICLE_ROUTE } from "../../../../lib/Router/routes";
import { GET_ARTICLES_QUERY } from "../../../../graphql/queries";
import { IGetArticlesQueryResult } from "../../../../interfaces/queries/IGetArticlesQuery";
import DataNotFound from "../../../_common/DataNotFound/DataNotFound";
import Loading from "../../../_common/Loading/Loading";
import QueryError from "../../../_common/QueryError/QueryError";

const ArticlesList: React.FunctionComponent = (props) => {
  const { loading, error, data } = useQuery<IGetArticlesQueryResult>(GET_ARTICLES_QUERY);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.articles || !data.articles.totalCount) return <DataNotFound message="Ingen artikler funnet" />;

  return (
    <div className="ArticlesList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Tittel</th>
          </tr>
        </thead>

        <tbody>
          {data.articles.articles.map((article) => (
            <tr key={article.id}>
              <td>
                <Link to={GET_ARTICLE_ROUTE(article.id)}>{article.title}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ArticlesList;

import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";

import { GET_PRISON_CAMP_CATEGORY_ROUTE } from "../../../lib/Router/routes";
import { GET_PRISON_CAMP_CATEGORIES_QUERY } from "../../../graphql/queries";
import { IGetPrisonCampCategoriesQueryResult } from "../../../interfaces/queries/IGetPrisonCampCategoriesQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const PrisonCampCategoriesList: React.FunctionComponent = (props) => {
  const { loading, error, data } = useQuery<IGetPrisonCampCategoriesQueryResult>(GET_PRISON_CAMP_CATEGORIES_QUERY);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.prisonCampCategories || !data.prisonCampCategories.length) return <DataNotFound />;

  return (
    <div className="PrisonCampCategoriesList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Navn</th>
          </tr>
        </thead>

        <tbody>
          {data.prisonCampCategories.map((prisonCampCategory) => (
            <tr key={prisonCampCategory.id}>
              <td>
                <Link to={GET_PRISON_CAMP_CATEGORY_ROUTE(prisonCampCategory.id)}>{prisonCampCategory.name}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrisonCampCategoriesList;

import { useParams } from "react-router-dom";
import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import CreateTeachingCategory from "../components/TeachingCategory/CreateTeachingCategory/CreateTeachingCategory";

const CreateTeachingCategoryPage: React.FunctionComponent = () => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <CreateTeachingCategory teachingModuleId={id} />
}

export default CreateTeachingCategoryPage;

import React from "react";
import { IEntityReference } from "../../../interfaces/domain/IEntityReference";
import EntityReferences from "../../Reference/EntityReferences/EntityReferences";
import { IPerson } from "../../../interfaces/domain/IPerson";
import { IEntityReferenceTypeEnum } from "../../../interfaces/forms/IEntityReferenceForm";

interface PersonReferencesProps {
  personReferences: Array<IEntityReference<IPerson>>;
  personId: number;
}

const PersonReferences: React.FunctionComponent<PersonReferencesProps> = props => {
  return (
    <EntityReferences
      entityReferences={props.personReferences}
      entityType={IEntityReferenceTypeEnum.PERSON}
      entityId={props.personId}></EntityReferences>
  );
};

export default PersonReferences;

import * as yup from "yup";
import { dateSchema } from "./dateSchema";

export const eventSchema = yup.object({
  displayOrder: yup.string().required("Må fylles ut"),
  startDate: dateSchema,
  endDate: dateSchema,
  eventType: yup.object({
    value: yup.number().required("Må fylles ut"),
  }),
});

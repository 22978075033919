import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { NavLink, useHistory } from "react-router-dom";
import {
  GET_REFERENCE_ROUTE,
  GET_REFERENCE_PERSONS_ROUTE,
  GET_REFERENCE_PRISONCAMPS_ROUTE,
  GET_REFERENCE_HISTORIES_ROUTE,
  GET_REFERENCE_UNDERVISNING_ROUTE,
  ROUTES,
} from "../../../lib/Router/routes";
import { DELETE_REFERENCE_MUTATION } from "../../../graphql/mutations";
import { GET_REFERENCE_QUERY } from "../../../graphql/queries";
import { IDeleteReferenceMutationInput, IDeleteReferenceMutationResult } from "../../../interfaces/mutations/IDeleteReferenceMutation";
import { IGetReferenceQueryFilter, IGetReferenceQueryResult } from "../../../interfaces/queries/IGetReferenceQuery";
import Audit from "../../_common/Audit/Audit";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { useAuth } from "../../../lib/hooks/useAuth";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";
import { ReferenceRouter } from "../../../lib/Router/Router";

interface IEditReferenceProps {
  id: number;
}

const EditReference: React.FunctionComponent<IEditReferenceProps> = (props) => {
  const { isAdmin } = useAuth();
  const history = useHistory();

  const { loading, error, data } = useQuery<IGetReferenceQueryResult, IGetReferenceQueryFilter>(GET_REFERENCE_QUERY, {
    variables: { filter: { id: props.id } },
  });

  const [deleteReference] = useMutation<IDeleteReferenceMutationResult, IDeleteReferenceMutationInput>(DELETE_REFERENCE_MUTATION);

  if (loading) return <Loading />;
  if (!data || !data.reference) return <DataNotFound />;
  if (error && (!data || !data.reference)) return <QueryError apolloError={error} />;

  const onDeleteReference = async (Id: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette referanse: ${data.reference.title}?`);

    if (deleteConfirmed) {
      await deleteReference({
        variables: {
          input: {
            id: Id,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteReference)) {
            handleMutationSuccess(result.data);

            history.push(ROUTES.AUTHORIZED.REFERENCES_ROUTE);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h1">{data.reference.title}</h1>

        <div>
          {isAdmin() && (
            <ButtonToolbar className="d-flex justify-content-end">
              <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
                <Dropdown.Item
                  disabled={data.reference.canBeDeleted === false}
                  className={data.reference.canBeDeleted === false ? "" : "text-danger"}
                  eventKey="1"
                  onClick={() => {
                    onDeleteReference(data.reference.id);
                  }}>
                  <FiTrash2 /> Slett referanse
                </Dropdown.Item>
              </DropdownButton>
            </ButtonToolbar>
          )}

          <Audit audit={data.reference.audit}></Audit>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <NavLink to={GET_REFERENCE_ROUTE(data.reference.id)} exact className="nav-link" activeClassName="active bg-white">
                Generelt
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={GET_REFERENCE_PERSONS_ROUTE(data.reference.id)} exact className="nav-link" activeClassName="active bg-white">
                Personer
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={GET_REFERENCE_PRISONCAMPS_ROUTE(data.reference.id)} exact className="nav-link" activeClassName="active bg-white">
                Fangesteder
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={GET_REFERENCE_HISTORIES_ROUTE(data.reference.id)} exact className="nav-link" activeClassName="active bg-white">
                Historier
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={GET_REFERENCE_UNDERVISNING_ROUTE(data.reference.id)}
                exact
                className="nav-link"
                activeClassName="active bg-white">
                Undervisning
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <ReferenceRouter reference={data.reference}></ReferenceRouter>
    </div>
  );
};

export default EditReference;

import React from "react";
import { useParams } from "react-router-dom";

import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import EditPrisonCampCategory from "../components/PrisonCampCategory/EditPrisonCampCategory/EditPrisonCampCategory";

const PrisonCampCategoryPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditPrisonCampCategory id={Number(id)}></EditPrisonCampCategory>;
};

export default PrisonCampCategoryPage;

import "./Navbar.scss";
import React from "react";
import { FiHome, FiLogOut, FiUser } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../lib/Router/routes";
import { useAuth } from "../../../lib/hooks/useAuth";
import { getUserDisplayName } from "../../../helpers/userHelpers";
import NavbarSearch from "../NavbarSearch/NavbarSearch";
import { useMutation } from "@apollo/client";
import { ISignOutMutationResult } from "../../../interfaces/mutations/ISignOutMutation";
import { SIGN_OUT_MUTATION } from "../../../graphql/mutations";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

const Layout: React.FunctionComponent = (props) => {
  const { signOut, currentUser } = useAuth();
  const [signOutMutation] = useMutation<ISignOutMutationResult>(SIGN_OUT_MUTATION);

  const onSignOut = async () => {
    await signOutMutation()
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.signOut)) {
          signOut();
          handleMutationSuccess(result.data, "Logget ut");
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError();
      });
  };

  return (
    <nav className="Navbar sticky-top bg-dark d-flex align-items-center">
      <div className="col-md-3">
        <NavLink to={ROUTES.AUTHORIZED.DASHBOARD_ROUTE} className="navbar-brand text-white ml-3" exact>
          Fanger.no
        </NavLink>
      </div>

      <div className="col d-md-block d-sm-none">
        <NavbarSearch></NavbarSearch>
      </div>

      <div className="col-md-4 col-lg-3 text-right">
        <div className="mr-2">
          {currentUser && (
            <NavLink to={ROUTES.AUTHORIZED.CHANGE_PASSWORD_ROUTE} className="btn btn-link text-white btn-sm mr-1" exact>
              <FiUser className="mr-1" />
              Logget inn som: {getUserDisplayName(currentUser)}
            </NavLink>
          )}

          <NavLink to={ROUTES.AUTHORIZED.DASHBOARD_ROUTE} className="btn btn-secondary btn-sm" exact>
            <FiHome />
          </NavLink>

          {currentUser && (
            <button className="btn btn-secondary btn-sm ml-1" onClick={onSignOut}>
              <FiLogOut /> Logg ut
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Layout;

import React from "react";
import { useParams } from "react-router-dom";

import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import EditCitizenship from "../components/Citizenship/EditCitizenship/EditCitizenship";

const CitizenshipPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditCitizenship id={Number(id)}></EditCitizenship>;
};

export default CitizenshipPage;

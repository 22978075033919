import React from "react";
import { FieldError, NestDataObject, Control } from "react-hook-form/dist/types";
import { GetReadableName } from "../../../helpers/enumHelpers";
import { GetPersonFullName } from "../../../helpers/personHelpers";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import { IPersonResultHit } from "../../../interfaces/algolia/ISearchResultHit";
import { ISelectOptions, ISelectOption } from "../../../interfaces/domain/common/ISelectOption";
import { searchPersons } from "../../../lib/algolia/searchQueries";
import AsyncSelect from "react-select/async";
import FieldValidationError from "../../_common/Form/FieldError/FieldError";
import { Controller } from "react-hook-form";

interface PersonsSelectInputProps {
  fieldName: string;
  errors: NestDataObject<any, FieldError>;
  currentValues: Array<ISelectOption>;
  control: Control<any>;
  label?: string;
  required?: boolean;
}

const fetchQueryOptions = (inputValue: string) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(search(inputValue));
    }, 400);
  });

const search = async (query: string) => {
  const result = await searchPersons(query);
  const mappedResult = mapPersonResultHitsToSelectOptions(result);

  return mappedResult;
};

const mapPersonResultHitsToSelectOptions = (personHits: Array<IPersonResultHit>) => {
  let options: ISelectOptions = personHits.map((personHit) => {
    return mapToSelectOption(
      personHit.id,
      GetReadableName(GetPersonFullName(personHit.firstName, personHit.middleName, personHit.lastName))
    );
  });

  return options;
};

const PersonsSelectInput: React.FunctionComponent<PersonsSelectInputProps> = (props) => {
  return (
    <div className="form-group">
      {props.label && (
        <label className={props.required ? "required-field" : ""} htmlFor={props.fieldName}>
          {props.label}
        </label>
      )}

      <Controller
        as={AsyncSelect}
        control={props.control}
        name={props.fieldName}
        value={props.currentValues ? props.currentValues : null}
        loadOptions={fetchQueryOptions}
        cacheOptions
        defaultOptions={false}
        required={props.required}
        placeholder="Velg..."
        isMulti
        loadingMessage={() => {
          return "Henter...";
        }}
        noOptionsMessage={() => {
          return "Ingen valg";
        }}
      />

      <FieldValidationError error={props.errors[props.fieldName]}></FieldValidationError>
    </div>
  );
};

export default PersonsSelectInput;

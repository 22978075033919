import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { UPDATE_INDUSTRY_MUTATION } from "../../../graphql/mutations";
import { industrySchema } from "../../../helpers/validators/industrySchema";
import { IIndustry } from "../../../interfaces/domain/IIndustry";
import { IIndustryForm } from "../../../interfaces/forms/IIndustryForm";
import { IUpdateIndustryMutationInput, IUpdateIndustryMutationResult } from "../../../interfaces/mutations/IUpdateIndustryMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { ROUTES } from "../../../lib/Router/routes";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface IEditIndustryFormProps {
  industry: IIndustry;
}

const EditIndustryForm: React.FunctionComponent<IEditIndustryFormProps> = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState } = useForm<IIndustryForm>({
    validationSchema: industrySchema,
    mode: "onChange",
    defaultValues: {
      name: props.industry.name,
    },
  });

  const [updateIndustry] = useMutation<IUpdateIndustryMutationResult, IUpdateIndustryMutationInput>(UPDATE_INDUSTRY_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateIndustry({
      variables: {
        input: {
          id: props.industry.id,
          name: formValues.name,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateIndustry)) {
          handleMutationSuccess(result.data);
          history.push(ROUTES.ADMIN.INDUSTRIES_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditIndustryForm;

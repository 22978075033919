import React from "react";
import { useQuery } from "@apollo/client";
import { FiPlusCircle } from "react-icons/fi";
import { NavLink, Link } from "react-router-dom";
import { GET_PRISON_CAMP_ROUTE, ROUTES } from "../../../lib/Router/routes";
import { GET_PRISON_CAMPS_QUERY } from "../../../graphql/queries";
import { IGetPrisonCampsQueryResult, IGetPrisonCampsQueryFilter } from "../../../interfaces/queries/IGetPrisonCampsQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { GetFormattedDateTimeShort } from "../../../helpers/dateHelpers";
import { GiWindowBars } from "react-icons/gi";
import { useAuth } from "../../../lib/hooks/useAuth";

const DashboardPrisonCampsList: React.FunctionComponent = (props) => {
  const { canVisit } = useAuth();
  const { loading, error, data } = useQuery<IGetPrisonCampsQueryResult, IGetPrisonCampsQueryFilter>(GET_PRISON_CAMPS_QUERY, {
    variables: { filter: { amountToReturn: 5, sortByLastUpdated: "DESC" } },
  });
  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data) return <DataNotFound message="Ingen fangesteder funnet" />;

  return (
    <div className="card shadow-sm">
      <div className="card-header ">
        <div className="float-right">
          {canVisit(ROUTES.ADMIN.PRISON_CAMP_CREATE_ROUTE) && (
            <NavLink to={ROUTES.ADMIN.PRISON_CAMP_CREATE_ROUTE} className="btn btn-sm btn-outline-dark mr-1" role="button" exact>
              <FiPlusCircle /> Legg til nytt fangested
            </NavLink>
          )}

          <NavLink to={ROUTES.AUTHORIZED.PRISON_CAMPS_ROUTE} className="btn btn-sm btn-outline-dark" role="button" exact>
            Vis alle
          </NavLink>
        </div>
        <h4 className="dashboard-card-header m-0">
          <GiWindowBars className="text-muted" /> Sist oppdaterte fangesteder
        </h4>

        <small className="text-muted">Totalt antall: {data.prisonCamps.totalPrisonCampsCount}</small>
      </div>

      <div className="list-group list-group-flush">
        {data.prisonCamps.prisonCamps.map((prisonCamp) => (
          <Link className="list-group-item list-group-item-action" to={GET_PRISON_CAMP_ROUTE(prisonCamp.id)} key={prisonCamp.id}>
            <div className="d-flex w-100 justify-content-between">
              {prisonCamp.name}
              <small className="text-muted">{GetFormattedDateTimeShort(prisonCamp.audit.updatedAt)}</small>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DashboardPrisonCampsList;

export enum IReferenceCategoryEnum {
  DOCUMENT = "DOCUMENT",
  ARCHIVE = "ARCHIVE",
  BOOK = "BOOK",
  ARTICLE = "ARTICLE",
  ITEM = "ITEM",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  WEBSITE = "WEBSITE",
  NEWSPAPER = "NEWSPAPER",
  SOUND = "SOUND",
  OTHER = "OTHER"
}

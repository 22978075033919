import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { CREATE_QUESTION_MUTATION } from "../../../graphql/mutations";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { questionSchema } from "../../../helpers/validators/questionSchema";
import { IQuestionForm } from "../../../interfaces/forms/IQuestionForm";
import { ICreateQuestionMutationInput, ICreateQuestionMutationResult } from "../../../interfaces/mutations/ICreateQuestionMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { GET_ASSIGNMENT_ROUTE } from "../../../lib/Router/routes";
import { useHistory } from "react-router-dom";

interface ICreateQuestionProps {
  assignmentId: number;
}

const CreateQuestionForm: React.FunctionComponent<ICreateQuestionProps> = (props) => {
  const history = useHistory();
  const { register, handleSubmit, errors, formState } = useForm<IQuestionForm>({
    validationSchema: questionSchema,
    mode: "onChange",
  });

  const [createQuestionMutation] = useMutation<ICreateQuestionMutationResult, ICreateQuestionMutationInput>(CREATE_QUESTION_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await createQuestionMutation({
      variables: {
        input: {
          title: formValues.title,
          displayOrder: formValues.displayOrder,
          hint: formValues.hint,
          assignmentId: props.assignmentId,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createQuestion)) {
          handleMutationSuccess(result.data, "Spørsmål opprettet.");

          history.push(GET_ASSIGNMENT_ROUTE(props.assignmentId));
        } else {
          handleMutationError(undefined, "Kunne ikke opprette spørsmål");
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="form-group">
            <label className="required-field" htmlFor="title">
              Spørsmålstittel
            </label>
            <input
              type="text"
              name="title"
              ref={register}
              className={`form-control ${errors.title ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />
            <FieldError error={errors.title}></FieldError>
          </div>

          <div className="form-group">
            <label className="required-field" htmlFor="displayOrder">
              Rekkefølge
            </label>
            <input
              type="number"
              name="displayOrder"
              ref={register}
              className={`form-control ${errors.displayOrder ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />
            <FieldError error={errors.displayOrder}></FieldError>
          </div>

          <div className="form-group">
            <label className="required-field" htmlFor="displayOrder">
              Hint
            </label>
            <textarea name="hint" ref={register} className={`form-control ${errors.hint ? "is-invalid" : "valid"}`} autoComplete="off" />
            <FieldError error={errors.displayOrder}></FieldError>
          </div>
          <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Oppretter...</span>
              </>
            ) : (
              <span>Opprett og tilknytt</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateQuestionForm;

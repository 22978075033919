import React from "react";
import { FieldError, NestDataObject, Control } from "react-hook-form/dist/types";
import { GetReadableName } from "../../../helpers/enumHelpers";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import { IHistoryResultHit } from "../../../interfaces/algolia/ISearchResultHit";
import { ISelectOptions, ISelectOption } from "../../../interfaces/domain/common/ISelectOption";
import { searchHistories } from "../../../lib/algolia/searchQueries";
import FieldValidationError from "../../_common/Form/FieldError/FieldError";
import AsyncSelect from "react-select/async";
import { Controller } from "react-hook-form";

interface HistoriesSelectInputProps {
  fieldName: string;
  errors: NestDataObject<any, FieldError>;
  currentValues: Array<ISelectOption>;
  control: Control<any>;
  label?: string;
  required?: boolean;
}

const fetchQueryOptions = (inputValue: string) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(search(inputValue));
    }, 400);
  });

const search = async (query: string) => {
  const result = await searchHistories(query);
  const mappedResult = mapHistoryResultHitsToSelectOptions(result);

  return mappedResult;
};

const mapHistoryResultHitsToSelectOptions = (historyHits: Array<IHistoryResultHit>) => {
  let options: ISelectOptions = historyHits.map((historyHits) => {
    return mapToSelectOption(historyHits.id, GetReadableName(historyHits.title));
  });

  return options;
};

const HistoriesSelectInput: React.FunctionComponent<HistoriesSelectInputProps> = (props) => {
  return (
    <div className="form-group">
      {props.label && (
        <label className={props.required ? "required-field" : ""} htmlFor={props.fieldName}>
          {props.label}
        </label>
      )}

      <Controller
        as={AsyncSelect}
        control={props.control}
        name={props.fieldName}
        value={props.currentValues ? props.currentValues : null}
        loadOptions={fetchQueryOptions}
        cacheOptions
        defaultOptions={false}
        required={props.required}
        placeholder="Velg..."
        isMulti
        loadingMessage={() => {
          return "Henter...";
        }}
        noOptionsMessage={() => {
          return "Ingen valg";
        }}
      />

      <FieldValidationError error={props.errors[props.fieldName]}></FieldValidationError>
    </div>
  );
};

export default HistoriesSelectInput;

import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../lib/Router/routes";
import { CHANGE_PASSWORD_MUTATION } from "../../../graphql/mutations";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { changePasswordSchema } from "../../../helpers/validators/changePasswordSchema";
import { IChangePasswordForm } from "../../../interfaces/forms/IChangePasswordForm";
import { IChangePasswordMutationInput, IChangePasswordMutationResult } from "../../../interfaces/mutations/IChangePasswordMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";

const ChangePasswordForm: React.FunctionComponent = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState } = useForm<IChangePasswordForm>({
    validationSchema: changePasswordSchema,
    mode: "onChange",
  });

  const [changePasswordMutation] = useMutation<IChangePasswordMutationResult, IChangePasswordMutationInput>(CHANGE_PASSWORD_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await changePasswordMutation({
      variables: {
        input: {
          currentPassword: formValues.currentPassword,
          newPassword: formValues.newPassword,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.changePassword)) {
          handleMutationSuccess(result.data, "Endring vellykket. Logg inn på nytt.");

          history.push(ROUTES.AUTHORIZED.DASHBOARD_ROUTE);
        } else {
          handleMutationError(undefined, "Kunne ikke nullstille");
        }
      })
      .catch((error) => {
        handleMutationError(error, "Kunne ikke nullstille");
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="currentPassword">Nåværende passord</label>

            <input
              type="password"
              name="currentPassword"
              ref={register}
              className={`form-control ${errors.currentPassword ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />

            <FieldError error={errors.currentPassword}></FieldError>
          </div>

          <div className="form-group">
            <label htmlFor="newPassword">Nytt passord</label>

            <input
              type="password"
              name="newPassword"
              ref={register}
              className={`form-control ${errors.newPassword ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />

            <FieldError error={errors.newPassword}></FieldError>
          </div>
        </div>

        <div className="card-footer">
          <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Oppdaterer...</span>
              </>
            ) : (
              <span>Oppdater</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordForm;

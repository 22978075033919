import { useQuery } from "@apollo/client";
import React from "react";
import { FiBook, FiPlusCircle } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";
import { GET_REFERENCES_QUERY } from "../../../graphql/queries";
import { GetFormattedDateTimeShort } from "../../../helpers/dateHelpers";
import { GetReadableReferenceCategoryName } from "../../../helpers/enumHelpers";
import { IGetReferencesQueryFilter, IGetReferencesQueryResult } from "../../../interfaces/queries/IGetReferencesQuery";
import { GET_REFERENCE_ROUTE, ROUTES } from "../../../lib/Router/routes";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const DashboardReferencesList: React.FunctionComponent = (props) => {
  const { loading, error, data } = useQuery<IGetReferencesQueryResult, IGetReferencesQueryFilter>(GET_REFERENCES_QUERY, {
    variables: { filter: { amountToReturn: 5, sortByLastUpdated: "DESC" } },
  });

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data) return <DataNotFound message="Ingen referanser funnet" />;

  return (
    <div className="card shadow-sm">
      <div className="card-header ">
        <div className="float-right">
          <NavLink to={ROUTES.AUTHORIZED.REFERENCE_CREATE_ROUTE} className="btn btn-sm btn-outline-dark mr-1" role="button" exact>
            <FiPlusCircle /> Legg til ny referanse
          </NavLink>

          <NavLink to={ROUTES.AUTHORIZED.REFERENCES_ROUTE} className="btn btn-sm btn-outline-dark" role="button" exact>
            Vis alle
          </NavLink>
        </div>
        <h4 className="dashboard-card-header m-0">
          <FiBook className="text-muted" /> Referanser
        </h4>

        <small className="text-muted">Totalt antall: {data.references.totalReferencesCount}</small>
      </div>

      <div className="list-group list-group-flush">
        {data.references.references.map((reference) => (
          <Link className="list-group-item list-group-item-action" to={GET_REFERENCE_ROUTE(reference.id)} key={reference.id}>
            <div className="d-flex w-100 row">
              <div className="col">{reference.title}</div>
              <div className="col">
                <span className="badge badge-light" key={reference.category}>
                  {GetReadableReferenceCategoryName(reference.category)}
                </span>
              </div>
              <div className="col text-right">
                <small className="text-muted">{GetFormattedDateTimeShort(reference.audit.updatedAt)}</small>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DashboardReferencesList;

import { useMutation, useQuery } from "@apollo/client";
import { useAuth } from "../../../lib/hooks/useAuth";
import { IGetTeachingModuleQueryResult } from "../../../interfaces/queries/IGetTeachingModuleQuery";
import { GET_TEACHING_MODULE_QUERY } from "../../../graphql/queries";
import CreateTeachingModuleForm from "../CreateTeachingModule/CreateTeachingModuleForm";
import QueryError from "../../_common/QueryError/QueryError";
import Loading from "../../_common/Loading/Loading";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import { DELETE_TEACHING_MODULE_MUTATION } from "../../../graphql/mutations";
import { IDeleteTeachingModuleMutationInput, IDeleteTeachingModuleMutationResult } from "../../../interfaces/mutations/IDeleteTeachingModuleMutation";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import EditTeachingModuleFormContainer from "./EditTeachingModuleFormContainer";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../lib/Router/routes";

const EditTeachingModule: React.FunctionComponent = () => {
  const { isAdmin } = useAuth();
  const history = useHistory();
  const { loading, error, data } = useQuery<IGetTeachingModuleQueryResult>(GET_TEACHING_MODULE_QUERY);

  const [deleteTeachingModule] = useMutation<IDeleteTeachingModuleMutationResult, IDeleteTeachingModuleMutationInput>(DELETE_TEACHING_MODULE_MUTATION);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.teachingModule) {
    return isAdmin() ? <CreateTeachingModuleForm /> : <DataNotFound message="Det finnes ingen undervisningsmodul" />;
  }

  const onDeleteTeachingModule = async (teachingModuleId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette undervisningsmodulen?`);

    if (deleteConfirmed) {
      await deleteTeachingModule({
        variables: {
          input: {
            id: teachingModuleId,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteTeachingModule)) {
            handleMutationSuccess(result.data);

            history.push(ROUTES.AUTHORIZED.DASHBOARD_ROUTE);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <h1 className="h1">{data.teachingModule.title}</h1>

        <div>
          {isAdmin() && (
            <ButtonToolbar className="d-flex justify-content-end">
              <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
                <Dropdown.Item
                  className="text-danger"
                  eventKey="1"
                  onClick={() => {
                    onDeleteTeachingModule(data.teachingModule.id);
                  }}>
                  <FiTrash2 /> Slett undervisningsmodul
                </Dropdown.Item>
              </DropdownButton>
            </ButtonToolbar>
          )}
        </div>
      </div>

      <EditTeachingModuleFormContainer teachingModule={data.teachingModule} />
    </div>
  )
};

export default EditTeachingModule;


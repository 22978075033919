import * as React from "react";
import { FiBook } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IReferenceResultHit } from "../../../../interfaces/algolia/ISearchResultHit";
import { GET_REFERENCE_ROUTE } from "../../../../lib/Router/routes";
import { GetReadableReferenceCategoryName } from "../../../../helpers/enumHelpers";
import { IReferenceCategoryEnum } from "../../../../interfaces/domain/enums/IReferenceCategoryEnum";

type ReferenceResultHitProps = {
  hit: IReferenceResultHit;
  onClick?: () => void;
  hideIcon?: boolean;
};

const ReferenceResultHit: React.FunctionComponent<ReferenceResultHitProps> = (props) => {
  const reference = props.hit;
  const categoryEnum = reference.category.toUpperCase() as IReferenceCategoryEnum;

  return (
    <Link
      to={GET_REFERENCE_ROUTE(reference.id)}
      className="list-group-item list-group-item-action"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}>
      <div className="media d-flex align-items-center">
        {props.hideIcon ? null : <FiBook size={26} className="mr-2 text-muted" />}

        <div className="media-body">
          <div className="d-flex w-100 justify-content-between">
            <span className="m-0">
              <span className="text-muted">#{reference.id} </span>
              <span className="h6">{reference.title}</span>
            </span>

            <small className="text-muted">{GetReadableReferenceCategoryName(categoryEnum)}</small>
          </div>

          <div className="text-muted">{reference.archiveIdentification}</div>
        </div>
      </div>
    </Link>
  );
};

export default ReferenceResultHit;

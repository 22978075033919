import React from 'react';

const Loading: React.FunctionComponent = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border text-dark" role="status">
        <span className="sr-only">Laster...</span>
      </div>
    </div>
  );
};

export default Loading;

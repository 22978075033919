import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiPlusCircle } from "react-icons/fi";
import { ICaptivity } from "../../../interfaces/domain/ICaptivity";
import { IEvent } from "../../../interfaces/domain/IEvent";
import CreateCaptivityForm from "../CreateCaptivity/CreateCaptivityForm";
import CreateEventForm from "../../Event/CreateEvent/CreateEventForm";
import CaptivitiesList from "./CaptivitiesList";

interface CaptivitiesProps {
  captivities: Array<ICaptivity>;
  events: Array<IEvent>;
  personId: number;
}

const Captivities: React.FunctionComponent<CaptivitiesProps> = props => {
  const [showModalCapativity, setShowModalCapativity] = useState(false);
  const handleCloseModalCapativity = () => setShowModalCapativity(false);
  const handleShowModalCapativity = () => setShowModalCapativity(true);
  const [showModalEvent, setShowModalEvent] = useState(false);
  const handleCloseModalEvent = () => setShowModalEvent(false);
  const handleShowModalEvent = () => setShowModalEvent(true);

  return (
    <div>
      <div className="mb-3 text-right">
        <button className="btn btn-sm btn-outline-dark mr-2" onClick={handleShowModalEvent}>
          <FiPlusCircle /> Legg til arrestasjon
        </button>
        <button className="btn btn-sm btn-outline-dark" onClick={handleShowModalCapativity}>
          <FiPlusCircle /> Legg til fangeopphold
        </button>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow-sm">
            <div className="card-body">
              <CaptivitiesList captivities={props.captivities} events={props.events} personId={props.personId}></CaptivitiesList>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModalEvent} dialogClassName="vw-100" size="xl" onHide={handleCloseModalEvent}>
        <Modal.Header closeButton>
          <Modal.Title>Legg til arrestasjon</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateEventForm personId={props.personId} handleCloseModal={handleCloseModalEvent}></CreateEventForm>
        </Modal.Body>
      </Modal>
      <Modal show={showModalCapativity} dialogClassName="vw-100" size="xl" onHide={handleCloseModalCapativity}>
        <Modal.Header closeButton>
          <Modal.Title>Legg til fangeopphold</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateCaptivityForm personId={props.personId} handleCloseModal={handleCloseModalCapativity}></CreateCaptivityForm>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Captivities;

import { Link, useHistory } from "react-router-dom";
import { ITeachingSubject } from "../../../interfaces/domain/ITeachingSubject";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import { GET_TEACHING_SUBJECT_ROUTE } from "../../../lib/Router/routes";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { FiTrash2 } from "react-icons/fi";
import { GET_TEACHING_CATEGORY_QUERY } from "../../../graphql/queries";
import { useMutation } from "@apollo/client";
import { IDeleteTeachingSubjectMutationInput, IDeleteTeachingSubjectMutationResult } from "../../../interfaces/mutations/IDeleteTeachingSubjectMutation";
import { DELETE_TEACHING_SUBJECT_MUTATION } from "../../../graphql/mutations";

interface TeachingSubjectsColumnListProps {
  teachingSubjects: Array<ITeachingSubject>;
  teachingCategoryId: number;
}

const TeachingSubjectsColumnList: React.FunctionComponent<TeachingSubjectsColumnListProps> = (props) => {
  const history = useHistory();
  const [deleteAttachment] = useMutation<IDeleteTeachingSubjectMutationResult, IDeleteTeachingSubjectMutationInput>(DELETE_TEACHING_SUBJECT_MUTATION);

  const onDeleteTeachingSubject = async (teachingSubjecttId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette faget?`);

    if (deleteConfirmed) {
      await deleteAttachment({
        variables: {
          input: {
            id: teachingSubjecttId,
          },
        },
        refetchQueries: [{ query: GET_TEACHING_CATEGORY_QUERY, variables: { filter: { id: props.teachingCategoryId } } }],
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteTeachingSubject)) {
            handleMutationSuccess(result.data);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  if (!props || !props.teachingSubjects || !props.teachingSubjects.length) return <DataNotFound />;

  return (
    <>
      <div className="list-group list-group-flush">
        {props.teachingSubjects.map((teachingSubject) => (
          <li key={teachingSubject.id} className="list-group-item list-group-item-action">
            <div className="row">
              <div className="d-flex w-100 row">
                <div className="col text-md-left">
                  {teachingSubject.displayOrder}
                </div>
                <div className="col">
                  <Link className="col-auto d-flex align-items-center" to={GET_TEACHING_SUBJECT_ROUTE(teachingSubject.id)} key={teachingSubject.id}>
                    {teachingSubject.title}
                  </Link>
                </div>
                <div className="col-auto d-flex align-items-center text-md-right">
                  <button
                    className="btn btn-sm btn-secondary mr-2"
                    onClick={() => {
                      history.push(GET_TEACHING_SUBJECT_ROUTE(teachingSubject.id));
                    }}>
                    Rediger
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      onDeleteTeachingSubject(teachingSubject.id);
                    }}>
                    <FiTrash2 />
                  </button>
                </div>
              </div>

            </div>
          </li>
        ))}
      </div>
    </>
  )
}

export default TeachingSubjectsColumnList;

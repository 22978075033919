import * as yup from "yup";

export const prisonCampSchema = yup.object({
  name: yup.string().required("Må fylles ut"),
  categories: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.number().required("Må fylles ut"),
      })
    )
    .required("Må fylle ut med en eller flere kategorier"),
});

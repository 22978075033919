import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiPlusCircle } from "react-icons/fi";
import { IAttachment } from "../../../interfaces/domain/IAttachment";
import CreateAttachmentForm from "../CreateAttachment/CreateAttachmentForm";
import AttachmentsList from "./AttachmentsList";
import FormSubTitle from "../../_common/Form/FormSubTitle/FormSubTitle";

interface AttachmentsProps {
  attachments: Array<IAttachment>;
  referenceId: number;
}

const Attachments: React.FunctionComponent<AttachmentsProps> = props => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <div className="card shadow-sm">
        <div className="card-body border-bottom">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
            <FormSubTitle>Vedlegg</FormSubTitle>
            <div>
              <button className="btn btn-sm btn-outline-dark" onClick={handleShowModal}>
                <FiPlusCircle /> Legg til
              </button>
            </div>
          </div>
        </div>

        <AttachmentsList attachments={props.attachments} referenceId={props.referenceId}></AttachmentsList>
      </div>

      <Modal show={showModal} size="lg" onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Legg til vedlegg</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateAttachmentForm referenceId={props.referenceId} handleCloseModal={handleCloseModal}></CreateAttachmentForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Attachments;

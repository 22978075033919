import React from "react";
import FieldValidationError from "../FieldError/FieldError";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";

interface RemarksFieldsProps {
  register: any;
  errors: NestDataObject<any, FieldError>;
}

const RemarksFields: React.FunctionComponent<RemarksFieldsProps> = (props) => {
  const fieldInternalRemark = `remarks.internalRemark`;
  const fieldPublicRemark = `remarks.publicRemark`;

  return (
    <>
      <div className="form-group">
        <label htmlFor={fieldInternalRemark}>Notat (vises kun internt)</label>
        <textarea
          name={fieldInternalRemark}
          ref={props.register}
          rows={4}
          className={`form-control ${props.errors[fieldInternalRemark] ? "is-invalid" : "valid"}`}
        />

        <FieldValidationError error={props.errors[fieldInternalRemark]}></FieldValidationError>
      </div>

      <div className="form-group">
        <label htmlFor={fieldPublicRemark}>Notat (vil bli offentliggjort)</label>
        <textarea
          name={fieldPublicRemark}
          ref={props.register}
          rows={4}
          className={`form-control ${props.errors[fieldPublicRemark] ? "is-invalid" : "valid"}`}
        />

        <FieldValidationError error={props.errors[fieldPublicRemark]}></FieldValidationError>
      </div>
    </>
  );
};

export default RemarksFields;

import React, { useState } from "react";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";
import { ILocationForm } from "../../../../interfaces/domain/common/ILocation";
import CountriesSelectInput from "../../../Country/CountriesSelectInput/CountriesSelectInput";
import DistrictsSelectInput from "../../../District/DistrictsSelectInput/DistrictsSelectInput";
import RegionsSelectInput from "../../../Region/RegionsSelectInput/RegionsSelectInput";
import FieldValidationError from "../../../_common/Form/FieldError/FieldError";

interface LocationFieldsProps {
  fieldName?: string;
  control: any;
  errors: NestDataObject<any, FieldError>;
  currentValues?: ILocationForm;
  onChange?: (value: ILocationForm) => void;
  yearOfOrigin: number;
}

const LocationFields: React.FunctionComponent<LocationFieldsProps> = (props) => {
  const [location, setLocation] = useState(props.currentValues);
  const fieldNameCountry = props.fieldName ? `${props.fieldName}.country` : "country";
  const fieldNameRegion = props.fieldName ? `${props.fieldName}.region` : "region";
  const fieldNameDistrict = props.fieldName ? `${props.fieldName}.district` : "district";

  return (
    <div className="form-row">
      <div className="col-lg-4">
        <CountriesSelectInput
          fieldName={fieldNameCountry}
          label="Land"
          control={props.control}
          errors={props.errors}
          currentValue={props.currentValues?.country ? props.currentValues.country : undefined}
          selectedYearOfOrigin={props.yearOfOrigin}
          isClearable
          onChange={(value) => {
            const updatedLocation = Object.assign({}, location, {
              country: value ? { value: value.value, label: value.label } : null,
              region: null,
              district: null,
            });

            setLocation(updatedLocation);

            if (props.onChange) {
              props.onChange(updatedLocation);
            }
          }}
        />

        <FieldValidationError error={props.errors[fieldNameCountry]}></FieldValidationError>
      </div>

      <div className="col-lg-4">
        <RegionsSelectInput
          fieldName={fieldNameRegion}
          label="Fylke"
          control={props.control}
          errors={props.errors}
          currentValue={props.currentValues?.region ? props.currentValues.region : undefined}
          countryId={location?.country?.value as number}
          disabled={!location?.country?.value}
          selectedYearOfOrigin={props.yearOfOrigin}
          isClearable
          onChange={(value) => {
            const updatedLocation = Object.assign({}, location, {
              region: value ? { value: value.value, label: value.label } : null,
              district: null,
            });

            setLocation(updatedLocation);

            if (props.onChange) {
              props.onChange(updatedLocation);
            }
          }}
        />

        <FieldValidationError error={props.errors[fieldNameRegion]}></FieldValidationError>
      </div>

      <div className="col-lg-4">
        <DistrictsSelectInput
          fieldName={fieldNameDistrict}
          label="Distrikt"
          control={props.control}
          errors={props.errors}
          currentValue={props.currentValues?.district ? props.currentValues.district : undefined}
          regionId={location?.region?.value as number}
          disabled={!location?.region?.value}
          selectedYearOfOrigin={props.yearOfOrigin}
          isClearable
          onChange={(value) => {
            const updatedLocation = Object.assign({}, location, {
              district: value ? { value: value.value, label: value.label } : null,
            });

            setLocation(updatedLocation);

            if (props.onChange) {
              props.onChange(updatedLocation);
            }
          }}
        />

        <FieldValidationError error={props.errors[fieldNameDistrict]}></FieldValidationError>
      </div>
    </div>
  );
};

export default LocationFields;

import "instantsearch.css/themes/algolia.css";
import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";

interface SearchBoxProps {
  currentRefinement: any;
  refine: any;
  onChange?: (value: string) => void;
}

const SearchBox: React.FunctionComponent<SearchBoxProps> = props => {
  const onChange = (e: any) => {
    props.refine(e.target.value);

    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  return (
    <input
      className="form-control form-control-dark"
      type="text"
      placeholder="Søk etter personer, fangesteder, historier og referanser..."
      aria-label="Søk"
      value={props.currentRefinement}
      onChange={onChange}
    />
  );
};

export default connectSearchBox(SearchBox);

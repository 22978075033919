import React from "react";
import FieldValidationError from "../FieldError/FieldError";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";

interface RemarksFieldsProps {
  register: any;
  errors: NestDataObject<any, FieldError>;
}

const PrivateRemarkField: React.FunctionComponent<RemarksFieldsProps> = (props) => {
  const fieldInternalRemark = `remarks.internalRemark`;

  return (
    <>
      <div className="form-group">
        <label htmlFor={fieldInternalRemark}>Notat (vises kun internt)</label>
        <textarea
          name={fieldInternalRemark}
          ref={props.register}
          rows={4}
          className={`form-control ${props.errors[fieldInternalRemark] ? "is-invalid" : "valid"}`}
        />

        <FieldValidationError error={props.errors[fieldInternalRemark]}></FieldValidationError>
      </div>
    </>
  );
};

export default PrivateRemarkField;

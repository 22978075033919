import { Link, useHistory } from "react-router-dom";
import { IAssignment } from "../../../interfaces/domain/IAssignment";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import { GET_ASSIGNMENT_ROUTE } from "../../../lib/Router/routes";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { FiTrash2 } from "react-icons/fi";
import { GET_TEACHING_SUBJECT_QUERY } from "../../../graphql/queries";
import { useMutation } from "@apollo/client";
import { IDeleteAssignmentMutationInput, IDeleteAssignmentMutationResult } from "../../../interfaces/mutations/IDeleteAssignmentMutation";
import { DELETE_ASSIGNMENT_MUTATION } from "../../../graphql/mutations";

interface AssignmentsColumnListProps {
  assignments: Array<IAssignment>;
  teachingSubjectId: number;
}

const AssignmentsColumnList: React.FunctionComponent<AssignmentsColumnListProps> = (props) => {
  const history = useHistory();
  const [deleteAttachment] = useMutation<IDeleteAssignmentMutationResult, IDeleteAssignmentMutationInput>(DELETE_ASSIGNMENT_MUTATION);

  const onDeleteAssignment = async (assignmentId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette oppgaven?`);

    if (deleteConfirmed) {
      await deleteAttachment({
        variables: {
          input: {
            id: assignmentId,
          },
        },
        refetchQueries: [{ query: GET_TEACHING_SUBJECT_QUERY, variables: { filter: { id: props.teachingSubjectId } } }],
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteAssignment)) {
            handleMutationSuccess(result.data);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  if (!props || !props.assignments || !props.assignments.length) return <DataNotFound />;

  return (
    <>
      <div className="list-group list-group-flush">
        {props.assignments.map((assignment) => (
          <li key={assignment.id} className="list-group-item list-group-item-action">
            <div className="row">
              <div className="d-flex w-100 row">
                <div className="col text-md-left">
                  {assignment.displayOrder}
                </div>
                <div className="col">
                  <Link className="col-auto d-flex align-items-center" to={GET_ASSIGNMENT_ROUTE(assignment.id)} key={assignment.id}>
                    {assignment.title}
                  </Link>
                </div>
                <div className="col-auto d-flex align-items-center text-md-right">
                  <button
                    className="btn btn-sm btn-secondary mr-2"
                    onClick={() => {
                      history.push(GET_ASSIGNMENT_ROUTE(assignment.id));
                    }}>
                    Rediger
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      onDeleteAssignment(assignment.id);
                    }}>
                    <FiTrash2 />
                  </button>
                </div>
              </div>

            </div>
          </li>
        ))}
      </div>
    </>
  )
}

export default AssignmentsColumnList;

import React from "react";
import { FiPlusCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { GET_ASSIGNMENT_CREATE_ROUTE, ROUTES } from "../../../lib/Router/routes";
import { IAssignment } from "../../../interfaces/domain/IAssignment";
import FormSubTitle from "../../_common/Form/FormSubTitle/FormSubTitle";
import { useAuth } from "../../../lib/hooks/useAuth";
import AssignmentsColumnList from "./AssignmentsColumnList";

interface AssignmentsColumnProps {
  assignments: Array<IAssignment>;
  teachingSubjectId: number;
}

const AssignmentsColumn: React.FunctionComponent<AssignmentsColumnProps> = (props) => {
  const { canVisit } = useAuth();

  return (
    <div>
      <div className="card shadow-sm">
        <div className="card-body border-bottom">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <FormSubTitle>Oppgaver</FormSubTitle>
            {canVisit(ROUTES.ADMIN.ASSIGNMENT_CREATE_ROUTE) &&
              <div className="btn-toolbar mb-2 mb-md-0">
                <NavLink to={GET_ASSIGNMENT_CREATE_ROUTE(props.teachingSubjectId)} className="btn btn-sm btn-outline-dark" role="button" exact>
                  <FiPlusCircle /> Legg til
                </NavLink>
              </div>}
          </div>
        </div>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <AssignmentsColumnList assignments={props.assignments} teachingSubjectId={props.teachingSubjectId}></AssignmentsColumnList>
        </div>
      </div>
    </div>
  );
}

export default AssignmentsColumn;

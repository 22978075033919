import React from "react";

import CreatePersonForm from "./CreatePersonForm/CreatePersonForm";

const CreatePerson: React.FunctionComponent = props => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Legg til person</h1>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <CreatePersonForm />
        </div>
      </div>
    </div>
  );
};

export default CreatePerson;

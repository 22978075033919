import { IEntityReferenceTypeEnum } from "../interfaces/forms/IEntityReferenceForm";
import { GET_PERSON_QUERY, GET_PRISON_CAMP_QUERY, GET_HISTORY_QUERY, GET_ASSIGNMENT_QUERY, GET_QUESTION_QUERY } from "../graphql/queries";

export const getEntityQueryByEntityReferenceType = (entityReferenceType: IEntityReferenceTypeEnum) => {
  switch (entityReferenceType) {
    case IEntityReferenceTypeEnum.PERSON:
      return GET_PERSON_QUERY;
    case IEntityReferenceTypeEnum.PRISON_CAMP:
      return GET_PRISON_CAMP_QUERY;
    case IEntityReferenceTypeEnum.HISTORY:
      return GET_HISTORY_QUERY;
    case IEntityReferenceTypeEnum.ASSIGNMENT:
      return GET_ASSIGNMENT_QUERY;
      case IEntityReferenceTypeEnum.QUESTION:
        return GET_QUESTION_QUERY;
  }
};

import { ISelectOption } from "../interfaces/domain/common/ISelectOption";
import { IAttachment } from "../interfaces/domain/IAttachment";

export const mapToSelectOption = (value: string | number, label: string) => {
  const selectOption: ISelectOption = {
    label: label,
    value: value,
  };

  return selectOption;
};

export const mapAttachmentToSelectOption = (attachment: IAttachment) => {
  return mapToSelectOption(attachment.id, attachment.sourceFileName);
};

import { useQuery } from "@apollo/client";
import React from "react";
import { Controller } from "react-hook-form";
import { Control, FieldError, NestDataObject } from "react-hook-form/dist/types";
import Select from "react-select";
import { GET_DISTRICTS_QUERY } from "../../../graphql/queries";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import { ISelectOption } from "../../../interfaces/domain/common/ISelectOption";
import { IGetDistrictsQueryFilter, IGetDistrictsQueryResult } from "../../../interfaces/queries/IGetDistrictsQuery";
import FieldValidationError from "../../_common/Form/FieldError/FieldError";
import QueryError from "../../_common/QueryError/QueryError";

interface DistrictsSelectInputProps {
  selectedYearOfOrigin?: number;
  disabled?: boolean;
  requiredField?: boolean;
  isClearable?: boolean;
  fieldName: string;
  errors: NestDataObject<any, FieldError>;
  currentValue?: ISelectOption;
  control: Control<any>;
  label?: string;
  required?: boolean;
  regionId?: number;
  onChange?: (value: ISelectOption) => void;
}

const DistrictsSelectInput: React.FunctionComponent<DistrictsSelectInputProps> = (props) => {
  const { loading, error, data } = useQuery<IGetDistrictsQueryResult, IGetDistrictsQueryFilter>(GET_DISTRICTS_QUERY, {
    variables: { filter: { yearOfOrigin: props.selectedYearOfOrigin, regionId: props.regionId } },
  });

  if (error) return <QueryError apolloError={error} />;

  const districts = !data || !data.districts || !data.districts.length ? [] : data.districts;

  return (
    <div className="form-group">
      {props.label && (
        <label className={props.required ? "required-field" : ""} htmlFor={props.fieldName}>
          {props.label}
        </label>
      )}

      <Controller
        as={Select}
        control={props.control}
        name={props.fieldName}
        value={props.currentValue ? props.currentValue : null}
        options={districts.map((district) => mapToSelectOption(district.id, district.name))}
        required={props.required}
        placeholder="Velg..."
        isLoading={loading}
        loadingMessage={() => {
          return "Henter...";
        }}
        noOptionsMessage={() => {
          return "Ingen valg";
        }}
        isDisabled={props.disabled}
        isClearable={props.isClearable}
        onChange={([value]) => {
          if (props.onChange) {
            props.onChange(value);
          }

          return value;
        }}
      />

      <FieldValidationError error={props.errors[props.fieldName]}></FieldValidationError>
    </div>
  );
};

export default DistrictsSelectInput;

import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { NavLink, useHistory } from "react-router-dom";
import { GET_PRISON_CAMP_REFERENCES_ROUTE, GET_PRISON_CAMP_PERSONS_ROUTE, GET_PRISON_CAMP_ROUTE, ROUTES } from "../../../lib/Router/routes";
import { DELETE_PRISON_CAMP_MUTATION } from "../../../graphql/mutations";
import { GET_PRISON_CAMP_QUERY } from "../../../graphql/queries";
import { IDeletePrisonCampMutationInput, IDeletePrisonCampMutationResult } from "../../../interfaces/mutations/IDeletePrisonCampMutation";
import { IGetPrisonCampQueryFilter, IGetPrisonCampQueryResult } from "../../../interfaces/queries/IGetPrisonCampQuery";
import { PrisonCampRouter } from "../../../lib/Router/Router";
import Audit from "../../_common/Audit/Audit";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { useAuth } from "../../../lib/hooks/useAuth";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface IEditPrisonCampProps {
  id: number;
}

const EditPrisonCamp: React.FunctionComponent<IEditPrisonCampProps> = (props) => {
  const { isAdmin } = useAuth();
  const history = useHistory();

  const { loading, error, data } = useQuery<IGetPrisonCampQueryResult, IGetPrisonCampQueryFilter>(GET_PRISON_CAMP_QUERY, {
    variables: { filter: { id: props.id } },
  });

  const [deletePrisonCamp] = useMutation<IDeletePrisonCampMutationResult, IDeletePrisonCampMutationInput>(DELETE_PRISON_CAMP_MUTATION);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.prisonCamp) return <DataNotFound />;

  const onDeletePrisonCamp = async (prisonCampId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette fangested: ${data.prisonCamp.name}?`);

    if (deleteConfirmed) {
      await deletePrisonCamp({
        variables: {
          input: {
            id: prisonCampId,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deletePrisonCamp)) {
            handleMutationSuccess(result.data);

            history.push(ROUTES.AUTHORIZED.PRISON_CAMPS_ROUTE);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <h1 className="h2">{data.prisonCamp.name}</h1>

        <div>
          {isAdmin() && (
            <ButtonToolbar className="d-flex justify-content-end">
              <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
                <Dropdown.Item
                  className="text-danger"
                  eventKey="1"
                  onClick={() => {
                    onDeletePrisonCamp(data.prisonCamp.id);
                  }}>
                  <FiTrash2 /> Slett
                </Dropdown.Item>
              </DropdownButton>
            </ButtonToolbar>
          )}

          <Audit audit={data.prisonCamp.audit}></Audit>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <NavLink to={GET_PRISON_CAMP_ROUTE(data.prisonCamp.id)} exact className="nav-link" activeClassName="active bg-white">
                Generelt
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={GET_PRISON_CAMP_PERSONS_ROUTE(data.prisonCamp.id)} exact className="nav-link" activeClassName="active bg-white">
                Personer
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={GET_PRISON_CAMP_REFERENCES_ROUTE(data.prisonCamp.id)}
                exact
                className="nav-link"
                activeClassName="active bg-white">
                Referanser
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <PrisonCampRouter prisonCamp={data.prisonCamp}></PrisonCampRouter>
        </div>
      </div>
    </div>
  );
};

export default EditPrisonCamp;

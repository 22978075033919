import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CREATE_ATTACHMENT_MUTATION } from "../../../graphql/mutations";
import { GET_REFERENCE_QUERY } from "../../../graphql/queries";
import { createAttachmentSchema } from "../../../helpers/validators/attachmentSchema";
import { IAttachmentForm } from "../../../interfaces/forms/IAttachmentForm";
import { ICreateAttachmentMutationInput, ICreateAttachmentMutationResult } from "../../../interfaces/mutations/ICreateAttachmentMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { IFileUpload } from "../../../interfaces/domain/IAttachment";
import FileUploader from "../../_common/FileUploader/FileUploader";
import { formatBytes, getAllowedFileUploadTypes } from "../../../helpers/fileHelpers";
import { getFileUploadLimit } from "../../../helpers/environmentHelpers";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface CreateAttachmentFormProps {
  referenceId: number;
  handleCloseModal: () => void;
}

const CreateAttachmentForm: React.FunctionComponent<CreateAttachmentFormProps> = (props) => {
  const [fileUpload, setFileUpload] = useState<IFileUpload | undefined>(undefined);
  const { register, handleSubmit, errors, formState, setValue } = useForm<IAttachmentForm>({
    validationSchema: createAttachmentSchema,
    mode: "onChange",
  });

  const [createAttachment] = useMutation<ICreateAttachmentMutationResult, ICreateAttachmentMutationInput>(CREATE_ATTACHMENT_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    if (fileUpload) {
      await createAttachment({
        variables: {
          input: {
            referenceId: props.referenceId,
            caption: formValues.caption,
            licensee: formValues.licensee,
            fileName: formValues.fileName,
            fileLength: fileUpload.fileLength,
            fileContentType: fileUpload.fileContentType,
            fileAsBase64: fileUpload.fileAsBase64,
          },
        },
        refetchQueries: [{ query: GET_REFERENCE_QUERY, variables: { filter: { id: props.referenceId } } }],
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.createAttachment)) {
            handleMutationSuccess(result.data);

            props.handleCloseModal();
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="caption">Tittel/bildetekst</label>
        <input type="text" name="caption" ref={register} className={`form-control ${errors.caption ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.caption}></FieldError>
      </div>

      <div className="form-group">
        <label htmlFor="licensee">Rettighetshaver</label>
        <input type="text" name="licensee" ref={register} className={`form-control ${errors.licensee ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.licensee}></FieldError>
      </div>

      <div className="form-group">
        <input type="hidden" name="fileName" ref={register} className={`form-control ${errors.fileName ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.fileName}></FieldError>
      </div>

      <FileUploader
        onAddFile={(uploadFile) => {
          setFileUpload(uploadFile);
          setValue("fileName", uploadFile.fileName, true);
        }}></FileUploader>

      <small id="passwordHelpBlock" className="form-text text-muted">
        Tillatt maksimal filstørrelse: {formatBytes(getFileUploadLimit())} <br />
        Filtyper: {getAllowedFileUploadTypes().join(", ")}
      </small>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary mt-3">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Laster opp...</span>
          </>
        ) : (
          <span>Legg til</span>
        )}
      </button>
    </form>
  );
};

export default CreateAttachmentForm;

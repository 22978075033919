import * as yup from "yup";
import { dateSchema } from "./dateSchema";

export const captivitySchema = yup.object({
  displayOrder: yup.string().required("Må fylles ut"),
  startDate: dateSchema,
  endDate: dateSchema,
  prisonCamp: yup.object({
    value: yup.number().required("Må fylles ut"),
  }),
  ending: yup.mixed().required("Må fylles ut"),
});

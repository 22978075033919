import { useParams } from "react-router-dom";
import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import CreateTeachingSubject from "../components/TeachingSubject/CreateTeachingSubject/CreateTeachingSubject";

const CreateTeachingSubjectPage: React.FunctionComponent = () => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <CreateTeachingSubject teachingCategoryId={id} />
}

export default CreateTeachingSubjectPage;

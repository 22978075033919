import { useMutation } from "@apollo/client";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Prompt, useHistory } from "react-router-dom";
import Select from "react-select";
import { CREATE_PERSON_MUTATION } from "../../../../graphql/mutations";
import { GetCivilStatusEnumAsSelectOptions, GetGenderEnumAsSelectOptions } from "../../../../helpers/enumHelpers";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../../helpers/formHelpers";
import { mapLocationFormToLocationMutationInput } from "../../../../helpers/locationHelpers";
import { personSchema } from "../../../../helpers/validators/personSchema";
import { ICivilStatusEnum } from "../../../../interfaces/domain/enums/ICivilStatusEnum";
import { IGenderEnum } from "../../../../interfaces/domain/enums/IGenderEnum";
import { ICitizenship } from "../../../../interfaces/domain/ICitizenship";
import { IHistoryOption } from "../../../../interfaces/domain/IHistory";
import { INationality } from "../../../../interfaces/domain/INationality";
import { IProfession } from "../../../../interfaces/domain/IProfession";
import { IRole } from "../../../../interfaces/domain/IRole";
import { IPersonForm } from "../../../../interfaces/forms/IPersonForm";
import { ICreatePersonMutationInput, ICreatePersonMutationResult } from "../../../../interfaces/mutations/ICreatePersonMutation";
import { GET_PERSON_ROUTE } from "../../../../lib/Router/routes";
import CitizenshipsSelectInput from "../../../Citizenship/CitizenshipsSelectInput/CitizenshipsSelectInput";
import HistoriesSelectInput from "../../../History/HistoriesSelectInput/HistoriesSelectInput";
import NationalitiesSelectInput from "../../../Nationality/NationalitiesSelectInput/NationalitiesSelectInput";
import ProfessionsSelectInput from "../../../Profession/ProfessionsSelectInput/ProfessionsSelectInput";
import RolesSelectInput from "../../../Role/RolesSelectInput/RolesSelectInput";
import DateFields from "../../../_common/Form/DateFields/DateFields";
import FieldError from "../../../_common/Form/FieldError/FieldError";
import FormSubTitle from "../../../_common/Form/FormSubTitle/FormSubTitle";
import LocationAddressArea from "../../../_common/Form/LocationFields/LocationAddressArea";
import LocationFields from "../../../_common/Form/LocationFields/LocationFields";
import WYSIWYGEditor from "../../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";
import AliasFields from "../../Form/AliasFields/AliasFields";

const CreatePersonForm: React.FunctionComponent = () => {
  const history = useHistory();
  const { register, setValue, getValues, handleSubmit, errors, formState, control } = useForm<IPersonForm>({
    validationSchema: personSchema,
    mode: "onChange",
    defaultValues: {
      roles: [{ value: 1, label: "Fange" }],
      nationalities: [{ value: 1, label: "Norge" }],
      citizenships: [{ value: 1, label: "Norge" }],
    },
  });

  const [createPerson] = useMutation<ICreatePersonMutationResult, ICreatePersonMutationInput>(CREATE_PERSON_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await createPerson({
      variables: {
        input: {
          identificationNumber: formValues.identificationNumber,
          krigsseilerRegisterId: formValues.krigsseilerRegisterId,
          krigsgraverRegisterId: formValues.krigsgraverRegisterId,
          soldatregisterId: formValues.soldatregisterId,
          firstName: formValues.firstName,
          middleName: formValues.middleName,
          lastName: formValues.lastName,
          birthDate: formValues.birthDate,
          deathDate: formValues.deathDate,
          remarks: formValues.remarks,
          gender: formValues.gender?.value as IGenderEnum,
          civilStatus: formValues.civilStatus?.value as ICivilStatusEnum,
          aliases: formValues.aliases,
          professions: formValues.professions?.map((profession) => {
            return { id: profession.value, name: profession.label } as IProfession;
          }),
          nationalities: formValues.nationalities?.map((nationality) => {
            return { id: nationality.value, name: nationality.label } as INationality;
          }),
          citizenships: formValues.citizenships?.map((citizenship) => {
            return { id: citizenship.value, name: citizenship.label } as ICitizenship;
          }),
          roles: formValues.roles?.map((role) => {
            return { id: role.value, name: role.label } as IRole;
          }),
          placeOfBirth1947: mapLocationFormToLocationMutationInput(formValues.placeOfBirth1947),
          placeOfBirth2020: mapLocationFormToLocationMutationInput(formValues.placeOfBirth2020),
          placeOfResidence1947: mapLocationFormToLocationMutationInput(formValues.placeOfResidence1947),
          placeOfResidence2020: mapLocationFormToLocationMutationInput(formValues.placeOfResidence2020),
          placeOfDeath1947: mapLocationFormToLocationMutationInput(formValues.placeOfDeath1947),
          placeOfDeath2020: mapLocationFormToLocationMutationInput(formValues.placeOfDeath2020),
          placeOfBirthAddressArea: {
            address: formValues.placeOfBirthAddressArea.address ? formValues.placeOfBirthAddressArea.address : undefined,
            area: formValues.placeOfBirthAddressArea.area ? formValues.placeOfBirthAddressArea.area : undefined,
          },
          placeOfResidenceAddressArea: {
            address: formValues.placeOfResidenceAddressArea.address ? formValues.placeOfResidenceAddressArea.address : undefined,
            area: formValues.placeOfResidenceAddressArea.area ? formValues.placeOfResidenceAddressArea.area : undefined,
          },
          placeOfDeathAddressArea: {
            address: formValues.placeOfDeathAddressArea.address ? formValues.placeOfDeathAddressArea.address : undefined,
            area: formValues.placeOfDeathAddressArea.area ? formValues.placeOfDeathAddressArea.area : undefined,
          },
          temporaryPlaceOfResidence: formValues.temporaryPlaceOfResidence,
          histories: formValues.histories?.map((history) => {
            return { id: history.value, title: history.label } as IHistoryOption;
          }),
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createPerson)) {
          handleMutationSuccess(result.data);
          history.push(GET_PERSON_ROUTE(result.data.createPerson.id));
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Prompt
        when={formState.dirty && !formState.isSubmitting}
        message="Du har endringer som ikke er lagret. Er du sikker på du vil fortsette? Endringene vil gå tapt hvis du fortsetter uten å lagre.
        Trykk OK for å gå videre uten å lagre, eller trykk Avbryt for å gå tilbake."
      />
      <div className="row">
        <div className="col-lg-7">
          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Primærnavn</FormSubTitle>

              <div className="form-row">
                <div className="col form-group">
                  <label htmlFor="firstName">Fornavn</label>

                  <input
                    type="text"
                    name="firstName"
                    ref={register}
                    className={`form-control ${errors.firstName ? "is-invalid" : "valid"}`}
                  />

                  <FieldError error={errors.firstName}></FieldError>
                </div>

                <div className="col form-group">
                  <label htmlFor="middleName">Mellomnavn</label>

                  <input
                    type="text"
                    name="middleName"
                    ref={register}
                    className={`form-control ${errors.middleName ? "is-invalid" : "valid"}`}
                  />

                  <FieldError error={errors.middleName}></FieldError>
                </div>

                <div className="col form-group">
                  <label htmlFor="lastName">Etternavn</label>

                  <input
                    type="text"
                    name="lastName"
                    ref={register}
                    className={`form-control ${errors.lastName ? "is-invalid" : "valid"}`}
                  />

                  <FieldError error={errors.lastName}></FieldError>
                </div>
              </div>

              <div className="form-group">
                <FormSubTitle>Tilleggsnavn</FormSubTitle>

                <AliasFields fieldName="aliases" control={control} register={register} errors={errors}></AliasFields>
              </div>
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Født</FormSubTitle>

              <label htmlFor="birthDate">Dato</label>
              <DateFields fieldName="birthDate" register={register} errors={errors.birthDate}></DateFields>

              <LocationAddressArea
                register={register}
                errors={errors}
                setValue={setValue}
                fieldName="placeOfBirthAddressArea"></LocationAddressArea>

              <h6>1947</h6>

              <LocationFields
                fieldName="placeOfBirth1947"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfBirth1947}
                onChange={(value) => {
                  setValue("placeOfBirth1947", value, true);
                }}
                yearOfOrigin={1947}></LocationFields>

              <h6>2020</h6>

              <LocationFields
                fieldName="placeOfBirth2020"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfBirth2020}
                onChange={(value) => {
                  setValue("placeOfBirth2020", value, true);
                }}
                yearOfOrigin={2020}></LocationFields>
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Død</FormSubTitle>

              <label htmlFor="birthDate">Dato</label>
              <DateFields fieldName="deathDate" register={register} errors={errors.deathDate}></DateFields>

              <LocationAddressArea
                register={register}
                errors={errors}
                setValue={setValue}
                fieldName="placeOfDeathAddressArea"></LocationAddressArea>

              <h6>1947</h6>

              <LocationFields
                fieldName="placeOfDeath1947"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfDeath1947}
                onChange={(value) => {
                  setValue("placeOfDeath1947", value, true);
                }}
                yearOfOrigin={1947}></LocationFields>

              <h6>2020</h6>

              <LocationFields
                fieldName="placeOfDeath2020"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfDeath2020}
                onChange={(value) => {
                  setValue("placeOfDeath2020", value, true);
                }}
                yearOfOrigin={2020}></LocationFields>
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <FormSubTitle>Bosted</FormSubTitle>

              <LocationAddressArea
                register={register}
                errors={errors}
                setValue={setValue}
                fieldName="placeOfResidenceAddressArea"></LocationAddressArea>

              <h6>1947</h6>

              <LocationFields
                fieldName="placeOfResidence1947"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfResidence1947}
                onChange={(value) => {
                  setValue("placeOfResidence1947", value, true);
                }}
                yearOfOrigin={1947}></LocationFields>

              <h6>2020</h6>

              <LocationFields
                fieldName="placeOfResidence2020"
                control={control}
                errors={errors}
                currentValues={getValues().placeOfResidence2020}
                onChange={(value) => {
                  setValue("placeOfResidence2020", value, true);
                }}
                yearOfOrigin={2020}></LocationFields>

              <label>Notat om midlertidig bosted</label>

              <textarea
                name="temporaryPlaceOfResidence"
                ref={register}
                className={`form-control ${errors.temporaryPlaceOfResidence ? "is-invalid" : "valid"}`}
                rows={3}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-5">
          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="gender">Kjønn</label>

                    <Controller
                      as={Select}
                      control={control}
                      name="gender"
                      value={getValues().gender}
                      options={GetGenderEnumAsSelectOptions()}
                      placeholder="Velg..."
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="civilStatus">Sivilstatus</label>

                    <Controller
                      as={Select}
                      control={control}
                      name="civilStatus"
                      value={getValues().civilStatus}
                      options={GetCivilStatusEnumAsSelectOptions()}
                      placeholder="Velg..."
                    />
                  </div>
                </div>
              </div>

              <ProfessionsSelectInput
                fieldName="professions"
                label="Yrker"
                control={control}
                errors={errors}
                currentValues={getValues().professions}
              />

              <NationalitiesSelectInput
                fieldName="nationalities"
                label="Landtilhørighet"
                control={control}
                errors={errors}
                currentValues={getValues().nationalities}
              />

              <CitizenshipsSelectInput
                fieldName="citizenships"
                label="Statsborgerskap"
                control={control}
                errors={errors}
                currentValues={getValues().citizenships}
              />

              <RolesSelectInput fieldName="roles" label="Roller" control={control} errors={errors} currentValues={getValues().roles} />

              <div className="form-group">
                <label htmlFor="krigsseilerRegisterId">ID fra Krigsseilerregisteret.no</label>
                <input
                  type="text"
                  name="krigsseilerRegisterId"
                  ref={register}
                  className={`form-control ${errors.krigsseilerRegisterId ? "is-invalid" : "valid"}`}
                />

                <FieldError error={errors.krigsseilerRegisterId}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="krigsgraverRegisterId">ID fra Krigsgraver.no</label>
                <input
                  type="text"
                  name="krigsgraverRegisterId"
                  ref={register}
                  className={`form-control ${errors.krigsgraverRegisterId ? "is-invalid" : "valid"}`}
                />

                <FieldError error={errors.krigsgraverRegisterId}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="soldatregisterId">Soldatregister Id</label>
                <input
                  type="text"
                  name="soldatregisterId"
                  ref={register}
                  className={`form-control ${errors.soldatregisterId ? "is-invalid" : "valid"}`}
                />

                <FieldError error={errors.soldatregisterId}></FieldError>
              </div>
            </div>
          </div>

          <div className="card shadow-sm">
            <div className="card-body">
              <FormSubTitle>Tilknyttede historier</FormSubTitle>

              <HistoriesSelectInput fieldName="histories" control={control} errors={errors} currentValues={getValues().histories} />
            </div>
          </div>

          <div className="card shadow-sm mt-3">
            <div className="card-body">
              <FormSubTitle>Notater</FormSubTitle>

              <label>Notat (vises kun internt)</label>

              <WYSIWYGEditor
                fieldName="remarks.internalRemark"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues().remarks?.internalRemark ? getValues().remarks?.internalRemark : ""}
              />

              <label>Notat (vil bli offentliggjort)</label>

              <WYSIWYGEditor
                fieldName="remarks.publicRemark"
                register={register}
                errors={errors}
                setValue={setValue}
                currentValue={getValues().remarks?.publicRemark ? getValues().remarks?.publicRemark : ""}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card shadow-sm mb-3 mt-3">
        <div className="card-body">
          <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Oppretter...</span>
              </>
            ) : (
              <span>Opprett</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreatePersonForm;

import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiFile, FiTrash2 } from "react-icons/fi";
import { DELETE_ATTACHMENT_MUTATION } from "../../../graphql/mutations";
import { GET_REFERENCE_QUERY } from "../../../graphql/queries";
import { formatBytes, isImage } from "../../../helpers/fileHelpers";
import { IAttachment } from "../../../interfaces/domain/IAttachment";
import { IDeleteAttachmentMutationInput, IDeleteAttachmentMutationResult } from "../../../interfaces/mutations/IDeleteAttachmentMutation";
import EditAttachmentForm from "../EditAttachment/EditAttachmentForm";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface AttachmentsListProps {
  attachments: Array<IAttachment>;
  referenceId: number;
}

const AttachmentsList: React.FunctionComponent<AttachmentsListProps> = (props) => {
  const [selectedEditAttachment, setSelectedEditAttachment] = useState<IAttachment | undefined>(undefined);
  const [deleteAttachment] = useMutation<IDeleteAttachmentMutationResult, IDeleteAttachmentMutationInput>(DELETE_ATTACHMENT_MUTATION);

  const handleCloseModal = () => setSelectedEditAttachment(undefined);
  const handleShowModal = (attachment: IAttachment) => setSelectedEditAttachment(attachment);
  const onDeleteAttachment = async (attachmentId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette vedlegget?`);

    if (deleteConfirmed) {
      await deleteAttachment({
        variables: {
          input: {
            id: attachmentId,
          },
        },
        refetchQueries: [{ query: GET_REFERENCE_QUERY, variables: { filter: { id: props.referenceId } } }],
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteAttachment)) {
            handleMutationSuccess(result.data);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  if (!props.attachments || !props.attachments.length) return <DataNotFound message="Ingen vedlegg" />;

  return (
    <>
      <ul className="list-group list-group-flush">
        {props.attachments.map((attachment) => (
          <li key={attachment.id} className="list-group-item list-group-item-action">
            <div className="row">
              <div className="col-auto d-flex align-items-center justify-content-center p-0 pl-1 width-50-pixels">
                {isImage(attachment.contentType) ? (
                  <img src={attachment.thumbnailFileDownloadPath} alt={attachment.caption} className="img-fluid rounded"></img>
                ) : (
                  <FiFile className="icon--size-xl" />
                )}
              </div>

              <div className="col">
                <a href={attachment.fileDownloadPath} download={attachment.fileName} target="_blank" rel="noopener noreferrer">
                  {attachment.sourceFileName}
                </a>
                <small className="text-muted"> {formatBytes(attachment.size)}</small>
                <p className="mb-0 text-muted">
                  {attachment.caption} {attachment.licensee && <span>({attachment.licensee})</span>}
                </p>
              </div>

              <div className="col-auto d-flex align-items-center text-md-right">
                <button
                  className="btn btn-sm btn-secondary mr-2"
                  onClick={() => {
                    handleShowModal(attachment);
                  }}>
                  Rediger
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    onDeleteAttachment(attachment.id);
                  }}>
                  <FiTrash2 />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {selectedEditAttachment && (
        <Modal show={!!selectedEditAttachment} size="lg" onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Rediger vedlegg</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditAttachmentForm
              attachment={selectedEditAttachment}
              referenceId={props.referenceId}
              handleCloseModal={handleCloseModal}></EditAttachmentForm>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default AttachmentsList;

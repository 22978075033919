import { IAssignment } from "../../../interfaces/domain/IAssignment";
import QuestionsColumn from "../../Question/Questions/QuestionsColumn";
import EditAssignmentForm from "./EditAssignmentForm";

interface IEditAssignmentProps {
  assignment: IAssignment;
}

const EditAssignmentFormContainer: React.FunctionComponent<IEditAssignmentProps> = (props) => {
  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-12 mb-3">
          <EditAssignmentForm assignment={props.assignment}></EditAssignmentForm>
        </div>
      </div>
    </div>
  )
}

export default EditAssignmentFormContainer;

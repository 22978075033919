import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";

import { GET_CITIZENSHIP_ROUTE } from "../../../lib/Router/routes";
import { GET_CITIZENSHIPS_QUERY } from "../../../graphql/queries";
import { IGetCitizenshipsQueryResult } from "../../../interfaces/queries/IGetCitizenshipsQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const CitizenshipsList: React.FunctionComponent = (props) => {
  const { loading, error, data } = useQuery<IGetCitizenshipsQueryResult>(GET_CITIZENSHIPS_QUERY);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.citizenships || !data.citizenships.length) return <DataNotFound />;

  return (
    <div className="CitizenshipsList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Navn</th>
          </tr>
        </thead>

        <tbody>
          {data.citizenships.map((citizenship) => (
            <tr key={citizenship.id}>
              <td>
                <Link to={GET_CITIZENSHIP_ROUTE(citizenship.id)}>{citizenship.name}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CitizenshipsList;

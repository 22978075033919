import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { FiUsers, FiActivity } from "react-icons/fi";
import { NavLink, Link } from "react-router-dom";
import { GET_PERSON_ROUTE, ROUTES } from "../../../lib/Router/routes";
import { GET_PERSONS_QUERY } from "../../../graphql/queries";
import { GetPersonFullName } from "../../../helpers/personHelpers";
import { IGetPersonsQueryResult, IGetPersonsQueryFilter } from "../../../interfaces/queries/IGetPersonsQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { GetFormattedDateTimeShort } from "../../../helpers/dateHelpers";
import { Modal } from "react-bootstrap";

const DashboardPersonsList: React.FunctionComponent = (props) => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  
  // Data in modal
  const updatedPastWeek = useQuery<IGetPersonsQueryResult, IGetPersonsQueryFilter>(GET_PERSONS_QUERY, {
    variables: { filter: { amountToReturn: 200, sortByLastUpdated: "DESC", updatedPastWeek: true}},
    skip: !showModal
  });

  // Data on dashboard
  const { loading, error, data } = useQuery<IGetPersonsQueryResult, IGetPersonsQueryFilter>(GET_PERSONS_QUERY, {
    variables: { filter: { amountToReturn: 5, sortByLastUpdated: "DESC"} },
  });
  
  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data) return <DataNotFound message="Ingen personer funnet" />;

  return (
    <>
    <div className="card shadow-sm ">
      <div className="card-header ">
        <div className="float-right">
          <button className="btn btn-sm btn-outline-dark mr-1" onClick={handleShowModal}>
                <FiActivity /> Oppdatert siste uke
          </button>
          
          <NavLink to={ROUTES.AUTHORIZED.PERSONS_ROUTE} className="btn btn-sm btn-outline-dark" role="button" exact>
            Vis alle
          </NavLink>
        </div>
        <h4 className="dashboard-card-header m-0">
          <FiUsers className="text-muted" /> Sist oppdaterte personprofiler
        </h4>

        <small className="text-muted">Totalt antall: {data.persons.totalPersonsCount}</small>
      </div>

      <div className="list-group list-group-flush">
        {data.persons.persons.map((person) => (
          <Link className="list-group-item list-group-item-action" to={GET_PERSON_ROUTE(person.id)} key={person.id}>
            <div className="d-flex w-100 justify-content-between">
              <div>{GetPersonFullName(person.firstName, person.middleName, person.lastName)}</div>

              <small className="text-muted">{GetFormattedDateTimeShort(person.audit.updatedAt)}</small>
            </div>
          </Link>
        ))}
      </div>
    </div>

    
    <Modal className="" show={showModal} size="lg" onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Personer oppdatert siste 7 dager</Modal.Title>
        </Modal.Header>
        
        {updatedPastWeek.loading ? 
        <Modal.Body><Loading /></Modal.Body> : 
        <Modal.Body className="p-0 pb-1">
          <div className="list-group list-group-flush">
            {updatedPastWeek.data && updatedPastWeek.data.persons.persons.map((person) => (
              <Link className="list-group-item list-group-item-action" to={GET_PERSON_ROUTE(person.id)} key={person.id}>
                <div className="d-flex w-100 justify-content-between">
                  <div>{GetPersonFullName(person.firstName, person.middleName, person.lastName)}</div>
                  <small className="text-muted">{person.audit.updatedBy && `${person.audit.updatedBy}, `}{GetFormattedDateTimeShort(person.audit.updatedAt)}</small>
                </div>
              </Link>
            ))}
          </div>
        </Modal.Body>
        }
      </Modal>
    </>
  );
};

export default DashboardPersonsList;

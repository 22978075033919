import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";

import { GET_NATIONALITY_ROUTE } from "../../../lib/Router/routes";
import { GET_NATIONALITIES_QUERY } from "../../../graphql/queries";
import { IGetNationalitiesQueryResult } from "../../../interfaces/queries/IGetNationalitiesQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const NationalitiesList: React.FunctionComponent = (props) => {
  const { loading, error, data } = useQuery<IGetNationalitiesQueryResult>(GET_NATIONALITIES_QUERY);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.nationalities || !data.nationalities.length) return <DataNotFound />;

  return (
    <div className="NationalitiesList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Navn</th>
          </tr>
        </thead>

        <tbody>
          {data.nationalities.map((nationality) => (
            <tr key={nationality.id}>
              <td>
                <Link to={GET_NATIONALITY_ROUTE(nationality.id)}>{nationality.name}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NationalitiesList;

import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { DELETE_PROFESSION_MUTATION } from "../../../graphql/mutations";
import { IDeleteProfessionMutationResult, IDeleteProfessionMutationInput } from "../../../interfaces/mutations/IDeleteProfessionMutation";
import { GET_PROFESSION_QUERY } from "../../../graphql/queries";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { IGetProfessionQueryFilter, IGetProfessionQueryResult } from "../../../interfaces/queries/IGetProfessionQuery";
import { ROUTES } from "../../../lib/Router/routes";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import EditProfessionForm from "./EditProfessionForm";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";

interface IEditProfessionProps {
  id: number;
}

const EditProfession: React.FunctionComponent<IEditProfessionProps> = (props) => {
  const history = useHistory();

  const { loading, error, data } = useQuery<IGetProfessionQueryResult, IGetProfessionQueryFilter>(GET_PROFESSION_QUERY, {
    variables: { filter: { id: props.id } },
  });

  const [deleteProfession] = useMutation<IDeleteProfessionMutationResult, IDeleteProfessionMutationInput>(DELETE_PROFESSION_MUTATION);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.profession) return <DataNotFound />;

  const onDeleteProfession = async (professionId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette yrket: ${data.profession.name}?`);

    if (deleteConfirmed) {
      await deleteProfession({
        variables: {
          input: {
            id: professionId,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteProfession)) {
            handleMutationSuccess(result.data);
            history.push(ROUTES.ADMIN.PROFESSIONS_ROUTE);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">{data.profession.name}</h1>

        <div>
          <ButtonToolbar className="d-flex justify-content-end">
            <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
              <Dropdown.Item
                eventKey="1"
                disabled={data.profession.canBeDeleted === false}
                className={data.profession.canBeDeleted === false ? "" : "text-danger"}
                onClick={() => {
                  onDeleteProfession(data.profession.id);
                }}>
                <FiTrash2 /> Slett yrke
              </Dropdown.Item>
            </DropdownButton>
          </ButtonToolbar>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <EditProfessionForm profession={data.profession}></EditProfessionForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfession;

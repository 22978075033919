import React from "react";
import FieldValidationError from "../FieldError/FieldError";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";

interface CoordinatesFieldsProps {
  fieldName?: string;
  register: any;
  errors: NestDataObject<any, FieldError>;
}

const CoordinatesFields: React.FunctionComponent<CoordinatesFieldsProps> = (props) => {
  const fieldNameLatitude = `coordinates.latitude`;
  const fieldNameLongitude = `coordinates.longitude`;
  const showLabels = true;

  return (
    <div className="form-row">
      <div className="col form-group">
        {showLabels && <label htmlFor={fieldNameLatitude}>Breddegrad</label>}

        <input
          type="text"
          name={fieldNameLatitude}
          ref={props.register}
          className={`form-control ${props.errors[fieldNameLatitude] ? "is-invalid" : "valid"}`}
        />

        <FieldValidationError error={props.errors[fieldNameLatitude]}></FieldValidationError>
      </div>

      <div className="col form-group">
        {showLabels && <label htmlFor={fieldNameLongitude}>Lengdegrad</label>}

        <input
          type="text"
          name={fieldNameLongitude}
          ref={props.register}
          className={`form-control ${props.errors[fieldNameLongitude] ? "is-invalid" : "valid"}`}
        />

        <FieldValidationError error={props.errors[fieldNameLongitude]}></FieldValidationError>
      </div>
    </div>
  );
};

export default CoordinatesFields;

import { IAlgoliaRefinementItem } from "../interfaces/algolia/IAlgolia";
import { NOT_PROVIDED } from "./locationHelpers";

export const sortRefinementItemsAlphabetically = (a: IAlgoliaRefinementItem, b: IAlgoliaRefinementItem) => {
  if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
  if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
  return 0;
};

export const menuSelectTransformer = (items: Array<IAlgoliaRefinementItem>) => {
  return items.sort((a, b) => {
    return sortRefinementItemsAlphabetically(a, b);
  });
};

export const placeRefinementTransformer = (items: Array<IAlgoliaRefinementItem>) => {
  items.sort((a, b) => {
    return sortRefinementItemsAlphabetically(a, b);
  });
  const notProvidedIndex = items.map((item) => item.value).indexOf(NOT_PROVIDED);
  items.push(items.splice(notProvidedIndex, 1)[0]);
  return items;
};

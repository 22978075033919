import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { GET_DISTRICT_ROUTE } from "../../../lib/Router/routes";
import { GET_DISTRICTS_QUERY } from "../../../graphql/queries";
import { IGetDistrictsQueryResult } from "../../../interfaces/queries/IGetDistrictsQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const DistrictsList: React.FunctionComponent = (props) => {
  const { loading, error, data } = useQuery<IGetDistrictsQueryResult>(GET_DISTRICTS_QUERY);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.districts || !data.districts.length) return <DataNotFound />;

  return (
    <div className="DistrictsList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Navn</th>
            <th scope="col">Kommunekode</th>
            <th scope="col">Opprinnelsesår</th>
          </tr>
        </thead>

        <tbody>
          {data.districts.map((district) => (
            <tr key={district.id}>
              <td>
                <Link to={GET_DISTRICT_ROUTE(district.id)}>{district.name}</Link>
              </td>
              <td>{district.code}</td>
              <td>{district.yearOfOrigin}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DistrictsList;

import { useMutation } from "@apollo/client";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { CREATE_REGION_MUTATION } from "../../../graphql/mutations";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { getYearOfOriginSelectOptions } from "../../../helpers/locationHelpers";
import { regionSchema } from "../../../helpers/validators/regionSchema";
import { IRegionForm } from "../../../interfaces/forms/IRegionForm";
import { ICreateRegionMutationInput, ICreateRegionMutationResult } from "../../../interfaces/mutations/ICreateRegionMutation";
import { ROUTES } from "../../../lib/Router/routes";
import CountriesSelectInput from "../../Country/CountriesSelectInput/CountriesSelectInput";
import FieldError from "../../_common/Form/FieldError/FieldError";

const CreateRegionForm: React.FunctionComponent = () => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState, getValues, control, watch, setValue } = useForm<IRegionForm>({
    validationSchema: regionSchema,
    mode: "onChange",
  });

  const watchYearOfOrigin = watch("yearOfOrigin");
  const [createRegion] = useMutation<ICreateRegionMutationResult, ICreateRegionMutationInput>(CREATE_REGION_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await createRegion({
      variables: {
        input: {
          name: formValues.name,
          code: formValues.code,
          yearOfOrigin: formValues.yearOfOrigin.value as number,
          countryId: formValues.country.value as number,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createRegion)) {
          handleMutationSuccess(result.data);
          history.push(ROUTES.ADMIN.REGIONS_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="yearOfOrigin">
          Opprinnelsesår
        </label>

        <Controller
          as={Select}
          control={control}
          name="yearOfOrigin"
          value={getValues().yearOfOrigin}
          options={getYearOfOriginSelectOptions()}
          placeholder="Velg..."
          onChange={([value]) => {
            setValue("country", null!);
            return value;
          }}
        />
      </div>

      <CountriesSelectInput
        fieldName="country"
        label="Land"
        required
        control={control}
        errors={errors}
        currentValue={getValues().country}
        disabled={!watchYearOfOrigin}
        selectedYearOfOrigin={getValues().yearOfOrigin ? (getValues().yearOfOrigin.value as number) : undefined}
      />

      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <div className="form-group">
        <label className="required-field" htmlFor="code">
          Fylkekode
        </label>
        <input type="text" name="code" ref={register} className={`form-control ${errors.code ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.code}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Oppretter...</span>
          </>
        ) : (
          <span>Opprett</span>
        )}
      </button>
    </form>
  );
};

export default CreateRegionForm;

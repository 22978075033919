import React from "react";
import { GetFormattedDateTimeLong } from "../../../helpers/dateHelpers";
import { IPublishStatus } from "../../../interfaces/domain/common/IPublishStatus";

interface PublishStatusProps {
  publishStatus: IPublishStatus;
}

const PublishStatus: React.FunctionComponent<PublishStatusProps> = props => {
  return (
    <div className="text-muted">
      <small>Sist publisert: {props.publishStatus.publishedAt ? GetFormattedDateTimeLong(props.publishStatus.publishedAt) : "Aldri"}</small>
      {props.publishStatus.publishedBy && <small> Av: {props.publishStatus.publishedBy}</small>}
    </div>
  );
};

export default PublishStatus;

import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";

import { GET_COUNTRY_ROUTE } from "../../../lib/Router/routes";
import { GET_COUNTRIES_QUERY } from "../../../graphql/queries";
import { IGetCountriesQueryResult, IGetCountriesQueryFilter } from "../../../interfaces/queries/IGetCountriesQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const CountriesList: React.FunctionComponent = (props) => {
  const { loading, error, data } = useQuery<IGetCountriesQueryResult, IGetCountriesQueryFilter>(GET_COUNTRIES_QUERY);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.countries || !data.countries.length) return <DataNotFound />;

  return (
    <div className="CountriesList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Navn</th>
            <th scope="col">Kode</th>
            <th scope="col">Opprinnelsesår</th>
          </tr>
        </thead>

        <tbody>
          {data.countries.map((country) => (
            <tr key={country.id}>
              <td>
                <Link to={GET_COUNTRY_ROUTE(country.id)}>{country.name}</Link>
              </td>
              <td>{country.code}</td>
              <td>{country.yearOfOrigin}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CountriesList;

import { Link, useHistory } from "react-router-dom";
import { ITeachingCategory } from "../../../interfaces/domain/ITeachingCategory";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import { GET_TEACHING_CATEGORY_ROUTE } from "../../../lib/Router/routes";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { FiTrash2 } from "react-icons/fi";
import { GET_TEACHING_MODULE_QUERY } from "../../../graphql/queries";
import { useMutation } from "@apollo/client";
import { IDeleteTeachingCategoryMutationInput, IDeleteTeachingCategoryMutationResult } from "../../../interfaces/mutations/IDeleteTeachingCategoryMutation";
import { DELETE_TEACHING_CATEGORY_MUTATION } from "../../../graphql/mutations";

interface TeachingCategoriesColumnListProps {
  teachingCategories: Array<ITeachingCategory>;
  teachingModuleId: number;
}

const TeachingCategoriesColumnList: React.FunctionComponent<TeachingCategoriesColumnListProps> = (props) => {
  const history = useHistory();
  const [deleteAttachment] = useMutation<IDeleteTeachingCategoryMutationResult, IDeleteTeachingCategoryMutationInput>(DELETE_TEACHING_CATEGORY_MUTATION);

  const onDeleteTeachingCategory = async (teachingCategorytId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette kategorien?`);

    if (deleteConfirmed) {
      await deleteAttachment({
        variables: {
          input: {
            id: teachingCategorytId,
          },
        },
        refetchQueries: [{ query: GET_TEACHING_MODULE_QUERY, variables: { filter: { id: props.teachingModuleId } } }],
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteTeachingCategory)) {
            handleMutationSuccess(result.data);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  if (!props || !props.teachingCategories || !props.teachingCategories.length) return <DataNotFound />;

  return (
    <>
      <div className="list-group list-group-flush">
        {props.teachingCategories.map((teachingCategory) => (
          <li key={teachingCategory.id} className="list-group-item list-group-item-action">
            <div className="row">
              <div className="d-flex w-100 row">
                <div className="col text-md-left">
                  {teachingCategory.displayOrder}
                </div>
                <div className="col">
                  <Link className="col-auto d-flex align-items-center" to={GET_TEACHING_CATEGORY_ROUTE(teachingCategory.id)} key={teachingCategory.id}>
                    {teachingCategory.title}
                  </Link>
                </div>
                <div className="col-auto d-flex align-items-center text-md-right">
                  <button
                    className="btn btn-sm btn-secondary mr-2"
                    onClick={() => {
                      history.push(GET_TEACHING_CATEGORY_ROUTE(teachingCategory.id));
                    }}>
                    Rediger
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      onDeleteTeachingCategory(teachingCategory.id);
                    }}>
                    <FiTrash2 />
                  </button>
                </div>
              </div>

            </div>
          </li>
        ))}
      </div>
    </>
  )
}

export default TeachingCategoriesColumnList;

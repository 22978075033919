import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { UPDATE_ATTACHMENT_MUTATION } from "../../../graphql/mutations";
import { GET_REFERENCE_QUERY } from "../../../graphql/queries";
import { updateAttachmentSchema } from "../../../helpers/validators/attachmentSchema";
import { IAttachment } from "../../../interfaces/domain/IAttachment";
import { IAttachmentForm } from "../../../interfaces/forms/IAttachmentForm";
import { IUpdateAttachmentMutationInput, IUpdateAttachmentMutationResult } from "../../../interfaces/mutations/IUpdateAttachmentMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface IEditAttachmentFormProps {
  attachment: IAttachment;
  referenceId: number;
  handleCloseModal: () => void;
}

const EditAttachmentForm: React.FunctionComponent<IEditAttachmentFormProps> = (props) => {
  const { register, handleSubmit, errors, formState } = useForm<IAttachmentForm>({
    validationSchema: updateAttachmentSchema,
    mode: "onChange",
    defaultValues: {
      caption: props.attachment.caption,
      licensee: props.attachment.licensee,
      fileName: props.attachment.fileName,
    },
  });

  const [updateAttachment] = useMutation<IUpdateAttachmentMutationResult, IUpdateAttachmentMutationInput>(UPDATE_ATTACHMENT_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateAttachment({
      variables: {
        input: {
          id: props.attachment.id,
          caption: formValues.caption,
          licensee: formValues.licensee,
        },
      },
      refetchQueries: [{ query: GET_REFERENCE_QUERY, variables: { filter: { id: props.referenceId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateAttachment)) {
          handleMutationSuccess(result.data);

          props.handleCloseModal();
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="caption">Tittel/bildetekst</label>
        <input type="text" name="caption" ref={register} className={`form-control ${errors.caption ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.caption}></FieldError>
      </div>

      <div className="form-group">
        <label htmlFor="licensee">Rettighetshaver</label>
        <input type="text" name="licensee" ref={register} className={`form-control ${errors.licensee ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.licensee}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditAttachmentForm;

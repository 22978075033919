import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { GENERATE_RESET_PASSWORD_TOKEN_MUTATION } from "../../../graphql/mutations";
import { generateResetPasswordSchema } from "../../../helpers/validators/generateResetPasswordSchema";
import { IGenerateResetPasswordForm } from "../../../interfaces/forms/IGenerateResetPasswordForm";
import {
  IGenerateResetPasswordTokenMutationInput,
  IGenerateResetPasswordTokenMutationResult,
} from "../../../interfaces/mutations/IGenerateResetPasswordTokenMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { useHistory, Link } from "react-router-dom";
import { mutationIsSuccessful, handleMutationError, handleMutationSuccess } from "../../../helpers/formHelpers";
import { ROUTES } from "../../../lib/Router/routes";

const GenerateResetPasswordForm: React.FunctionComponent = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState } = useForm<IGenerateResetPasswordForm>({
    validationSchema: generateResetPasswordSchema,
    mode: "onChange",
  });

  const [generateResetPasswordMutation] = useMutation<IGenerateResetPasswordTokenMutationResult, IGenerateResetPasswordTokenMutationInput>(
    GENERATE_RESET_PASSWORD_TOKEN_MUTATION
  );

  const onSubmit = handleSubmit(async (formValues) => {
    await generateResetPasswordMutation({
      variables: {
        input: {
          email: formValues.email,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.generateResetPasswordToken)) {
          handleMutationSuccess(result.data, "Hvis e-posten din finnes i systemet vil du motta e-post.");

          history.push(ROUTES.PUBLIC.RESET_PASSWORD_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError();
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <h1 className="display-4 text-center mb-4">Nullstill passord</h1>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="email">E-post</label>

            <input
              type="email"
              name="email"
              ref={register}
              className={`form-control ${errors.email ? "is-invalid" : "valid"}`}
              placeholder="E-post"
              autoComplete="off"
            />

            <FieldError error={errors.email}></FieldError>
          </div>

          <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-lg btn-primary btn-block">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Sender...</span>
              </>
            ) : (
              <span>Send</span>
            )}
          </button>

          <p className="mt-3 text-center">
            <Link to={ROUTES.PUBLIC.SIGN_IN_ROUTE} className="btn btn-link">
              Tilbake til innlogging
            </Link>
            <Link to={ROUTES.PUBLIC.RESET_PASSWORD_ROUTE} className="btn btn-link">
              Har du kode?
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default GenerateResetPasswordForm;

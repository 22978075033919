import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../lib/Router/routes";
import { CREATE_USER_MUTATION } from "../../../graphql/mutations";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { userSchema } from "../../../helpers/validators/userSchema";
import { IUserForm } from "../../../interfaces/forms/IUserForm";
import { ICreateUserMutationInput, ICreateUserMutationResult } from "../../../interfaces/mutations/ICreateUserMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";

const CreateUserForm: React.FunctionComponent = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState } = useForm<IUserForm>({
    validationSchema: userSchema,
    mode: "onChange",
  });

  const [createUserMutation] = useMutation<ICreateUserMutationResult, ICreateUserMutationInput>(CREATE_USER_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await createUserMutation({
      variables: {
        input: {
          email: formValues.email,
          password: formValues.password,
          fullName: formValues.fullName,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createUser)) {
          handleMutationSuccess(result.data, "Bruker opprettet.");

          history.push(ROUTES.ADMIN.USERS_ROUTE);
        } else {
          handleMutationError(undefined, "Kunne ikke opprette bruker");
        }
      })
      .catch((error) => {
        handleMutationError(error, "Kunne ikke opprette bruker");
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="email">E-post (brukernavn)</label>

            <input
              type="text"
              name="email"
              ref={register}
              className={`form-control ${errors.email ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />

            <FieldError error={errors.email}></FieldError>
          </div>

          <div className="form-group">
            <label htmlFor="newPassword">Passord</label>

            <input
              type="password"
              name="password"
              ref={register}
              className={`form-control ${errors.password ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />

            <FieldError error={errors.password}></FieldError>
          </div>

          <div className="form-group">
            <label htmlFor="fullName">Navn</label>

            <input
              type="text"
              name="fullName"
              ref={register}
              className={`form-control ${errors.fullName ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />

            <FieldError error={errors.fullName}></FieldError>
          </div>
        </div>

        <div className="card-footer">
          <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Oppretter...</span>
              </>
            ) : (
              <span>Opprett</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateUserForm;

import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { DELETE_ENTITY_REFERENCE_MUTATION } from "../../../graphql/mutations";
import { IEntityReference } from "../../../interfaces/domain/IEntityReference";
import { IEntityReferenceTypeEnum } from "../../../interfaces/forms/IEntityReferenceForm";
import {
  IDeleteEntityReferenceMutationInput,
  IDeleteEntityReferenceMutationResult,
} from "../../../interfaces/mutations/IDeleteEntityReferenceMutation";
import EditEntityReferenceForm from "./Forms/EditEntityReferenceForm";
import { getEntityQueryByEntityReferenceType } from "../../../helpers/entityReferenceHelpers";
import { GetReadableReferenceCategoryName } from "../../../helpers/enumHelpers";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import { Link } from "react-router-dom";
import { GET_REFERENCE_ROUTE } from "../../../lib/Router/routes";

interface EntityReferencesListProps {
  entityReferences: Array<IEntityReference>;
  entityType: IEntityReferenceTypeEnum;
  entityId: number;
}

const EntityReferencesList: React.FunctionComponent<EntityReferencesListProps> = (props) => {
  const [selectedEditEntityReference, setSelectedEditEntityReference] = useState<IEntityReference | undefined>(undefined);
  const [deleteEntityReference] = useMutation<IDeleteEntityReferenceMutationResult, IDeleteEntityReferenceMutationInput>(
    DELETE_ENTITY_REFERENCE_MUTATION
  );

  const handleCloseModal = () => setSelectedEditEntityReference(undefined);
  const handleShowModal = (entityReference: IEntityReference) => setSelectedEditEntityReference(entityReference);

  const onDeletePersonReference = async (entityReferenceId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette referansekoblingen?`);

    if (deleteConfirmed) {
      await deleteEntityReference({
        variables: {
          input: {
            entityReferenceId: entityReferenceId,
            entityType: props.entityType,
          },
        },
        refetchQueries: [{ query: getEntityQueryByEntityReferenceType(props.entityType), variables: { filter: { id: props.entityId } } }],
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteEntityReference)) {
            handleMutationSuccess(result.data);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  if (!props.entityReferences || props.entityReferences.length === 0) {
    return <DataNotFound></DataNotFound>;
  }

  return (
    <div className="EntityReferencesList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Tittel</th>
            <th scope="col">Kildens referansenavn</th>
            <th scope="col">Kategori</th>
            <th scope="col">Individuell kommentar</th>
            <th scope="col">Individuell URL</th>
            <th scope="col"></th>
          </tr>
        </thead>

        <tbody>
          {props.entityReferences.map((entityReference) => (
            <tr key={entityReference.id}>
              <td>{entityReference.reference.id}</td>
              <td>
                <Link to={GET_REFERENCE_ROUTE(entityReference.reference.id)}>{entityReference.reference.title}</Link>
              </td>
              <td>{entityReference.reference.source}</td>
              <td>{GetReadableReferenceCategoryName(entityReference.reference.category)}</td>
              <td>{entityReference.contextualComment}</td>
              <td>{entityReference.contextualUrl}</td>
              <td className="text-right">
                <button
                  className="btn btn-sm btn-secondary mr-2"
                  onClick={() => {
                    handleShowModal(entityReference);
                  }}>
                  Rediger
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    onDeletePersonReference(entityReference.id);
                  }}>
                  <FiTrash2 />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedEditEntityReference && (
        <Modal show={!!selectedEditEntityReference} dialogClassName="vw-100" size="xl" onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Rediger referansekobling</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditEntityReferenceForm
              entityReference={selectedEditEntityReference}
              entityType={props.entityType}
              entityId={props.entityId}
              handleClose={handleCloseModal}></EditEntityReferenceForm>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default EntityReferencesList;

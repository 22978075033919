import React from "react";
import FieldValidationError from "../FieldError/FieldError";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";
import { ICertaintyEnum } from "../../../../interfaces/domain/enums/ICertaintyEnum";
import { GetReadableCertaintyName } from "../../../../helpers/enumHelpers";
import { IDate } from "../../../../interfaces/domain/IDate";

interface CertaintyFieldProps {
  fieldName?: string;
  label?: string;
  register: any;
  errors: NestDataObject<IDate, FieldError> | undefined;
}

const CertaintyField: React.FunctionComponent<CertaintyFieldProps> = (props) => {
  const fieldName = props.fieldName ? props.fieldName : `certainty`;

  return (
    <div className="form-group">
      {props.label && <label htmlFor={fieldName}>{props.label}</label>}

      <select name={fieldName} className="form-control custom-select" ref={props.register}>
        <option value={ICertaintyEnum.CERTAIN}>{GetReadableCertaintyName(ICertaintyEnum.CERTAIN)}</option>
        <option value={ICertaintyEnum.ASSUMED}>{GetReadableCertaintyName(ICertaintyEnum.ASSUMED)}</option>
        <option value={ICertaintyEnum.APPROXIMATELY}>{GetReadableCertaintyName(ICertaintyEnum.APPROXIMATELY)}</option>
        <option value={ICertaintyEnum.BEFORE}>{GetReadableCertaintyName(ICertaintyEnum.BEFORE)}</option>
        <option value={ICertaintyEnum.AFTER}>{GetReadableCertaintyName(ICertaintyEnum.AFTER)}</option>
        <option value={ICertaintyEnum.UNCERTAIN}>{GetReadableCertaintyName(ICertaintyEnum.UNCERTAIN)}</option>
      </select>

      <FieldValidationError error={props.errors?.certainty}></FieldValidationError>
    </div>
  );
};

export default CertaintyField;

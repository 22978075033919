import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { ATTACH_ENTITY_REFERENCE_MUTATION } from "../../../../graphql/mutations";
import { attachEntityReferenceSchema } from "../../../../helpers/validators/attachEntityReferenceSchema";
import { IAttachEntityReferenceForm, IEntityReferenceTypeEnum } from "../../../../interfaces/forms/IEntityReferenceForm";
import {
  IAttachEntityReferenceMutationInput,
  IAttachEntityReferenceMutationResult,
} from "../../../../interfaces/mutations/IAttachEntityReferenceMutation";
import FieldError from "../../../_common/Form/FieldError/FieldError";
import { getEntityQueryByEntityReferenceType } from "../../../../helpers/entityReferenceHelpers";
import ReferenceSelectInput from "../../ReferenceSelectInput/ReferenceSelectInput";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../../helpers/formHelpers";

interface AttachEntityReferenceFormProps {
  entityType: IEntityReferenceTypeEnum;
  entityId: number;
  handleClose: () => void;
}

const AttachEntityReferenceForm: React.FunctionComponent<AttachEntityReferenceFormProps> = (props) => {
  const { register, handleSubmit, errors, formState, getValues, control } = useForm<IAttachEntityReferenceForm>({
    validationSchema: attachEntityReferenceSchema,
    mode: "onChange",
  });

  const [attachEntityReference] = useMutation<IAttachEntityReferenceMutationResult, IAttachEntityReferenceMutationInput>(
    ATTACH_ENTITY_REFERENCE_MUTATION
  );

  const onSubmit = handleSubmit(async (formValues) => {
    await attachEntityReference({
      variables: {
        input: {
          entityType: props.entityType,
          entityId: props.entityId,
          referenceId: formValues.reference.value as number,
          contextualComment: formValues.contextualComment,
          contextualUrl: formValues.contextualUrl,
        },
      },
      refetchQueries: [{ query: getEntityQueryByEntityReferenceType(props.entityType), variables: { filter: { id: props.entityId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.attachEntityReference)) {
          handleMutationSuccess(result.data);
          props.handleClose();
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <ReferenceSelectInput
        fieldName="reference"
        required
        label="Referanse"
        control={control}
        errors={errors}
        currentValue={getValues().reference}
      />

      <div className="form-group">
        <label htmlFor="name">Individuell URL</label>
        <input
          type="text"
          name="contextualUrl"
          ref={register}
          className={`form-control ${errors.contextualUrl ? "is-invalid" : "valid"}`}
        />

        <FieldError error={errors.contextualUrl}></FieldError>
      </div>

      <div className="form-group">
        <label htmlFor="name">Individuell kommentar</label>
        <textarea
          name="contextualComment"
          ref={register}
          rows={4}
          className={`form-control ${errors.contextualComment ? "is-invalid" : "valid"}`}
        />

        <FieldError error={errors.contextualComment}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Tilknytter...</span>
          </>
        ) : (
          <span>Tilknytt</span>
        )}
      </button>
    </form>
  );
};

export default AttachEntityReferenceForm;

import { useQuery } from "@apollo/client";
import React from "react";

import { GET_CITIZENSHIP_QUERY } from "../../../graphql/queries";
import { IGetCitizenshipQueryFilter, IGetCitizenshipQueryResult } from "../../../interfaces/queries/IGetCitizenshipQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import EditCitizenshipForm from "./EditCitizenshipForm";

interface IEditCitizenshipProps {
  id: number;
}

const EditCitizenship: React.FunctionComponent<IEditCitizenshipProps> = (props) => {
  const { loading, error, data } = useQuery<IGetCitizenshipQueryResult, IGetCitizenshipQueryFilter>(GET_CITIZENSHIP_QUERY, {
    variables: { filter: { id: props.id } },
  });

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.citizenship) return <DataNotFound />;

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">{data.citizenship.name}</h1>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="card shadow-sm">
            <div className="card-body">
              <EditCitizenshipForm citizenship={data.citizenship}></EditCitizenshipForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCitizenship;

import React from "react";
import FieldValidationError from "../../../_common/Form/FieldError/FieldError";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";

interface Props {
  fieldName?: string;
  register: any;
  setValue: any;
  errors: NestDataObject<any, FieldError>;
}

const LocationAddressArea: React.FunctionComponent<Props> = (props) => {
  const fieldNameAddress = props.fieldName ? `${props.fieldName}.address` : "address";
  const fieldNameArea = props.fieldName ? `${props.fieldName}.area` : "area";

  return (
    <>
      <div className="form-row">
        <div className="col-lg-6 form-group">
          <label htmlFor={fieldNameAddress}>Adresse</label>

          <input
            type="text"
            name={fieldNameAddress}
            ref={props.register}
            className={`form-control ${props.errors[fieldNameAddress] ? "is-invalid" : "valid"}`}
          />

          <FieldValidationError error={props.errors[fieldNameAddress]}></FieldValidationError>
        </div>

        <div className="col-lg-6">
          <label htmlFor={fieldNameArea}>Område</label>

          <input
            type="text"
            name={fieldNameArea}
            ref={props.register}
            className={`form-control ${props.errors[fieldNameArea] ? "is-invalid" : "valid"}`}
          />
          <FieldValidationError error={props.errors[fieldNameArea]}></FieldValidationError>
        </div>
      </div>
    </>
  );
};

export default LocationAddressArea;

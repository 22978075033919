import React from "react";
import { connectStateResults } from "react-instantsearch-dom";
import { IPageSource } from "../../../interfaces/domain/common/ISearch";
import SearchResultHits from "./SearchResultHits";

interface SearchResultProps {
  searchState: any;
  onClickHit?: () => void;
  hideIcon?: boolean;
  pageSource?: IPageSource;
}

const SearchResult: React.FunctionComponent<SearchResultProps> = (props) => {
  return (
    <div className="card Search__SearchResult">
      <SearchResultHits
        onClickHit={props.onClickHit}
        pageSource={props.pageSource}
        searchState={props.searchState}
        hideIcon={props.hideIcon}
      />
    </div>
  );
};

export default connectStateResults(SearchResult);

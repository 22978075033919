import * as React from "react";
import { Link } from "react-router-dom";
import { GET_PERSON_ROUTE } from "../../../../lib/Router/routes";
import { GetPersonFullName } from "../../../../helpers/personHelpers";
import { IPersonResultHit } from "../../../../interfaces/algolia/ISearchResultHit";
import { FiUser } from "react-icons/fi";
import { GetFormattedDateByIDateObject } from "../../../../helpers/dateHelpers";
import { getFormattedLocation } from "../../../../helpers/locationHelpers";

type PersonResultHitProps = {
  hit: IPersonResultHit;
  onClick?: () => void;
  hideIcon?: boolean;
};

const PersonResultHit: React.FunctionComponent<PersonResultHitProps> = (props) => {
  const person = props.hit;
  const aliasesString = person.aliases
    ? person.aliases.map((alias) => GetPersonFullName(alias.firstName, alias.middleName, alias.lastName)).join(", ")
    : "";
  const nationalitiesString = person.nationalities ? person.nationalities.join(", ") : "";
  const birthDateString = person.birthDate ? `Født: ${GetFormattedDateByIDateObject(person.birthDate)} ` : "";
  const placeOfBirthString = person.placeOfBirth
    ? `${getFormattedLocation(
      person.placeOfBirth.country,
      person.placeOfBirth.region,
      person.placeOfBirth.district,
      " – Fødested: "
    )}`
    : "";
  const placeOfResidenceString = person.placeOfResidence
    ? `${getFormattedLocation(
      person.placeOfResidence.country,
      person.placeOfResidence.region,
      person.placeOfResidence.district,
      ` – Bosted: `
    )}`
    : "";

  return (
    <Link
      to={GET_PERSON_ROUTE(person.id)}
      className="list-group-item list-group-item-action"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}>
      <div className="media d-flex align-items-center">
        {props.hideIcon ? null : <FiUser size={26} className="mr-2 text-muted" />}

        <div className="media-body">
          <div className="d-flex w-100 justify-content-between">
            <span className="m-0 h6">
              {GetPersonFullName(person.firstName, person.middleName, person.lastName)}{" "}
              <small className="text-muted">
                <i>{aliasesString}</i>
              </small>
            </span>

            <small className="text-muted">{nationalitiesString}</small>
          </div>

          <div className="text-muted">
            {birthDateString} {placeOfBirthString} {placeOfResidenceString}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PersonResultHit;

import React from "react";
import FieldValidationError from "../FieldError/FieldError";
import { FieldError, NestDataObject } from "react-hook-form/dist/types";
import CertaintyField from "../CertaintyField/CertaintyField";
import { IDate } from "../../../../interfaces/domain/IDate";

interface DateFieldsProps {
  fieldName: string;
  register: any;
  errors: NestDataObject<IDate, FieldError> | undefined;
}

const DateFields: React.FunctionComponent<DateFieldsProps> = (props) => {
  const fieldNameDay = `${props.fieldName}.day`;
  const fieldNameMonth = `${props.fieldName}.month`;
  const fieldNameYear = `${props.fieldName}.year`;
  const fieldNameCertainty = `${props.fieldName}.certainty`;
  const showLabels = false;

  return (
    <div className="form-row">
      <div className="col form-group">
        {showLabels && <label htmlFor={fieldNameDay}>Dag</label>}

        <input
          type="number"
          name={fieldNameDay}
          placeholder="Dag"
          ref={props.register}
          className={`form-control ${props.errors?.day ? "is-invalid" : "valid"}`}
        />
        <FieldValidationError error={props.errors?.day}></FieldValidationError>
      </div>

      <div className="col form-group">
        {showLabels && <label htmlFor={fieldNameMonth}>Måned</label>}

        <input
          type="number"
          name={fieldNameMonth}
          placeholder="Måned"
          ref={props.register}
          className={`form-control ${props.errors?.month ? "is-invalid" : "valid"}`}
        />

        <FieldValidationError error={props.errors?.month}></FieldValidationError>
      </div>

      <div className="col form-group">
        {showLabels && <label htmlFor={fieldNameYear}>År</label>}

        <input
          type="number"
          name={fieldNameYear}
          placeholder="År"
          ref={props.register}
          className={`form-control ${props.errors?.year ? "is-invalid" : "valid"}`}
        />

        <FieldValidationError error={props.errors?.year}></FieldValidationError>
      </div>

      <div className="col">
        <CertaintyField fieldName={fieldNameCertainty} register={props.register} errors={props.errors}></CertaintyField>
      </div>
    </div>
  );
};

export default DateFields;

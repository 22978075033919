import React from "react";
import { Button } from "react-bootstrap";
import { EXPORT_PERSON_TO_CSV_MUTATION } from "../../../graphql/mutations";
import { IExportToCsvMutationResult } from "../../../interfaces/mutations/IExportToCsvMutation";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { useAuth } from "../../../lib/hooks/useAuth";
import { useQuery, useMutation } from "@apollo/client";
import { IGetCsvFileQueryResult } from "../../../interfaces/queries/IGetCsvFileQuery";
import { GET_CSVFILE_QUERY } from "../../../graphql/queries";
import { FiArrowDownCircle } from "react-icons/fi";

const ExportingToCsv: React.FunctionComponent = () => {
  const { isAdmin } = useAuth();
  const [exportingToCsv, { loading: exportInProgress }] = useMutation<IExportToCsvMutationResult>(EXPORT_PERSON_TO_CSV_MUTATION, {
    refetchQueries: [{ query: GET_CSVFILE_QUERY }],
  });
  const { data: csvFileData, loading: csvFileLoading  } = useQuery<IGetCsvFileQueryResult>(GET_CSVFILE_QUERY);


  const onRunExportToCsv = async () => {
    await exportingToCsv({})
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.exportToCsvMutation)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  };

  return (
    <>
      <div className="card-body">
        <h5 className="card-title">Eksport av personer til CSV-fil</h5>
        <div className="card-text"><p>Her kan du laste ned alle personer i Fangeregisteret i CSV-format. Om du ønsker en fersk eksport, klikk på «Opprett ny CSV-fil».</p>
        <p>NB! Ettersom dette vil generere en fil med alle personene i databasen kan det ta litt tid. Når filen er klar vil nedlastingslenken under vise nytt tidspunkt.</p>
        <Button
          variant="primary"
          size="sm"
          disabled={!isAdmin || exportInProgress}
          onClick={(event: any) => {
            event.preventDefault();
            const confirmed = window.confirm("Er du sikker på at du vil eksportere alle personer til CSV?");
            if (confirmed) {
              onRunExportToCsv();
            }
          }}>
          {exportInProgress ? (
            <>
              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
              <span>Oppretter...</span>
            </>
          ) : (
            <span>Opprett ny CSV-fil</span>
          )}
        </Button>
        </div>
      </div>
      <div className="list-group list-group-flush">
        {csvFileLoading ?
        <div className="list-group-item text-muted">
          <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
        </div> :
        <>
          {csvFileData?.csvFile ?
            <a href={csvFileData.csvFile.fileDownloadPath} download className="list-group-item list-group-item-action">
              <strong><FiArrowDownCircle color="green" /> Last ned CSV-fil </strong><span className="text-muted ml-1">(Opprettet {new Date(csvFileData.csvFile.createdAt).toLocaleDateString('nb-NO',   {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Europe/Oslo'
  })})</span>
            </a>
          :
          <div className="list-group-item list-group-item-warning">Ingen tidligere ekporter funnet, trykk på «Opprett ny CSV-fil».</div>
          }</>
        }
      </div>
    </>

  );
};

export default ExportingToCsv;

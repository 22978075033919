import React from "react";
import { useParams } from "react-router-dom";

import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import EditIndustry from "../components/Industry/EditIndustry/EditIndustry";

const IndustryPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditIndustry id={Number(id)}></EditIndustry>;
};

export default IndustryPage;

import { MenuSelect, Panel } from "react-instantsearch-dom";
import { placeRefinementTransformer } from "../../../../../helpers/searchHelpers";

interface PlaceRefinementProps {
  title: string;
  fieldName: string;
}

const PlaceRefinement: React.FunctionComponent<PlaceRefinementProps> = (props) => {
  return (
    <div className="row">
      <Panel className="col-lg-4">
        <div className="SearchFilter__Refinement">
          <h6>
            {props.title} land{" "}
            <small className="text-muted">
              <i>1947</i>
            </small>
          </h6>

          <MenuSelect
            attribute={`${props.fieldName}.country`}
            limit={500}
            transformItems={placeRefinementTransformer}
            translations={{
              seeAllOption: "Alle land",
            }}
          />
        </div>
      </Panel>

      <Panel className="col-lg-4">
        <div className="SearchFilter__Refinement">
          <h6>
            {props.title} fylke{" "}
            <small className="text-muted">
              <i>1947</i>
            </small>
          </h6>

          <MenuSelect
            attribute={`${props.fieldName}.region`}
            limit={500}
            transformItems={placeRefinementTransformer}
            translations={{
              seeAllOption: "Alle fylker",
            }}
          />
        </div>
      </Panel>

      <Panel className="col-lg-4">
        <div className="SearchFilter__Refinement">
          <h6>
            {props.title} kommune{" "}
            <small className="text-muted">
              <i>1947</i>
            </small>
          </h6>

          <MenuSelect
            attribute={`${props.fieldName}.district`}
            limit={500}
            transformItems={placeRefinementTransformer}
            translations={{
              seeAllOption: "Alle kommuner",
            }}
          />
        </div>
      </Panel>
    </div>
  );
};

export default PlaceRefinement;

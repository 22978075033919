import React from "react";
import { FiArrowRight, FiBook, FiBookOpen, FiHome, FiKey, FiPlusCircle, FiType, FiUsers, FiSettings, FiDownload } from "react-icons/fi";
import { GiWindowBars } from "react-icons/gi";
import { TfiRulerPencil } from "react-icons/tfi";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../lib/Router/routes";
import "./Sidebar.scss";
import { useAuth } from "../../../lib/hooks/useAuth";

const Sidebar: React.FunctionComponent = (props) => {
  const { canVisit, isAdmin } = useAuth();

  return (
    <nav className="Sidebar Sidebar__Sticky">
      <ul className="nav flex-column">
        {canVisit(ROUTES.AUTHORIZED.DASHBOARD_ROUTE) && (
          <li className="nav-item">
            <NavLink to={ROUTES.AUTHORIZED.DASHBOARD_ROUTE} className="nav-link" activeClassName="active" exact>
              <FiHome className="icon--size-l" /> Oversikt
            </NavLink>
          </li>
        )}

        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.AUTHORIZED.PERSONS_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.AUTHORIZED.PERSONS_ROUTE} className="nav-link" activeClassName="active">
                  <FiUsers className="icon--size-l" /> Personer
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.AUTHORIZED.PERSON_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.AUTHORIZED.PERSON_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.AUTHORIZED.PRISON_CAMPS_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.AUTHORIZED.PRISON_CAMPS_ROUTE} className="nav-link" activeClassName="active">
                  <GiWindowBars className="icon--size-l" /> Fangesteder
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.PRISON_CAMP_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.PRISON_CAMP_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.AUTHORIZED.REFERENCES_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.AUTHORIZED.REFERENCES_ROUTE} className="nav-link" activeClassName="active">
                  <FiBook className="icon--size-l" /> Referanser
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.AUTHORIZED.REFERENCE_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.AUTHORIZED.REFERENCE_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.AUTHORIZED.HISTORIES_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.AUTHORIZED.HISTORIES_ROUTE} className="nav-link" activeClassName="active">
                  <FiBookOpen className="icon--size-l" /> Historier
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.HISTORY_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.HISTORY_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.ARTICLES_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.ARTICLES_ROUTE} className="nav-link" activeClassName="active">
                  <FiType className="icon--size-l" /> Artikler
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.ARTICLE_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.ARTICLE_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.AUTHORIZED.TEACHING_MODULE_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.AUTHORIZED.TEACHING_MODULE_ROUTE} className="nav-link" activeClassName="active">
                  <TfiRulerPencil className="icon--size-l" /> Undervisning
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.TEACHING_MODULE_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.TEACHING_MODULE_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>
      </ul>

      <hr></hr>

      <ul className="nav flex-column mb-2">
        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.CITIZENSHIPS_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.CITIZENSHIPS_ROUTE} className="nav-link" activeClassName="active">
                  <FiArrowRight className="icon--size-l" /> Statsborgerskap
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.CITIZENSHIP_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.CITIZENSHIP_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.NATIONALITIES_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.NATIONALITIES_ROUTE} className="nav-link" activeClassName="active">
                  <FiArrowRight className="icon--size-l" /> Landtilhørighet
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.NATIONALITY_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.NATIONALITY_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.INDUSTRIES_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.INDUSTRIES_ROUTE} className="nav-link" activeClassName="active">
                  <FiArrowRight className="icon--size-l" /> Bransjer
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.INDUSTRY_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.INDUSTRY_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.PROFESSIONS_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.PROFESSIONS_ROUTE} className="nav-link" activeClassName="active">
                  <FiArrowRight className="icon--size-l" /> Yrker
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.PROFESSION_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.PROFESSION_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.ROLES_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.ROLES_ROUTE} className="nav-link" activeClassName="active">
                  <FiArrowRight className="icon--size-l" /> Personroller
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.ROLE_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.ROLE_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.EVENT_TYPES_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.EVENT_TYPES_ROUTE} className="nav-link" activeClassName="active">
                  <FiArrowRight className="icon--size-l" /> Hendelsestyper
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.EVENT_TYPE_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.EVENT_TYPE_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.PRISON_CAMP_CATEGORIES_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.PRISON_CAMP_CATEGORIES_ROUTE} className="nav-link" activeClassName="active">
                  <FiArrowRight className="icon--size-l" /> Fangestedstyper
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.PRISON_CAMP_CATEGORY_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.PRISON_CAMP_CATEGORY_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.COUNTRIES_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.COUNTRIES_ROUTE} className="nav-link" activeClassName="active">
                  <FiArrowRight className="icon--size-l" /> Land
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.COUNTRY_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.COUNTRY_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.REGIONS_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.REGIONS_ROUTE} className="nav-link" activeClassName="active">
                  <FiArrowRight className="icon--size-l" /> Fylker
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.REGION_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.REGION_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>

        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.DISTRICTS_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.DISTRICTS_ROUTE} className="nav-link" activeClassName="active">
                  <FiArrowRight className="icon--size-l" /> Kommuner
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.DISTRICT_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.DISTRICT_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>
      </ul>

      {isAdmin() && <hr></hr>}

      <ul className="nav flex-column mb-2">
        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.USERS_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.USERS_ROUTE} className="nav-link" activeClassName="active">
                  <FiKey className="icon--size-l" /> Brukere
                </NavLink>
              </div>
            )}

            {canVisit(ROUTES.ADMIN.USER_CREATE_ROUTE) && (
              <div className="Sidebar__MenuItem__Action bd-highlight">
                <NavLink to={ROUTES.ADMIN.USER_CREATE_ROUTE} className="nav-link" activeClassName="active">
                  <FiPlusCircle className="icon--size-l" />
                </NavLink>
              </div>
            )}
          </div>
        </li>
        <li className="Sidebar__MenuItem nav-item">
          <div className="d-flex bd-highlight">
            {canVisit(ROUTES.ADMIN.EXPORTTOCSV_ROUTE) && (
              <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                <NavLink to={ROUTES.ADMIN.EXPORTTOCSV_ROUTE} className="nav-link" activeClassName="active">
                  <FiDownload className="icon--size-l" /> Eksporter til CSV
                </NavLink>
              </div>
            )}
          </div>
        </li>
        {isAdmin() && (
          <li className="Sidebar__MenuItem nav-item">
            <div className="d-flex bd-highlight">
              {canVisit(ROUTES.ADMIN.SETTINGS_ROUTE) && (
                <div className="Sidebar__MenuItem_Link flex-grow-1 bd-highlight">
                  <NavLink to={ROUTES.ADMIN.SETTINGS_ROUTE} className="nav-link" activeClassName="active">
                    <FiSettings className="icon--size-l" /> Algolia
                  </NavLink>
                </div>
              )}
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Sidebar;

import React from "react";
import { IEntityReference } from "../../../interfaces/domain/IEntityReference";
import EntityReferences from "../../Reference/EntityReferences/EntityReferences";
import { IQuestion } from "../../../interfaces/domain/IQuestion";
import { IEntityReferenceTypeEnum } from "../../../interfaces/forms/IEntityReferenceForm";

interface QuestionReferencesProps {
  questionReferences: Array<IEntityReference<IQuestion>>;
  questionId: number;
}

const QuestionReferences: React.FunctionComponent<QuestionReferencesProps> = props => {
  return (
    <EntityReferences
      entityReferences={props.questionReferences}
      entityType={IEntityReferenceTypeEnum.QUESTION}
      entityId={props.questionId}></EntityReferences>
  );
};

export default QuestionReferences;

import * as yup from "yup";

export const regionSchema = yup.object({
  name: yup.string().required("Må fylles ut"),
  code: yup.string().required("Må fylles ut"),
  yearOfOrigin: yup.object({
    value: yup.number().required("Må fylles ut"),
  }),
  country: yup.object({
    value: yup.number().required("Må fylles ut"),
  }),
});

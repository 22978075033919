import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";

import { GET_INDUSTRY_ROUTE } from "../../../lib/Router/routes";
import { GET_INDUSTRIES_QUERY } from "../../../graphql/queries";
import { IGetIndustriesQueryResult } from "../../../interfaces/queries/IGetIndustriesQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const IndustriesList: React.FunctionComponent = () => {
  const { loading, error, data } = useQuery<IGetIndustriesQueryResult>(GET_INDUSTRIES_QUERY);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.industries || !data.industries.length) return <DataNotFound />;

  return (
    <div className="IndustriesList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Navn</th>
          </tr>
        </thead>

        <tbody>
          {data.industries.map((industry) => (
            <tr key={industry.id}>
              <td>
                <Link to={GET_INDUSTRY_ROUTE(industry.id)}>{industry.name}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IndustriesList;

import { useParams } from "react-router-dom";
import TeachingCategories from "../components/TeachingCategory/TeachingCategories/TeachingCategories"
import PageNotFound from "../components/_common/PageNotFound/PageNotFound";

const TeachingCategoriesPage: React.FunctionComponent = () => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }


  return (<TeachingCategories teachingModuleId={Number(id)} />
  )
}

export default TeachingCategoriesPage;

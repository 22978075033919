import React from "react";
import { useParams } from "react-router-dom";

import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import EditNationality from "../components/Nationality/EditNationality/EditNationality";

const NationalityPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditNationality id={Number(id)}></EditNationality>;
};

export default NationalityPage;

import { useQuery } from "@apollo/client";
import React from "react";

import { GET_PRISON_CAMP_CATEGORY_QUERY } from "../../../graphql/queries";
import {
  IGetPrisonCampCategoryQueryFilter,
  IGetPrisonCampCategoryQueryResult,
} from "../../../interfaces/queries/IGetPrisonCampCategoryQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import EditPrisonCampCategoryForm from "./EditPrisonCampCategoryForm";

interface IEditPrisonCampCategoryProps {
  id: number;
}

const EditPrisonCampCategory: React.FunctionComponent<IEditPrisonCampCategoryProps> = (props) => {
  const { loading, error, data } = useQuery<IGetPrisonCampCategoryQueryResult, IGetPrisonCampCategoryQueryFilter>(
    GET_PRISON_CAMP_CATEGORY_QUERY,
    {
      variables: { filter: { id: props.id } },
    }
  );

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.prisonCampCategory) return <DataNotFound />;

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">{data.prisonCampCategory.name}</h1>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="card shadow-sm">
            <div className="card-body">
              <EditPrisonCampCategoryForm prisonCampCategory={data.prisonCampCategory}></EditPrisonCampCategoryForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPrisonCampCategory;

import { useQuery } from "@apollo/client";
import React from "react";
import { GET_AUDITLOG_QUERY } from "../../../../graphql/queries";
import { IGetAuditLogQueryResult, IGetAuditLogQueryFilter } from "../../../../interfaces/queries/IGetAuditLogQuery";
import DataNotFound from "../../../_common/DataNotFound/DataNotFound";
import Loading from "../../../_common/Loading/Loading";
import QueryError from "../../../_common/QueryError/QueryError";
import { GetFormattedDateTimeLong } from "../../../../helpers/dateHelpers";

interface IAuditLogProps {
  entityId: number;
  entityName: string;
}

const AuditLogEntityList: React.FunctionComponent<IAuditLogProps> = (props) => {
  const { loading, error, data } = useQuery<IGetAuditLogQueryResult, IGetAuditLogQueryFilter>(GET_AUDITLOG_QUERY, {
    variables: { filter: { entityId: props.entityId, entityName: props.entityName } },
  });

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.auditlogs) return <DataNotFound message="Ingen endringer er registrert" />;

  const auditlogs = !data || !data.auditlogs || !data.auditlogs.length ? [] : data.auditlogs;

  return (
    <table className="table table-hover table-sm">
      <thead>
        <tr>
          <th scope="col">Sist oppdatert</th>
          <th scope="col">Oppdatert av</th>
        </tr>
      </thead>

      <tbody>
        {auditlogs.map((auditlog) => (
          <tr key={auditlog.id}>
            <td className="text-capitalize">{auditlog.updatedAt ? GetFormattedDateTimeLong(auditlog.updatedAt) : ""}</td>
            <td>{auditlog.updatedBy}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AuditLogEntityList;

import { loader } from "graphql.macro";

export const GET_USERS_QUERY = loader("./GET_USERS_QUERY.gql");
export const GET_USER_ROLES_QUERY = loader("./GET_USER_ROLES_QUERY.gql");

export const GET_PERSONS_QUERY = loader("./GET_PERSONS_QUERY.gql");
export const GET_PERSON_QUERY = loader("./GET_PERSON_QUERY.gql");

export const GET_PROFESSIONS_QUERY = loader("./GET_PROFESSIONS_QUERY.gql");
export const GET_PROFESSION_QUERY = loader("./GET_PROFESSION_QUERY.gql");

export const GET_INDUSTRIES_QUERY = loader("./GET_INDUSTRIES_QUERY.gql");
export const GET_INDUSTRY_QUERY = loader("./GET_INDUSTRY_QUERY.gql");

export const GET_CITIZENSHIPS_QUERY = loader("./GET_CITIZENSHIPS_QUERY.gql");
export const GET_CITIZENSHIP_QUERY = loader("./GET_CITIZENSHIP_QUERY.gql");

export const GET_EVENT_TYPES_QUERY = loader("./GET_EVENT_TYPES_QUERY.gql");
export const GET_EVENT_TYPE_QUERY = loader("./GET_EVENT_TYPE_QUERY.gql");

export const GET_NATIONALITIES_QUERY = loader("./GET_NATIONALITIES_QUERY.gql");
export const GET_NATIONALITY_QUERY = loader("./GET_NATIONALITY_QUERY.gql");

export const GET_ROLES_QUERY = loader("./GET_ROLES_QUERY.gql");
export const GET_ROLE_QUERY = loader("./GET_ROLE_QUERY.gql");

export const GET_PRISON_CAMP_CATEGORIES_QUERY = loader("./GET_PRISON_CAMP_CATEGORIES_QUERY.gql");
export const GET_PRISON_CAMP_CATEGORY_QUERY = loader("./GET_PRISON_CAMP_CATEGORY_QUERY.gql");
export const GET_COUNTRY_QUERY = loader("./GET_COUNTRY_QUERY.gql");
export const GET_COUNTRIES_QUERY = loader("./GET_COUNTRIES_QUERY.gql");

export const GET_REGION_QUERY = loader("./GET_REGION_QUERY.gql");
export const GET_REGIONS_QUERY = loader("./GET_REGIONS_QUERY.gql");

export const GET_DISTRICT_QUERY = loader("./GET_DISTRICT_QUERY.gql");
export const GET_DISTRICTS_QUERY = loader("./GET_DISTRICTS_QUERY.gql");

export const GET_HISTORY_QUERY = loader("./GET_HISTORY_QUERY.gql");
export const GET_HISTORIES_QUERY = loader("./GET_HISTORIES_QUERY.gql");

export const GET_PRISON_CAMP_QUERY = loader("./GET_PRISON_CAMP_QUERY.gql");
export const GET_PRISON_CAMPS_QUERY = loader("./GET_PRISON_CAMPS_QUERY.gql");

export const GET_ARTICLE_QUERY = loader("./GET_ARTICLE_QUERY.gql");
export const GET_ARTICLES_QUERY = loader("./GET_ARTICLES_QUERY.gql");

export const GET_REFERENCE_QUERY = loader("./GET_REFERENCE_QUERY.gql");
export const GET_REFERENCES_QUERY = loader("./GET_REFERENCES_QUERY.gql");

export const GET_TEACHING_MODULE_QUERY = loader("./GET_TEACHING_MODULE_QUERY.gql");
export const GET_TEACHING_CATEGORY_QUERY = loader("./GET_TEACHING_CATEGORY_QUERY.gql");
export const GET_TEACHING_CATEGORIES_QUERY = loader("./GET_TEACHING_CATEGORIES_QUERY.gql");
export const GET_TEACHING_SUBJECT_QUERY = loader("./GET_TEACHING_SUBJECT_QUERY.gql");
export const GET_TEACHING_SUBJECTS_QUERY = loader("./GET_TEACHING_SUBJECTS_QUERY.gql");

export const GET_ASSIGNMENT_QUERY = loader("./GET_ASSIGNMENT_QUERY.gql");
export const GET_ASSIGNMENTS_QUERY = loader("./GET_ASSIGNMENTS_QUERY.gql");

export const GET_QUESTION_QUERY = loader("./GET_QUESTION_QUERY.gql");
export const GET_QUESTIONS_QUERY = loader("./GET_QUESTIONS_QUERY.gql");

export const GET_AUDITLOG_QUERY = loader("./GET_AUDITLOG_QUERY.gql");

export const GET_ALGOLIA_AUTOMATIC_SYNC_SETTING_QUERY = loader("./GET_ALGOLIA_AUTOMATIC_SYNC_SETTING_QUERY.gql");
export const GET_CSVFILE_QUERY = loader("./GET_CSVFILE_QUERY.gql");

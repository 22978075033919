import { useQuery } from "@apollo/client";
import React from "react";
import { FiBookOpen, FiPlusCircle } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";
import { GET_HISTORIES_QUERY } from "../../../graphql/queries";
import { GetFormattedDateTimeShort } from "../../../helpers/dateHelpers";
import { IGetHistoriesQueryFilter, IGetHistoriesQueryResult } from "../../../interfaces/queries/IGetHistoriesQuery";
import { GET_HISTORY_ROUTE, ROUTES } from "../../../lib/Router/routes";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { useAuth } from "../../../lib/hooks/useAuth";

const LastUpdatedHistoriesList: React.FunctionComponent = (props) => {
  const { canVisit } = useAuth();
  const { loading, error, data } = useQuery<IGetHistoriesQueryResult, IGetHistoriesQueryFilter>(GET_HISTORIES_QUERY, {
    variables: { filter: { amountToReturn: 5, sortByLastUpdated: "DESC" } },
  });

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data) return <DataNotFound message="Ingen historier funnet" />;

  return (
    <div className="card shadow-sm">
      <div className="card-header ">
        <div className="float-right">
          {canVisit(ROUTES.ADMIN.HISTORY_CREATE_ROUTE) && (
            <NavLink to={ROUTES.ADMIN.HISTORY_CREATE_ROUTE} className="btn btn-sm btn-outline-dark mr-1" role="button" exact>
              <FiPlusCircle /> Legg til ny historie
            </NavLink>
          )}

          <NavLink to={ROUTES.AUTHORIZED.HISTORIES_ROUTE} className="btn btn-sm btn-outline-dark" role="button" exact>
            Vis alle
          </NavLink>
        </div>
        <h4 className="dashboard-card-header m-0">
          <FiBookOpen className="text-muted" /> Sist oppdaterte historier
        </h4>

        <small className="text-muted">Totalt antall: {data.histories.totalHistoriesCount}</small>
      </div>

      <div className="list-group list-group-flush">
        {data.histories.histories.map((history) => (
          <Link className="list-group-item list-group-item-action" to={GET_HISTORY_ROUTE(history.id)} key={history.id}>
            <div className="d-flex w-100 justify-content-between">
              {history.title}
              <small className="text-muted">{GetFormattedDateTimeShort(history.audit.updatedAt)}</small>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default LastUpdatedHistoriesList;

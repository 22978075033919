import React from "react";
import { FiPlusCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { GET_TEACHING_CATEGORY_CREATE_ROUTE, ROUTES } from "../../../lib/Router/routes";
import { ITeachingCategory } from "../../../interfaces/domain/ITeachingCategory";
import FormSubTitle from "../../_common/Form/FormSubTitle/FormSubTitle";
import TeachingCategoriesColumnList from "./TeachingCategoriesColumnList";
import { useAuth } from "../../../lib/hooks/useAuth";

interface TeachingCategoriesColumnProps {
  teachingCategories: Array<ITeachingCategory>;
  teachingModuleId: number;
}

const TeachingCategoriesColumn: React.FunctionComponent<TeachingCategoriesColumnProps> = (props) => {
  const { canVisit } = useAuth();

  return (
    <div>
      <div className="card shadow-sm">
        <div className="card-body border-bottom">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <FormSubTitle>Kategorier</FormSubTitle>
            {canVisit(ROUTES.ADMIN.TEACHING_CATEGORY_CREATE_ROUTE) &&
              <div className="btn-toolbar mb-2 mb-md-0">
                <NavLink to={GET_TEACHING_CATEGORY_CREATE_ROUTE(props.teachingModuleId)} className="btn btn-sm btn-outline-dark" role="button" exact>
                  <FiPlusCircle /> Legg til
                </NavLink>
              </div>}
          </div>
        </div>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <TeachingCategoriesColumnList teachingCategories={props.teachingCategories} teachingModuleId={props.teachingModuleId}></TeachingCategoriesColumnList>
        </div>
      </div>
    </div>
  );
}

export default TeachingCategoriesColumn;

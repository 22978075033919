import { ITeachingModule } from "../../../interfaces/domain/ITeachingModule";
import TeachingCategoriesColumn from "../../TeachingCategory/TeachingCategories/TeachingCategoriesColumn";
import EditTeachingModuleFrom from "./EditTeachingModuleForm";


interface IEditTeachingModuleProps {
  teachingModule: ITeachingModule;
}

const EditTeachingModuleFormContainer: React.FunctionComponent<IEditTeachingModuleProps> = (props) => {
  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-6 mb-3">
          <EditTeachingModuleFrom teachingModule={props.teachingModule}></EditTeachingModuleFrom>
        </div>

        <div className="col-lg-6">
          <TeachingCategoriesColumn teachingModuleId={props.teachingModule.id} teachingCategories={props.teachingModule.teachingCategories}></TeachingCategoriesColumn>
        </div>
      </div>
    </div>
  )
}

export default EditTeachingModuleFormContainer;

import { IReferenceForm } from "./IReferenceForm";
import { ISelectOption } from "../domain/common/ISelectOption";

export enum IEntityReferenceTypeEnum {
  PERSON = "PERSON",
  PRISON_CAMP = "PRISON_CAMP",
  HISTORY = "HISTORY",
  ASSIGNMENT = "ASSIGNMENT",
  QUESTION = "QUESTION"
}

export interface IEntityReferenceForm {
  contextualComment: string;
  contextualUrl: string;
}

export interface IAttachEntityReferenceForm extends IEntityReferenceForm {
  reference: ISelectOption;
}

export interface ICreateEntityReferenceForm extends IEntityReferenceForm, IReferenceForm {}

export interface IUpdateEntityReferenceForm extends IEntityReferenceForm {
  entityReferenceId: number;
}

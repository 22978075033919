import * as React from "react";
import { GiWindowBars } from "react-icons/gi";
import { Link } from "react-router-dom";
import { IPrisonCampResultHit } from "../../../../interfaces/algolia/ISearchResultHit";
import { GET_PRISON_CAMP_ROUTE } from "../../../../lib/Router/routes";

type PrisonCampResultHitProps = {
  hit: IPrisonCampResultHit;
  onClick?: () => void;
  hideIcon?: boolean;
};

const PrisonCampResultHit: React.FunctionComponent<PrisonCampResultHitProps> = props => {
  const prisonCamp = props.hit;
  const aliasesString = prisonCamp.aliases ? prisonCamp.aliases.join(", ") : "";
  const categoriesString = prisonCamp.categories ? prisonCamp.categories.join(", ") : "";

  return (
    <Link
      to={GET_PRISON_CAMP_ROUTE(prisonCamp.id)}
      className="list-group-item list-group-item-action"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}>
      <div className="media d-flex align-items-center">
        {props.hideIcon ? null : <GiWindowBars size={26} className="text-muted mr-2" />}

        <div className="media-body">
          <div className="d-flex w-100 justify-content-between">
            <span className="h6 m-0">
              {prisonCamp.name}{" "}
              <small className="text-muted">
                <i>{aliasesString}</i>
              </small>
            </span>

            <small className="text-muted">{categoriesString}</small>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PrisonCampResultHit;

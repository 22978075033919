import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { UPDATE_ENTITY_REFERENCE_MUTATION } from "../../../../graphql/mutations";
import { updateEntityReferenceSchema } from "../../../../helpers/validators/updateEntityReferenceSchema";
import { IUpdateEntityReferenceForm, IEntityReferenceTypeEnum } from "../../../../interfaces/forms/IEntityReferenceForm";
import {
  IUpdateEntityReferenceMutationInput,
  IUpdateEntityReferenceMutationResult,
} from "../../../../interfaces/mutations/IUpdateEntityReferenceMutation";
import FieldError from "../../../_common/Form/FieldError/FieldError";
import { IEntityReference } from "../../../../interfaces/domain/IEntityReference";
import { getEntityQueryByEntityReferenceType } from "../../../../helpers/entityReferenceHelpers";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../../helpers/formHelpers";

interface UpdateEntityReferenceFormProps {
  entityType: IEntityReferenceTypeEnum;
  entityId: number;
  entityReference: IEntityReference;
  handleClose: () => void;
}

const EditEntityReferenceForm: React.FunctionComponent<UpdateEntityReferenceFormProps> = (props) => {
  const { register, handleSubmit, errors, formState } = useForm<IUpdateEntityReferenceForm>({
    validationSchema: updateEntityReferenceSchema,
    mode: "onChange",
    defaultValues: {
      contextualComment: props.entityReference.contextualComment,
      contextualUrl: props.entityReference.contextualUrl,
    },
  });

  const [updateEntityReference] = useMutation<IUpdateEntityReferenceMutationResult, IUpdateEntityReferenceMutationInput>(
    UPDATE_ENTITY_REFERENCE_MUTATION
  );

  const onSubmit = handleSubmit(async (formValues) => {
    await updateEntityReference({
      variables: {
        input: {
          entityType: props.entityType,
          entityReferenceId: props.entityReference.id,
          contextualComment: formValues.contextualComment,
          contextualUrl: formValues.contextualUrl,
        },
      },
      refetchQueries: [{ query: getEntityQueryByEntityReferenceType(props.entityType), variables: { filter: { id: props.entityId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateEntityReference)) {
          handleMutationSuccess(result.data);
          props.handleClose();
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label htmlFor="name">Individuell URL</label>
        <input
          type="text"
          name="contextualUrl"
          ref={register}
          className={`form-control ${errors.contextualUrl ? "is-invalid" : "valid"}`}
        />

        <FieldError error={errors.contextualUrl}></FieldError>
      </div>

      <div className="form-group">
        <label htmlFor="name">Individuell kommentar</label>
        <textarea
          name="contextualComment"
          ref={register}
          rows={4}
          className={`form-control ${errors.contextualComment ? "is-invalid" : "valid"}`}
        />

        <FieldError error={errors.contextualComment}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditEntityReferenceForm;

import React from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { ROUTES } from "./routes";
import Layout from "../../components/_common/Layouts/Layout/Layout";
import NoAccess from "../../components/_common/NoAccess/NoAccess";

export const AuthorizedRoute = ({ children, ...rest }: RouteProps): JSX.Element => {
  const { isAuthorized, canVisit } = useAuth();
  const message = "Unauthorized";

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        const attemptedPath = rest.path as string;

        if (isAuthorized() && !canVisit(attemptedPath)) {
          return (
            <Layout>
              <NoAccess></NoAccess>
            </Layout>
          );
        }

        return isAuthorized() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.PUBLIC.SIGN_IN_ROUTE,
              state: { message, requestedPath: rest.path, from: props.location }
            }}
          />
        );
      }}></Route>
  );
};

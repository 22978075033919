import { generatePath } from "react-router-dom";

export const ROUTES = {
  PUBLIC: {
    SIGN_IN_ROUTE: "/signin",
    GENERATE_RESET_PASSWORD_ROUTE: "/getReset",
    RESET_PASSWORD_ROUTE: "/reset",
  },
  AUTHORIZED: {
    DASHBOARD_ROUTE: "/",
    CHANGE_PASSWORD_ROUTE: "/change",
    PERSON_CREATE_ROUTE: "/persons/create",
    PERSONS_ROUTE: "/persons",
    PERSON_ROUTE: "/persons/:id",
    PERSON_CAPTIVITIES_ROUTE: "/persons/:id/captivities",
    PERSON_EVENTS_ROUTE: "/persons/:id/events",
    PERSON_REFERENCES_ROUTE: "/persons/:id/references",
    HISTORIES_ROUTE: "/histories",
    HISTORY_ROUTE: "/histories/:id",
    HISTORY_REFERENCES_ROUTE: "/histories/:id/references",
    HISTORY_PRISONCAMPS_ROUTE: "/histories/:id/prisoncamps",
    HISTORY_PERSONS_ROUTE: "/histories/:id/persons",
    PRISON_CAMPS_ROUTE: "/prisoncamps",
    PRISON_CAMP_ROUTE: "/prisoncamps/:id",
    PRISON_CAMP_REFERENCES_ROUTE: "/prisoncamps/:id/references",
    PRISON_CAMP_PERSONS_ROUTE: "/prisoncamps/:id/persons",
    REFERENCES_ROUTE: "/references",
    REFERENCE_CREATE_ROUTE: "/references/new",
    REFERENCE_ROUTE: "/references/:id",
    REFERENCE_PERSONS_ROUTE: "/references/:id/persons",
    REFERENCE_PRISONCAMPS_ROUTE: "/references/:id/prisoncamps",
    REFERENCE_HISTORIES_ROUTE: "/references/:id/histories",
    REFERENCE_UNDERVISNING_ROUTE: "/references/:id/undervisning",
    TEACHING_MODULE_ROUTE: "/teachingmodule",
    TEACHING_CATEGORY_ROUTE: "/teachingcategories/:id",
    TEACHING_CATEGORIES_ROUTE: "/teachingcategories",
    TEACHING_SUBJECTS_ROUTE: "/teachingcategories/:id/teachingsubjects",
    TEACHING_SUBJECT_ROUTE: "/teachingsubjects/:id",
    ASSIGNMENT_ROUTE: "/assignments/:id",
    ASSIGNMENT_REFERENCES_ROUTE: "/assignments/:id/references",
    QUESTION_ROUTE: "/questions/:id",
    QUESTION_REFERENCES_ROUTE: "/questions/:id/references",
  },
  ADMIN: {
    USERS_ROUTE: "/users",
    USER_CREATE_ROUTE: "/users/create",
    HISTORY_CREATE_ROUTE: "/histories/new",
    ARTICLES_ROUTE: "/articles",
    ARTICLE_ROUTE: "/articles/:id",
    ARTICLE_CREATE_ROUTE: "/articles/new",
    COUNTRIES_ROUTE: "/countries",
    COUNTRY_CREATE_ROUTE: "/countries/new",
    COUNTRY_ROUTE: "/countries/:id",
    REGIONS_ROUTE: "/regions",
    REGION_CREATE_ROUTE: "/regions/new",
    REGION_ROUTE: "/regions/:id",
    DISTRICTS_ROUTE: "/districts",
    DISTRICT_CREATE_ROUTE: "/districts/new",
    DISTRICT_ROUTE: "/districts/:id",
    PROFESSIONS_ROUTE: "/professions",
    PROFESSION_CREATE_ROUTE: "/professions/new",
    PROFESSION_ROUTE: "/professions/:id",
    INDUSTRIES_ROUTE: "/industries",
    INDUSTRY_CREATE_ROUTE: "/industries/new",
    INDUSTRY_ROUTE: "/industries/:id",
    NATIONALITIES_ROUTE: "/nationalities",
    NATIONALITY_CREATE_ROUTE: "/nationalities/new",
    NATIONALITY_ROUTE: "/nationalities/:id",
    ROLES_ROUTE: "/roles",
    ROLE_CREATE_ROUTE: "/roles/new",
    ROLE_ROUTE: "/roles/:id",
    CITIZENSHIPS_ROUTE: "/citizenships",
    CITIZENSHIP_CREATE_ROUTE: "/citizenships/new",
    CITIZENSHIP_ROUTE: "/citizenships/:id",
    EVENT_TYPES_ROUTE: "/eventtypes",
    EVENT_TYPE_CREATE_ROUTE: "/eventtypes/new",
    EVENT_TYPE_ROUTE: "/eventtypes/:id",
    PRISON_CAMP_CATEGORIES_ROUTE: "/prisoncampcategories",
    PRISON_CAMP_CATEGORY_CREATE_ROUTE: "/prisoncampcategories/new",
    PRISON_CAMP_CATEGORY_ROUTE: "/prisoncampcategories/:id",
    PRISON_CAMP_CREATE_ROUTE: "/prisoncamps/new",
    SETTINGS_ROUTE: "/settings",
    EXPORTTOCSV_ROUTE: "/exportcsv",
    TEACHING_MODULE_CREATE_ROUTE: "/teachingmodule/new",
    TEACHING_CATEGORY_CREATE_ROUTE: "/teachingmodule/:id/teachingcategory/new",
    TEACHING_SUBJECT_CREATE_ROUTE: "/teachingcategory/:id/teachingsubject/new",
    ASSIGNMENT_CREATE_ROUTE: "/teachingsubject/:id/assignment/new",
    QUESTION_CREATE_ROUTE: "/question/:id/question/new",
  },
};

export const GET_PERSON_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.PERSON_ROUTE, {
    id: id,
  });
};
export const GET_PERSON_CAPTIVITIES_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.PERSON_CAPTIVITIES_ROUTE, {
    id: id,
  });
};
export const GET_PERSON_EVENTS_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.PERSON_EVENTS_ROUTE, {
    id: id,
  });
};
export const GET_PERSON_REFERENCES_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.PERSON_REFERENCES_ROUTE, {
    id: id,
  });
};

export const GET_HISTORY_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.HISTORY_ROUTE, {
    id: id,
  });
};

export const GET_HISTORY_REFERENCES_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.HISTORY_REFERENCES_ROUTE, {
    id: id,
  });
};

export const GET_HISTORY_PRISONCAMPS_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.HISTORY_PRISONCAMPS_ROUTE, {
    id: id,
  });
};

export const GET_HISTORY_PERSONS_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.HISTORY_PERSONS_ROUTE, {
    id: id,
  });
};

export const GET_ARTICLE_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.ARTICLE_ROUTE, {
    id: id,
  });
};

export const GET_COUNTRY_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.COUNTRY_ROUTE, {
    id: id,
  });
};

export const GET_REGION_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.REGION_ROUTE, {
    id: id,
  });
};

export const GET_DISTRICT_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.DISTRICT_ROUTE, {
    id: id,
  });
};

export const GET_PROFESSION_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.PROFESSION_ROUTE, {
    id: id,
  });
};

export const GET_INDUSTRY_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.INDUSTRY_ROUTE, {
    id: id,
  });
};

export const GET_NATIONALITY_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.NATIONALITY_ROUTE, {
    id: id,
  });
};

export const GET_ROLE_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.ROLE_ROUTE, {
    id: id,
  });
};

export const GET_CITIZENSHIP_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.CITIZENSHIP_ROUTE, {
    id: id,
  });
};

export const GET_EVENT_TYPE_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.EVENT_TYPE_ROUTE, {
    id: id,
  });
};

export const GET_PRISON_CAMP_CATEGORY_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.PRISON_CAMP_CATEGORY_ROUTE, {
    id: id,
  });
};

export const GET_PRISON_CAMP_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.PRISON_CAMP_ROUTE, {
    id: id,
  });
};

export const GET_PRISON_CAMP_PERSONS_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.PRISON_CAMP_PERSONS_ROUTE, {
    id: id,
  });
};

export const GET_PRISON_CAMP_REFERENCES_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.PRISON_CAMP_REFERENCES_ROUTE, {
    id: id,
  });
};

export const GET_TEACHING_CATEGORY_CREATE_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.TEACHING_CATEGORY_CREATE_ROUTE, {
    id: id,
  });
};

export const GET_TEACHING_CATEGORY_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.TEACHING_CATEGORY_ROUTE, {
    id: id,
  });
};

export const GET_TEACHING_SUBJECT_CREATE_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.TEACHING_SUBJECT_CREATE_ROUTE, {
    id: id,
  });
};

export const GET_TEACHING_SUBJECT_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.TEACHING_SUBJECT_ROUTE, {
    id: id,
  });
};

export const GET_ASSIGNMENT_CREATE_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.ASSIGNMENT_CREATE_ROUTE, {
    id: id,
  });
};

export const GET_ASSIGNMENT_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.ASSIGNMENT_ROUTE, {
    id: id,
  });
};

export const GET_ASSIGNMENT_REFERENCES_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.ASSIGNMENT_REFERENCES_ROUTE, {
    id: id,
  });
};

export const GET_QUESTION_CREATE_ROUTE = (id: number) => {
  return generatePath(ROUTES.ADMIN.QUESTION_CREATE_ROUTE, {
    id: id,
  });
};

export const GET_QUESTION_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.QUESTION_ROUTE, {
    id: id,
  });
};

export const GET_QUESTION_REFERENCES_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.QUESTION_REFERENCES_ROUTE, {
    id: id,
  });
};

export const GET_REFERENCE_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.REFERENCE_ROUTE, {
    id: id,
  });
};
export const GET_REFERENCE_PERSONS_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.REFERENCE_PERSONS_ROUTE, {
    id: id,
  });
};

export const GET_REFERENCE_PRISONCAMPS_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.REFERENCE_PRISONCAMPS_ROUTE, {
    id: id,
  });
};

export const GET_REFERENCE_HISTORIES_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.REFERENCE_HISTORIES_ROUTE, {
    id: id,
  });
};

export const GET_REFERENCE_UNDERVISNING_ROUTE = (id: number) => {
  return generatePath(ROUTES.AUTHORIZED.REFERENCE_UNDERVISNING_ROUTE, {
    id: id,
  });
};

export const GetAdminRoutes = () => {
  return Object.values(ROUTES.ADMIN);
};

export const GetAuthorizedRoutes = () => {
  return Object.values(ROUTES.AUTHORIZED);
};

export const GetPublicRoutes = () => {
  return Object.values(ROUTES.PUBLIC);
};

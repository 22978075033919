import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { CREATE_TEACHING_CATEGORY_MUTATION } from "../../../graphql/mutations";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { teachingCategorySchema } from "../../../helpers/validators/teachingCategorySchema";
import { ITeachingCategoryForm } from "../../../interfaces/forms/ITeachingCategoryForm";
import {
  ICreateTeachingCategoryMutationInput,
  ICreateTeachingCategoryMutationResult,
} from "../../../interfaces/mutations/ICreateTeachingCategoryMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { ROUTES } from "../../../lib/Router/routes";
import { useHistory } from "react-router-dom";

interface ICreateTeachingCategoryProps {
  teachingModuleId: number;
}

const CreateTeachingCategoryForm: React.FunctionComponent<ICreateTeachingCategoryProps> = (props) => {
  const history = useHistory();
  const { register, handleSubmit, errors, formState } = useForm<ITeachingCategoryForm>({
    validationSchema: teachingCategorySchema,
    mode: "onChange",
  });

  const [createTeachingCategoryMutation] = useMutation<ICreateTeachingCategoryMutationResult, ICreateTeachingCategoryMutationInput>(
    CREATE_TEACHING_CATEGORY_MUTATION
  );

  const onSubmit = handleSubmit(async (formValues) => {
    await createTeachingCategoryMutation({
      variables: {
        input: {
          title: formValues.title,
          displayOrder: formValues.displayOrder,
          teachingModuleId: props.teachingModuleId,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createTeachingCategory)) {
          handleMutationSuccess(result.data, "Kategori opprettet.");

          history.push(ROUTES.AUTHORIZED.TEACHING_MODULE_ROUTE);
        } else {
          handleMutationError(undefined, "Kunne ikke opprette kategori");
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="form-group">
            <label className="required-field" htmlFor="title">
              Tittel (Hvilket nivå oppgavene passer for)
            </label>
            <input
              type="text"
              name="title"
              ref={register}
              className={`form-control ${errors.title ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />
            <FieldError error={errors.title}></FieldError>
          </div>

          <div className="form-group">
            <label className="required-field" htmlFor="displayOrder">
              Rekkefølge
            </label>
            <input
              type="number"
              name="displayOrder"
              ref={register}
              className={`form-control ${errors.displayOrder ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />
            <FieldError error={errors.displayOrder}></FieldError>
          </div>
          <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Oppretter...</span>
              </>
            ) : (
              <span>Opprett og tilknytt</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateTeachingCategoryForm;

import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/nb";
import { IDate } from "../interfaces/domain/IDate";

dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);

const months = ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"];

const GetMonthByIDateObject = (dateObject: IDate) => {
  if (dateObject.month) {
    if (dateObject.month > 0 && dateObject.month < 13) {
      return months[dateObject.month - 1].toLocaleLowerCase();
    }
    return null;
  }
  return null;
};

export const GetFormattedDateTimeShort = (dateTimeUtc: Date) => {
  const formattedDateTime = dayjs(dateTimeUtc, { locale: "nb" }).format("H:mm - DD.MM.YYYY");

  return formattedDateTime;
};

export const GetFormattedDateTimeLong = (dateTimeUtc: Date) => {
  const formattedDateTime = dayjs(dateTimeUtc, { locale: "nb" }).format("LLLL");
  return formattedDateTime;
};

export const GetFormattedRelativeDateTime = (dateTimeUtc: Date) => {
  const relativeToNow = dayjs(dateTimeUtc, { locale: "nb" }).fromNow();
  return relativeToNow;
};

export const GetFormattedDateByIDateObject = (dateObject: IDate) => {
  if (!dateObject.day && !dateObject.month && !dateObject.year) {
    return "Ingen dato";
  }

  const dateString = `${dateObject.day ? dateObject.day + ". " : ""}${
    GetMonthByIDateObject(dateObject) ? GetMonthByIDateObject(dateObject) + " " : ""
  }${dateObject.year ? dateObject.year : ""}`;
  return dateString;
};

import React from "react";
import { IPrisonCamp } from "../../../interfaces/domain/IPrisonCamp";
import EntityPrisonCampsList from "../../PrisonCamp/PrisonCamps/EntityPrisonCampsList/EntityPrisonCampsList";

interface HistoryPrisonCampsProps {
  historyPrisonCamps: Array<IPrisonCamp>;
  historyId: number;
}

const HistoryPrisonCamps: React.FunctionComponent<HistoryPrisonCampsProps> = (props) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card shadow-sm">
          <div className="card-body">
            <EntityPrisonCampsList entityPrisonCamps={props.historyPrisonCamps} entityId={props.historyId}></EntityPrisonCampsList>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryPrisonCamps;

import { useMutation } from "@apollo/client";
import React from "react";
import { Button } from "react-bootstrap";
import { RUN_COMPLETE_ALGOLIA_RESYNC } from "../../../graphql/mutations";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { IRunCompleteAlgoliaResyncMutationResult } from "../../../interfaces/mutations/IRunCompleteAlgoliaResync";
import { useAuth } from "../../../lib/hooks/useAuth";

const RunCompleteAlgoliaResync: React.FunctionComponent = () => {
  const { isAdmin } = useAuth();
  const [runCompleteAlgoliaResync, { loading }] = useMutation<IRunCompleteAlgoliaResyncMutationResult>(RUN_COMPLETE_ALGOLIA_RESYNC);

  const onRunCompleteAlgoliaResync = async () => {
    await runCompleteAlgoliaResync()
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.runCompleteAlgoliaResync)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  };

  return (
    <div>
      <h5>Reindeksering</h5>

      <p>
        Dersom automatisk synkronisering mot Algolia er avskrudd kan søkedata bli utdatert. For å oppdatere all data i Algolia, kan man
        kjøre en manuell reindeksering.
      </p>

      <p>
        <strong>Dette vil tømme indeksene i Algolia og populere dem på nytt.</strong>
      </p>

      <p>
        Merk at det ikke anbefales å kjøre denne mer enn nødvendig, da det påvirker operasjonskvoten i Algolia. For mer informasjon om
        gjeldende kvote og forbruk, besøk kontrollpanelet hos Algolia.com.
      </p>

      <Button
        variant="primary"
        className="mr-1"
        size="sm"
        disabled={!isAdmin || loading}
        onClick={(event: any) => {
          event.preventDefault();
          const confirmed = window.confirm(`Er du sikker på at du vil resynkronisere all data?`);

          if (confirmed) {
            onRunCompleteAlgoliaResync();
          }
        }}>
        {loading ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Synkroniserer...</span>
          </>
        ) : (
          <span> Resynkroniser</span>
        )}
      </Button>
    </div>
  );
};

export default RunCompleteAlgoliaResync;

import React from "react";
import Attachments from "../../Attachment/Attachments/Attachments";
import EditReferenceForm from "./EditReferenceForm";
import AuditLogEntityList from "../../_common/Audit/AuditLog/AuditLogEntityList";
import { IEntityEnum } from "../../../interfaces/domain/enums/IEntityEnum";
import { IReference } from "../../../interfaces/domain/IReference";

interface IEditReferenceProps {
  reference: IReference;
}

const EditReferenceFormContainer: React.FunctionComponent<IEditReferenceProps> = (props) => {
  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-6 mb-3">
          <EditReferenceForm reference={props.reference}></EditReferenceForm>
        </div>

        <div className="col-lg-6">
          <Attachments referenceId={props.reference.id} attachments={props.reference.attachments}></Attachments>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <AuditLogEntityList entityId={props.reference.id} entityName={IEntityEnum.REFERENCES} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditReferenceFormContainer;

import React from "react";
import { FiPlusCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { GET_TEACHING_SUBJECT_CREATE_ROUTE, ROUTES } from "../../../lib/Router/routes";
import { ITeachingSubject } from "../../../interfaces/domain/ITeachingSubject";
import FormSubTitle from "../../_common/Form/FormSubTitle/FormSubTitle";
import { useAuth } from "../../../lib/hooks/useAuth";
import TeachingSubjectsColumnList from "./TeachingSubjectsColumnList";

interface TeachingSubjectsColumnProps {
  teachingSubjects: Array<ITeachingSubject>;
  teachingCategoryId: number;
}

const TeachingSubjectsColumn: React.FunctionComponent<TeachingSubjectsColumnProps> = (props) => {
  const { canVisit } = useAuth();

  return (
    <div>
      <div className="card shadow-sm">
        <div className="card-body border-bottom">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <FormSubTitle>Fag</FormSubTitle>
            {canVisit(ROUTES.ADMIN.TEACHING_SUBJECT_CREATE_ROUTE) &&
              <div className="btn-toolbar mb-2 mb-md-0">
                <NavLink to={GET_TEACHING_SUBJECT_CREATE_ROUTE(props.teachingCategoryId)} className="btn btn-sm btn-outline-dark" role="button" exact>
                  <FiPlusCircle /> Legg til
                </NavLink>
              </div>}
          </div>
        </div>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <TeachingSubjectsColumnList teachingSubjects={props.teachingSubjects} teachingCategoryId={props.teachingCategoryId}></TeachingSubjectsColumnList>
        </div>
      </div>
    </div>
  );
}

export default TeachingSubjectsColumn;

import React from "react";
import { FiPlusCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../lib/Router/routes";
import Search from "../../_common/Search/Search";
import { useAuth } from "../../../lib/hooks/useAuth";
import { SearchFilterObjectTypeEnum } from "../../../interfaces/algolia/ISearchResultHit";

const Histories: React.FunctionComponent = (props) => {
  const { canVisit } = useAuth();

  return (
    <div>
      <div className="flex-wrap pb-2 mb-3 d-flex justify-content-between flex-md-nowrap align-items-center border-bottom">
        <h1 className="h2">Historier</h1>
        <div className="mb-2 btn-toolbar mb-md-0">
          {canVisit(ROUTES.ADMIN.HISTORY_CREATE_ROUTE) && (
            <NavLink to={ROUTES.ADMIN.HISTORY_CREATE_ROUTE} className="btn btn-sm btn-outline-dark" role="button" exact>
              <FiPlusCircle /> Legg til
            </NavLink>
          )}
        </div>
      </div>

      <Search objectType={SearchFilterObjectTypeEnum.History}></Search>
    </div>
  );
};

export default Histories;

import { useParams } from "react-router-dom";
import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import CreateQuestion from "../components/Question/CreateQuestion/CreateQuestion";

const CreateQuestionPage: React.FunctionComponent = () => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <CreateQuestion assignmentId={id} />
}

export default CreateQuestionPage;

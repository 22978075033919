import React from "react";
import { IEntityReference } from "../../../interfaces/domain/IEntityReference";
import EntityReferences from "../../Reference/EntityReferences/EntityReferences";
import { IAssignment } from "../../../interfaces/domain/IAssignment";
import { IEntityReferenceTypeEnum } from "../../../interfaces/forms/IEntityReferenceForm";

interface AssignmentReferencesProps {
  assignmentReferences: Array<IEntityReference<IAssignment>>;
  assignmentId: number;
}

const AssignmentReferences: React.FunctionComponent<AssignmentReferencesProps> = props => {
  return (
    <EntityReferences
      entityReferences={props.assignmentReferences}
      entityType={IEntityReferenceTypeEnum.ASSIGNMENT}
      entityId={props.assignmentId}></EntityReferences>
  );
};

export default AssignmentReferences;

import * as React from "react";
import PersonResultHit from "./SearchResultHitTypes/PersonResultHit";
import {
  ISearchResultHit,
  IPersonResultHit,
  SearchObjectTypeEnum,
  IPrisonCampResultHit,
  IHistoryResultHit,
  IReferenceResultHit,
} from "../../../interfaces/algolia/ISearchResultHit";
import PrisonCampResultHit from "./SearchResultHitTypes/PrisonCampResultHit";
import HistoryResultHit from "./SearchResultHitTypes/HistoryResultHit";
import ReferenceResultHit from "./SearchResultHitTypes/ReferenceResultHit";
import { IPageSource } from "../../../interfaces/domain/common/ISearch";
import ReferenceTabsResultHit from "./SearchResultHitTypes/ReferenceTabsResultHit";

type SearchResultHitProps = {
  hit: ISearchResultHit;
  onClick?: () => void;
  hideIcon?: boolean;
  pageSource?: IPageSource;
};

const SearchResultHit: React.FunctionComponent<SearchResultHitProps> = (props) => {
  switch (props.hit.objectType) {
    case SearchObjectTypeEnum.Person:
      if (props.pageSource?.sourceType === "reference")
        return (
          <ReferenceTabsResultHit
            objectType={props.hit.objectType}
            pageSource={props.pageSource}
            onClick={props.onClick}
            hit={props.hit as IPersonResultHit}
          />
        );

      return <PersonResultHit onClick={props.onClick} hit={props.hit as IPersonResultHit} hideIcon={props.hideIcon} />;

    case SearchObjectTypeEnum.PrisonCamp:
      if (props.pageSource?.sourceType === "reference")
        return (
          <ReferenceTabsResultHit
            objectType={props.hit.objectType}
            pageSource={props.pageSource}
            onClick={props.onClick}
            hit={props.hit as IPrisonCampResultHit}
          />
        );
      return <PrisonCampResultHit onClick={props.onClick} hit={props.hit as IPrisonCampResultHit} hideIcon={props.hideIcon} />;

    case SearchObjectTypeEnum.History:
      if (props.pageSource?.sourceType === "reference")
        return (
          <ReferenceTabsResultHit
            objectType={props.hit.objectType}
            pageSource={props.pageSource}
            onClick={props.onClick}
            hit={props.hit as IHistoryResultHit}
          />
        );
      return <HistoryResultHit onClick={props.onClick} hit={props.hit as IHistoryResultHit} hideIcon={props.hideIcon} />;

    case SearchObjectTypeEnum.Reference:
      return <ReferenceResultHit onClick={props.onClick} hit={props.hit as IReferenceResultHit} hideIcon={props.hideIcon} />;
  }
};

export default SearchResultHit;

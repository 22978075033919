import * as yup from "yup";

export const districtSchema = yup.object({
  name: yup.string().required("Må fylles ut"),
  code: yup.string().required("Må fylles ut"),
  yearOfOrigin: yup.object({
    value: yup.number().required("Må fylles ut"),
  }),
  region: yup.object({
    value: yup.number().required("Må fylles ut"),
  }),
});

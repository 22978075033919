import { Link } from "react-router-dom";
import { IEntityAssignment } from "../../../interfaces/domain/IEntityAssignment";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import { GET_ASSIGNMENT_ROUTE } from "../../../lib/Router/routes";

interface ReferenceUndervisning {
  assignments: Array<IEntityAssignment>;
}

const renderResult = (props: any) => {
  if (!props || !props.assignments || !props.assignments.length) return <div className="card-body text-muted">Ingen treff</div>;
  return props.assignments.map((assignment: IEntityAssignment) => (
    <li
      key={assignment.assignment.id}
      className="list-group-item list-group-item-action"
      style={{ border: "1px solid #ddd", marginBottom: "8px" }}>
      <div className="row">
        <div className="d-flex w-100 row">
          <div className="col">
            <Link
              className="col-auto d-flex align-items-center"
              to={GET_ASSIGNMENT_ROUTE(assignment.assignment.id)}
              key={assignment.assignment.id}>
              {assignment.assignment.title}
            </Link>
          </div>
        </div>
      </div>
    </li>
  ));
};

const ReferenceUndervisning: React.FunctionComponent<ReferenceUndervisning> = (props) => {
  return (
    <>
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="shadow-sm card">
              <div className="card-body">
                <h5 className="mb-3">Tilknyttede undervisningsoppgaver</h5>
                <div className="list-group list-group-flush">{renderResult(props)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferenceUndervisning;

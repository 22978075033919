import { useMutation } from "@apollo/client";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { UPDATE_COUNTRY_MUTATION } from "../../../graphql/mutations";
import { GET_COUNTRY_QUERY } from "../../../graphql/queries";
import { countrySchema } from "../../../helpers/validators/countrySchema";
import { ICountry } from "../../../interfaces/domain/ICountry";
import { ICountryForm } from "../../../interfaces/forms/ICountryForm";
import { IUpdateCountryMutationInput, IUpdateCountryMutationResult } from "../../../interfaces/mutations/IUpdateCountryMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { ROUTES } from "../../../lib/Router/routes";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";
import { getYearOfOriginSelectOptions } from "../../../helpers/locationHelpers";
import Select from "react-select";
import { mapToSelectOption } from "../../../helpers/selectHelpers";

interface IEditCountryFormProps {
  country: ICountry;
}

const EditCountryForm: React.FunctionComponent<IEditCountryFormProps> = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState, control, getValues } = useForm<ICountryForm>({
    validationSchema: countrySchema,
    mode: "onChange",
    defaultValues: {
      name: props.country.name,
      code: props.country.code,
      yearOfOrigin: mapToSelectOption(props.country.yearOfOrigin, props.country.yearOfOrigin.toString()),
    },
  });

  const [updateCountry] = useMutation<IUpdateCountryMutationResult, IUpdateCountryMutationInput>(UPDATE_COUNTRY_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateCountry({
      variables: {
        input: {
          id: props.country.id,
          name: formValues.name,
          code: formValues.code,
          yearOfOrigin: formValues.yearOfOrigin.value as number,
        },
      },
      refetchQueries: [{ query: GET_COUNTRY_QUERY, variables: { filter: { id: props.country.id } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateCountry)) {
          handleMutationSuccess(result.data);

          history.push(ROUTES.ADMIN.COUNTRIES_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="yearOfOrigin">
          Opprinnelsesår
        </label>

        <Controller
          as={Select}
          control={control}
          name="yearOfOrigin"
          value={getValues().yearOfOrigin}
          options={getYearOfOriginSelectOptions()}
          placeholder="Velg..."
          isDisabled
        />
      </div>

      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <div className="form-group">
        <label className="required-field" htmlFor="code">
          Landkode
        </label>
        <input type="text" name="code" ref={register} className={`form-control ${errors.code ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.code}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditCountryForm;

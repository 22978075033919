import React from "react";
import { IPrisonCamp } from "../../../../interfaces/domain/IPrisonCamp";

import DataNotFound from "../../../_common/DataNotFound/DataNotFound";
import { Link } from "react-router-dom";
import { GET_PRISON_CAMP_ROUTE } from "../../../../lib/Router/routes";

interface EntityPrisonCampsListProps {
  entityPrisonCamps: Array<IPrisonCamp>;
  entityId: number;
}

const EntityPrisonCampsList: React.FunctionComponent<EntityPrisonCampsListProps> = (props) => {
  if (!props.entityPrisonCamps || props.entityPrisonCamps.length === 0) {
    return <DataNotFound></DataNotFound>;
  }

  return (
    <div className="EntityPrisonCampsList">
      <table className="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Navn</th>
          </tr>
        </thead>

        <tbody>
          {props.entityPrisonCamps.map((entityPrisonCamp) => (
            <tr key={entityPrisonCamp.id}>
              <td>{entityPrisonCamp.id}</td>
              <td>
                <Link to={GET_PRISON_CAMP_ROUTE(entityPrisonCamp.id)}>{entityPrisonCamp.name}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EntityPrisonCampsList;

import React from "react";
import { useParams } from "react-router-dom";
import EditAssignment from "../components/Assignment/EditAssignment/EditAssignment";
import PageNotFound from "../components/_common/PageNotFound/PageNotFound";


const AssignmentPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditAssignment id={Number(id)}></EditAssignment>
};

export default AssignmentPage;

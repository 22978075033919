import React from "react";
import { useParams } from "react-router-dom";

import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import EditCountry from "../components/Country/EditCountry/EditCountry";

const CountryPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditCountry id={Number(id)}></EditCountry>;
};

export default CountryPage;

import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { UPDATE_PROFESSION_MUTATION } from "../../../graphql/mutations";
import { professionSchema } from "../../../helpers/validators/professionSchema";
import { IProfession } from "../../../interfaces/domain/IProfession";
import { IProfessionForm } from "../../../interfaces/forms/IProfessionForm";
import { IUpdateProfessionMutationInput, IUpdateProfessionMutationResult } from "../../../interfaces/mutations/IUpdateProfessionMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { ROUTES } from "../../../lib/Router/routes";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";
import IndustriesSelectInput from "../../Industry/IndustriesSelectInput/IndustriesSelectInput";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import { IIndustry } from "../../../interfaces/domain/IIndustry";

interface IEditProfessionFormProps {
  profession: IProfession;
}

const EditProfessionForm: React.FunctionComponent<IEditProfessionFormProps> = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState, control, getValues } = useForm<IProfessionForm>({
    validationSchema: professionSchema,
    mode: "onChange",
    defaultValues: {
      name: props.profession.name,
      industries: props.profession.industries?.map((industry) => {
        return mapToSelectOption(industry.id, industry.name);
      }),
    },
  });

  const [updateProfession] = useMutation<IUpdateProfessionMutationResult, IUpdateProfessionMutationInput>(UPDATE_PROFESSION_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateProfession({
      variables: {
        input: {
          id: props.profession.id,
          name: formValues.name,
          industries: formValues.industries?.map((industry) => {
            return { id: industry.value, name: industry.label } as IIndustry;
          }),
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateProfession)) {
          handleMutationSuccess(result.data);
          history.push(ROUTES.ADMIN.PROFESSIONS_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <div className="form-group">
        <IndustriesSelectInput
          fieldName="industries"
          label="Bransjer"
          control={control}
          errors={errors}
          currentValues={getValues().industries}
        />
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditProfessionForm;

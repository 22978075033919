import React from "react";
import { useQuery } from "@apollo/client";
import { FiPlusCircle, FiUsers } from "react-icons/fi";
import { NavLink, Link } from "react-router-dom";
import { ROUTES } from "../../../lib/Router/routes";
import { GET_PERSON_ROUTE } from "../../../lib/Router/routes";
import { GET_PERSONS_QUERY } from "../../../graphql/queries";
import { GetPersonFullName } from "../../../helpers/personHelpers";
import { IGetPersonsQueryResult, IGetPersonsQueryFilter } from "../../../interfaces/queries/IGetPersonsQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { GetFormattedDateTimeShort } from "../../../helpers/dateHelpers";

const DashboardPersonsList: React.FunctionComponent = (props) => {
  const { loading, error, data } = useQuery<IGetPersonsQueryResult, IGetPersonsQueryFilter>(GET_PERSONS_QUERY, {
    variables: { filter: { amountToReturn: 5, sortByLastCreated: "DESC" } },
  });

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data) return <DataNotFound message="Ingen personer funnet" />;

  return (
    <div className="card shadow-sm">
      <div className="card-header ">
        <div className="float-right">
          <NavLink to={ROUTES.AUTHORIZED.PERSON_CREATE_ROUTE} className="btn btn-sm btn-outline-dark mr-1" role="button" exact>
            <FiPlusCircle /> Legg til ny person
          </NavLink>
          <NavLink to={ROUTES.AUTHORIZED.PERSONS_ROUTE} className="btn btn-sm btn-outline-dark" role="button" exact>
            Vis alle
          </NavLink>
        </div>
        <h4 className="dashboard-card-header m-0">
          <FiUsers className="text-muted" /> Sist opprettede personprofiler
        </h4>

        <small className="text-muted">Totalt antall: {data.persons.totalPersonsCount}</small>
      </div>

      <div className="list-group list-group-flush">
        {data.persons.persons.map((person) => (
          <Link className="list-group-item list-group-item-action" to={GET_PERSON_ROUTE(person.id)} key={person.id}>
            <div className="d-flex w-100 justify-content-between">
              <div>{GetPersonFullName(person.firstName, person.middleName, person.lastName)}</div>

              <small className="text-muted">{GetFormattedDateTimeShort(person.audit.createdAt)}</small>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DashboardPersonsList;

import { IDate } from "../domain/IDate";

export enum SearchObjectTypeEnum {
  Person = "Person",
  PrisonCamp = "PrisonCamp",
  History = "History",
  Reference = "Reference",
}

export enum SearchFilterObjectTypeEnum {
  Person = "objectType:Person",
  PrisonCamp = "objectType:PrisonCamp",
  History = "objectType:History",
  Reference = "objectType:Reference",
}

export interface ISearchResultHit {
  objectID: string;
  objectType: SearchObjectTypeEnum;
  id: number;
}

export interface IPersonResultHit extends ISearchResultHit {
  firstName: string;
  middleName: string;
  lastName: string;
  aliases?: Array<{
    firstName: string;
    middleName: string;
    lastName: string;
  }>;
  captivities?: Array<{
    prisonCampName: string;
    prisonerNumber: string;
  }>;
  nationalities?: Array<string>;
  placeOfBirth?: {
    country: string;
    region: string;
    district: string;
  }
  placeOfResidence?: {
    country: string;
    region: string;
    district: string;
  };
  birthDate?: IDate;
  references?: Array<{
    id: number;
    cUrl: string;
    cComment: string;
  }>;
}

export interface IPrisonCampResultHit extends ISearchResultHit {
  name: string;
  aliases?: Array<string>;
  categories?: Array<string>;
}

export interface IHistoryResultHit extends ISearchResultHit {
  title: string;
}

export interface IReferenceResultHit extends ISearchResultHit {
  title: string;
  archiveIdentification: string;
  source: string;
  category: string;
}

import React from "react";
import ExportingToCsv from "../components/ExportToCsv/ExportToCsv/ExportingToCsv";

const ExportToCsvPage: React.FunctionComponent = (props) => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Eksporter til CSV</h1>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="card shadow-sm">

              <ExportingToCsv />

          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportToCsvPage;

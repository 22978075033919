import React from "react";
import ChangePasswordForm from "../components/Authentication/ChangePassword/ChangePasswordForm";

const ChangePasswordPage: React.FunctionComponent = props => {
  return (
    <div>
      <div className="pb-2 mb-3 border-bottom">
        <h1 className="h2">Bytt passord</h1>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <ChangePasswordForm></ChangePasswordForm>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPage;

import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { UPDATE_CITIZENSHIP_MUTATION } from "../../../graphql/mutations";
import { citizenshipSchema } from "../../../helpers/validators/citizenshipSchema";
import { ICitizenship } from "../../../interfaces/domain/ICitizenship";
import { ICitizenshipForm } from "../../../interfaces/forms/ICitizenshipForm";
import {
  IUpdateCitizenshipMutationInput,
  IUpdateCitizenshipMutationResult,
} from "../../../interfaces/mutations/IUpdateCitizenshipMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { ROUTES } from "../../../lib/Router/routes";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface IEditCitizenshipFormProps {
  citizenship: ICitizenship;
}

const EditCitizenshipForm: React.FunctionComponent<IEditCitizenshipFormProps> = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState } = useForm<ICitizenshipForm>({
    validationSchema: citizenshipSchema,
    mode: "onChange",
    defaultValues: {
      name: props.citizenship.name,
    },
  });

  const [updateCitizenship] = useMutation<IUpdateCitizenshipMutationResult, IUpdateCitizenshipMutationInput>(UPDATE_CITIZENSHIP_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateCitizenship({
      variables: {
        input: {
          id: props.citizenship.id,
          name: formValues.name,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateCitizenship)) {
          handleMutationSuccess(result.data);

          history.push(ROUTES.ADMIN.CITIZENSHIPS_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditCitizenshipForm;

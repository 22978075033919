import * as yup from "yup";
import { dateSchema } from "./dateSchema";

export const personSchema = yup.object({
  identificationNumber: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  birthDate: dateSchema,
  deathDate: dateSchema,
});

import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SIGN_IN_MUTATION } from "../../../graphql/mutations";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { signInSchema } from "../../../helpers/validators/signInSchema";
import { ISignInForm } from "../../../interfaces/forms/ISignInForm";
import { ISignInMutationInput, ISignInMutationResult } from "../../../interfaces/mutations/ISignInMutation";
import { getAuthenticatedUserFromInfoCookie } from "../../../lib/AuthenticationService";
import { useAuth } from "../../../lib/hooks/useAuth";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { ROUTES } from "../../../lib/Router/routes";

const SignInForm: React.FunctionComponent = (props) => {
  const { register, handleSubmit, errors, formState } = useForm<ISignInForm>({
    validationSchema: signInSchema,
    mode: "onChange",
  });

  const [signInMutation] = useMutation<ISignInMutationResult, ISignInMutationInput>(SIGN_IN_MUTATION);
  const { signIn } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { from }: any = location.state || { from: { pathname: "/" } };

  const onSubmit = handleSubmit(async (formValues) => {
    await signInMutation({
      variables: {
        input: {
          email: formValues.email,
          password: formValues.password,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.signIn)) {
          const user = getAuthenticatedUserFromInfoCookie();

          if (user) {
            signIn(user);

            handleMutationSuccess(result.data, "Logget inn");
            history.replace(from);
          } else {
            handleMutationError(undefined, "Feil innlogging");
          }
        } else {
          handleMutationError(undefined, "Feil innlogging");
        }
      })
      .catch((error) => {
        handleMutationError("Feil innlogging", error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <h1 className="display-4 text-center mb-4">Logg inn</h1>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="email" className="sr-only">
              E-post
            </label>

            <input
              type="email"
              name="email"
              ref={register}
              className={`form-control ${errors.email ? "is-invalid" : "valid"}`}
              placeholder="E-post"
              autoComplete="off"
            />

            <FieldError error={errors.email}></FieldError>
          </div>

          <div className="form-group">
            <label htmlFor="password" className="sr-only">
              Passord
            </label>

            <input
              type="password"
              name="password"
              ref={register}
              className={`form-control ${errors.password ? "is-invalid" : "valid"}`}
              placeholder="Passord"
              autoComplete="off"
            />

            <FieldError error={errors.password}></FieldError>
          </div>

          <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-lg btn-primary btn-block">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Logger inn...</span>
              </>
            ) : (
              <span>Logg inn</span>
            )}
          </button>

          <p className="mt-3 text-center">
            <Link to={ROUTES.PUBLIC.GENERATE_RESET_PASSWORD_ROUTE} className="btn btn-link">
              Glemt passord?
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default SignInForm;

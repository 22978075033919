import * as React from "react";
import { FiBookOpen } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IHistoryResultHit } from "../../../../interfaces/algolia/ISearchResultHit";
import { GET_HISTORY_ROUTE } from "../../../../lib/Router/routes";

type HistoryResultHitProps = {
  hit: IHistoryResultHit;
  onClick?: () => void;
  hideIcon?: boolean;
};

const HistoryResultHit: React.FunctionComponent<HistoryResultHitProps> = props => {
  const history = props.hit;

  return (
    <Link
      to={GET_HISTORY_ROUTE(history.id)}
      className="list-group-item list-group-item-action"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}>
      <div className="media d-flex align-items-center">
        {props.hideIcon ? null : <FiBookOpen size={26} className="text-muted mr-2" />}

        <div className="media-body">
          <span className="h6 m-0">{props.hit.title}</span>
        </div>
      </div>
    </Link>
  );
};

export default HistoryResultHit;

import React from "react";
import { useParams } from "react-router-dom";

import PageNotFound from "../components/_common/PageNotFound/PageNotFound";
import EditProfession from "../components/Profession/EditProfession/EditProfession";
import Search from "../components/_common/Search/Search";
import { SearchFilterObjectTypeEnum } from "../interfaces/algolia/ISearchResultHit";

const ProfessionPage: React.FunctionComponent = () => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  const personSearchFilter = `${SearchFilterObjectTypeEnum.Person} AND professions:${id}`;

  return (
    <>
      <EditProfession id={Number(id)} />

      <div className="row mt-3">
        <div className="col-xl-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="mb-3">Tilknyttede personer</h5>
              <Search defaultFilters={personSearchFilter} hideSearchFilter hideSearchBox></Search>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionPage;

import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { RESET_PASSWORD_MUTATION } from "../../../graphql/mutations";
import { resetPasswordSchema } from "../../../helpers/validators/resetPasswordSchema";
import { IResetPasswordForm } from "../../../interfaces/forms/IResetPasswordForm";
import { IResetPasswordMutationInput, IResetPasswordMutationResult } from "../../../interfaces/mutations/IResetPasswordMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { useHistory, Link } from "react-router-dom";
import { mutationIsSuccessful, handleMutationError, handleMutationSuccess } from "../../../helpers/formHelpers";
import { ROUTES } from "../../../lib/Router/routes";

const ResetPasswordForm: React.FunctionComponent = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState } = useForm<IResetPasswordForm>({
    validationSchema: resetPasswordSchema,
    mode: "onChange",
  });

  const [resetPasswordMutation] = useMutation<IResetPasswordMutationResult, IResetPasswordMutationInput>(RESET_PASSWORD_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await resetPasswordMutation({
      variables: {
        input: {
          email: formValues.email,
          resetToken: formValues.resetToken,
          newPassword: formValues.newPassword,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.resetPassword)) {
          handleMutationSuccess(result.data, "Nullstilling vellykket. Logg inn på nytt.");

          history.push(ROUTES.PUBLIC.SIGN_IN_ROUTE);
        } else {
          handleMutationError(undefined, "Kunne ikke nullstille");
        }
      })
      .catch((error) => {
        handleMutationError(error, "Kunne ikke nullstille");
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <h1 className="display-4 text-center mb-4">Nullstill passord</h1>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="email">E-post</label>

            <input
              type="email"
              name="email"
              ref={register}
              className={`form-control ${errors.email ? "is-invalid" : "valid"}`}
              placeholder="E-post"
              autoComplete="off"
            />

            <FieldError error={errors.email}></FieldError>
          </div>

          <div className="form-group">
            <label htmlFor="resetToken">Nøkkel</label>

            <input
              type="text"
              name="resetToken"
              ref={register}
              className={`form-control ${errors.resetToken ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />

            <FieldError error={errors.resetToken}></FieldError>
          </div>

          <div className="form-group">
            <label htmlFor="newPassword">Nytt passord</label>

            <input
              type="password"
              name="newPassword"
              ref={register}
              className={`form-control ${errors.newPassword ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />

            <FieldError error={errors.newPassword}></FieldError>
          </div>

          <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-lg btn-primary btn-block">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Sender...</span>
              </>
            ) : (
              <span>Send</span>
            )}
          </button>

          <p className="mt-3 text-center">
            <Link to={ROUTES.PUBLIC.SIGN_IN_ROUTE} className="btn btn-link">
              Tilbake til innlogging
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default ResetPasswordForm;

import { useForm } from "react-hook-form";
import { IQuestion } from "../../../interfaces/domain/IQuestion";
import { IQuestionForm } from "../../../interfaces/forms/IQuestionForm";
import { questionSchema } from "../../../helpers/validators/questionSchema";
import { Prompt } from "react-router-dom";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { useMutation } from "@apollo/client";
import { UPDATE_QUESTION_MUTATION } from "../../../graphql/mutations";
import { IUpdateQuestionMutationInput, IUpdateQuestionMutationResult } from "../../../interfaces/mutations/IUpdateQuestionMutation";
import { GET_QUESTION_QUERY } from "../../../graphql/queries";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";


interface IEditQuestionFormProps {
  question: IQuestion;
}

const EditQuestionForm: React.FunctionComponent<IEditQuestionFormProps> = (props) => {
  const { register, handleSubmit, errors, formState } = useForm<IQuestionForm>({
    validationSchema: questionSchema,
    mode: "onChange",
    defaultValues: {
      title: props.question.title,
      displayOrder: props.question.displayOrder,
      assignmentId: props.question.assignmentId
    }
  });

  const [updateQuestion] = useMutation<IUpdateQuestionMutationResult, IUpdateQuestionMutationInput>(UPDATE_QUESTION_MUTATION);
  const onSubmit = handleSubmit(async (formValues) => {
    await updateQuestion({
      variables: {
        input: {
          id: props.question.id,
          title: formValues.title,
          displayOrder: formValues.displayOrder,
          hint: formValues.hint,
          assignmentId: props.question.assignmentId
        },
      },
      refetchQueries: [{ query: GET_QUESTION_QUERY, variables: { filter: { id: props.question.id } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateQuestion)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <Prompt
        when={formState.dirty && !formState.isSubmitted}
        message="Du har endringer som ikke er lagret. Er du sikker på du vil fortsette? Endringene vil gå tapt hvis du fortsetter uten å lagre.
        Trykk OK for å gå videre uten å lagre, eller trykk Avbryt for å gå tilbake."
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="form-group">
                <label className="required-field" htmlFor="title">
                  Tittel
                </label>
                <input type="text" name="title" ref={register} className={`form-control ${errors.title ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.title}></FieldError>
              </div>

              <div className="form-group">
                <label htmlFor="displayOrder">
                  Rekkefølge
                </label>
                <input type="number" name="displayOrder" ref={register} className={`form-control ${errors.displayOrder ? "is-invalid" : "valid"}`} />

                <FieldError error={errors.displayOrder}></FieldError>
              </div>
            </div>

            <div className="card-footer">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
                  {formState.isSubmitting ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Lagrer...</span>
                    </>
                  ) : (
                    <span>Lagre</span>
                  )}
                </button>

                <div>
                  <small className="text-muted">Innholdet vil bli publisert</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form >
  );
};

export default EditQuestionForm;

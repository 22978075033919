import { useQuery } from "@apollo/client";
import React from "react";

import { GET_COUNTRY_QUERY } from "../../../graphql/queries";
import { IGetCountryQueryFilter, IGetCountryQueryResult } from "../../../interfaces/queries/IGetCountryQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import EditCountryForm from "./EditCountryForm";

interface IEditCountryProps {
  id: number;
}

const EditCountry: React.FunctionComponent<IEditCountryProps> = (props) => {
  const { loading, error, data } = useQuery<IGetCountryQueryResult, IGetCountryQueryFilter>(GET_COUNTRY_QUERY, {
    variables: { filter: { id: props.id } },
  });

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.country) return <DataNotFound />;

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">{data.country.name}</h1>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="card shadow-sm">
            <div className="card-body">
              <EditCountryForm country={data.country}></EditCountryForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCountry;

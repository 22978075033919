import React from "react";
import { Panel, RefinementList } from "react-instantsearch-dom";

const PublishStateRefinement: React.FunctionComponent = (props) => {
  return (
    <Panel>
      <div className="SearchFilter__Refinement">
        <h6>Filtering</h6>
        <RefinementList
          attribute="isPublished"
          transformItems={(items: { label: string }[]) =>
            items.map((item: { label: string }) => ({
              ...item,
              label: item.label === "true" ? "Publisert" : "Ikke publisert",
            }))
          }
        />
      </div>
    </Panel>
  );
};

export default PublishStateRefinement;

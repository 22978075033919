import React from "react";
import "./Dashboard.scss";
import DashboardHistoriesList from "./DashboardHistoriesList/DashboardHistoriesList";
import DashboardPersonsList from "./DashboardPersonsList/DashboardPersonsList";
import DashboardCreatedPersonsList from "./DashboardCreatedPersonsList/DashboardCreatedPersonsList";
import DashboardPrisonCampsList from "./DashboardPrisonCampsList/DashboardPrisonCampsList";
import DashboardReferencesList from "./DashboardReferencesList/DashboardReferencesList";

const Dashboard: React.FunctionComponent = (props) => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Oversikt</h1>
      </div>

      <div className="Dashboard">
        <div className="row">
          <div className="col-lg-6 mb-5">
            <DashboardPersonsList />
          </div>

          <div className="col-lg-6 mb-5">
            <DashboardCreatedPersonsList />
          </div>

          <div className="col-lg-6 mb-5">
            <DashboardPrisonCampsList />
          </div>

          <div className="col-lg-6 mb-5">
            <DashboardHistoriesList />
          </div>

          <div className="col-lg-6 mb-5">
            <DashboardReferencesList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import * as React from "react";
import { Link } from "react-router-dom";
import { GET_PERSON_ROUTE, GET_HISTORY_ROUTE, GET_PRISON_CAMP_ROUTE } from "../../../../lib/Router/routes";
import { GetPersonFullName } from "../../../../helpers/personHelpers";
import { SearchObjectTypeEnum } from "../../../../interfaces/algolia/ISearchResultHit";
import { GetFormattedDateByIDateObject } from "../../../../helpers/dateHelpers";
import { IPageSource } from "../../../../interfaces/domain/common/ISearch";

type ReferenceTabsResultHitProps = {
  objectType: SearchObjectTypeEnum;
  hit: any;
  onClick?: () => void;
  pageSource: IPageSource;
};

const ReferenceTabsResultHit: React.FunctionComponent<ReferenceTabsResultHitProps> = (props) => {
  const hit = props.hit;
  const birthDateString = hit.birthDate ? `Født: ${GetFormattedDateByIDateObject(hit.birthDate)}` : "";
  const relatedPersonReference = hit.references?.find((x: any) => x.id === props.pageSource.id);

  let titleShow = "";
  let linkTo = "";

  switch (props.objectType) {
    case SearchObjectTypeEnum.Person:
      titleShow = GetPersonFullName(hit.firstName, hit.middleName, hit.lastName);
      linkTo = GET_PERSON_ROUTE(hit.id);
      break;
    case SearchObjectTypeEnum.PrisonCamp:
      titleShow = hit.name;
      linkTo = GET_PRISON_CAMP_ROUTE(hit.id);
      break;
    case SearchObjectTypeEnum.History:
      titleShow = hit.title;
      linkTo = GET_HISTORY_ROUTE(hit.id);
      break;
  }
  return (
    <Link
      to={linkTo}
      className="list-group-item list-group-item-action"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}>
      <div className="media d-flex align-items-center">
        <div className="media-body">
          <div className="row">
            <div className="col">
              <div>
                <span className=" h6">{titleShow} </span>
              </div>
              <div className="text-muted">{birthDateString}</div>
            </div>
            <div className="col text-muted text-break">{relatedPersonReference?.cComment}</div>
            <div className="col text-muted text-break">{relatedPersonReference?.cUrl}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ReferenceTabsResultHit;

import React from "react";
import ToggleAlgoliaAutomaticSync from "../components/Setting/ToggleAlgoliaAutomaticSync/ToggleAlgoliaAutomaticSync";
import RunCompleteAlgoliaResync from "../components/Setting/RunCompleteAlgoliaResync/RunCompleteAlgoliaResync";

const SettingsPage: React.FunctionComponent = props => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Algolia</h1>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <ToggleAlgoliaAutomaticSync />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <RunCompleteAlgoliaResync />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;

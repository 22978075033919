import * as yup from "yup";
import { transformToNull } from "../validatorHelpers";

export const dateSchema = yup.object({
  day: yup
    .number()
    .positive()
    .min(1, "Ugyldig tall")
    .max(31, "Ugyldig tall")
    .nullable(true)
    .transform(transformToNull),
  month: yup
    .number()
    .positive()
    .min(1, "Ugyldig tall")
    .max(12, "Ugyldig tall")
    .nullable(true)
    .transform(transformToNull),
  year: yup
    .number()
    .positive()
    .min(1800, "Ugyldig tall")
    .max(3000, "Ugyldig tall")
    .nullable()
    .transform(transformToNull),
  certainty: yup.string().required("Må fylles ut")
});

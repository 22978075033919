import React from "react";
import { FiPlusCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import ProfessionsList from "./ProfessionsList";
import { ROUTES } from "../../../lib/Router/routes";

const Professions: React.FunctionComponent = props => {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Yrker</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <NavLink to={ROUTES.ADMIN.PROFESSION_CREATE_ROUTE} className="btn btn-sm btn-outline-dark" role="button" exact>
            <FiPlusCircle /> Legg til
          </NavLink>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className="card shadow-sm">
            <div className="card-body">
              <ProfessionsList></ProfessionsList>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Professions;

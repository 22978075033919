import React from "react";
import CreateUserForm from "../components/User/CreateUser/CreateUserForm";

const CreateUserPage: React.FunctionComponent = props => {
  return (
    <div>
      <div className="pb-2 mb-3 border-bottom">
        <h1 className="h2">Opprett bruker</h1>
      </div>

      <div className="row">
        <div className="col-lg-4">
          <CreateUserForm></CreateUserForm>
        </div>
      </div>
    </div>
  );
};

export default CreateUserPage;

import { ITeachingCategory } from "../../../interfaces/domain/ITeachingCategory";
import TeachingSubjectsColumn from "../../TeachingSubject/TeachingSubjects/TeachingSubjectsColumn";
import EditTeachingCategoryForm from "./EditTeachingCategoryForm";

interface IEditTeachingCategoryProps {
  teachingCategory: ITeachingCategory;
}

const EditTeachingCategoryFormContainer: React.FunctionComponent<IEditTeachingCategoryProps> = (props) => {

  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-6 mb-3">
          <EditTeachingCategoryForm teachingCategory={props.teachingCategory}></EditTeachingCategoryForm>
        </div>

        <div className="col-lg-6">
          <TeachingSubjectsColumn teachingCategoryId={props.teachingCategory.id} teachingSubjects={props.teachingCategory.teachingSubjects}></TeachingSubjectsColumn>
        </div>
      </div>
    </div>
  )
}

export default EditTeachingCategoryFormContainer;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiPlusCircle } from "react-icons/fi";
import { IEvent } from "../../../interfaces/domain/IEvent";
import CreateEventForm from "../CreateEvent/CreateEventForm";
import EventsList from "./EventsList";

interface EventsProps {
  events: Array<IEvent>;
  personId: number;
}

const Events: React.FunctionComponent<EventsProps> = props => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <div>
      <div className="mb-3 text-right">
        <button className="btn btn-sm btn-outline-dark" onClick={handleShowModal}>
          <FiPlusCircle /> Legg til
        </button>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow-sm">
            <div className="card-body">
              <EventsList events={props.events} capativityRelatedEvent={false} personId={props.personId}></EventsList>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} dialogClassName="vw-100" size="xl" onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Legg til hendelse</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateEventForm personId={props.personId} handleCloseModal={handleCloseModal}></CreateEventForm>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Events;

import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { DELETE_EVENT_MUTATION } from "../../../graphql/mutations";
import { GET_PERSON_QUERY } from "../../../graphql/queries";
import { GetFormattedDateByIDateObject } from "../../../helpers/dateHelpers";
import {
  GetReadableEndingName,
  GetReadableverifiedFromSourceName,
  GetReadableCertaintyName,
  CheckCapativityRelatedEventType,
} from "../../../helpers/enumHelpers";
import { IEvent } from "../../../interfaces/domain/IEvent";
import { IDeleteEventMutationInput, IDeleteEventMutationResult } from "../../../interfaces/mutations/IDeleteEventMutation";
import EditEventForm from "../EditEvent/EditEventForm";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface EventsListProps {
  events: Array<IEvent>;
  personId: number;
  capativityRelatedEvent: boolean;
}

const EventsList: React.FunctionComponent<EventsListProps> = (props) => {
  const [selectedEditEvent, setSelectedEditEvent] = useState<IEvent | undefined>(undefined);
  const [deleteEvent] = useMutation<IDeleteEventMutationResult, IDeleteEventMutationInput>(DELETE_EVENT_MUTATION);

  const handleCloseModal = () => setSelectedEditEvent(undefined);
  const handleShowModal = (event: IEvent) => setSelectedEditEvent(event);

  const onDeleteEvent = async (eventId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette hendelsen?`);

    if (deleteConfirmed) {
      await deleteEvent({
        variables: {
          input: {
            id: eventId,
          },
        },
        refetchQueries: [{ query: GET_PERSON_QUERY, variables: { filter: { id: props.personId } } }],
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteEvent)) {
            handleMutationSuccess(result.data);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  return (
    <div className="EventsList">
      {!props.capativityRelatedEvent && <h3 className="mb-4">Andre hendelser</h3>}

      <table className="table table-hover table-bordered mb-4">
        <thead>
          <tr>
            <th scope="col" style={{ width: "80px" }}>
              #
            </th>
            <th scope="col">Hendelse</th>
            <th scope="col" style={{ width: "220px" }}>
              Fra dato
            </th>
            <th scope="col" style={{ width: "220px" }}>
              Til dato
            </th>
            <th scope="col" style={{ width: "160px" }}>
              Avslutningstype
            </th>
            <th scope="col" style={{ width: "100px" }}>
              Kryssjekk
            </th>
            <th scope="col" style={{ width: "140px" }}></th>
          </tr>
        </thead>

        <tbody>
          {props.events.map(
            (event) =>
              CheckCapativityRelatedEventType(event.eventType.name) === props.capativityRelatedEvent && (
                <tr key={event.id}>
                  <td>{event.displayOrder}</td>
                  <td>{event.eventType.name}</td>
                  <td>
                    {GetFormattedDateByIDateObject(event.startDate)} ({GetReadableCertaintyName(event.startDate.certainty)})
                  </td>
                  <td>
                    {GetFormattedDateByIDateObject(event.endDate)} ({GetReadableCertaintyName(event.endDate.certainty)})
                  </td>
                  <td>{GetReadableEndingName(event.ending)}</td>
                  <td>{GetReadableverifiedFromSourceName(event.verifiedFromSource)}</td>
                  <td className=" text-right">
                    <button
                      className="btn btn-sm btn-secondary mr-2"
                      onClick={() => {
                        handleShowModal(event);
                      }}>
                      Rediger
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        onDeleteEvent(event.id);
                      }}>
                      <FiTrash2 />
                    </button>
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>

      {selectedEditEvent && (
        <Modal show={!!selectedEditEvent} dialogClassName="vw-100" size="xl" onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Rediger hendelse</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditEventForm event={selectedEditEvent} personId={props.personId} handleCloseModal={handleCloseModal}></EditEventForm>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default EventsList;

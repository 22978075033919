import "instantsearch.css/themes/algolia.css";
import React, { useRef, useState } from "react";
import { Configure, InstantSearch } from "react-instantsearch-dom";
import { ENVIRONMENT_ALGOLIA_INDEX_NAME } from "../../../helpers/environmentHelpers";
import searchClient from "../../../lib/algolia/searchClient";
import { useOutsideClick } from "../../../lib/hooks/useClickOutside";
import SearchBox from "../Search/SearchBox";
import SearchResult from "../Search/SearchResult";
import "./NavbarSearch.scss";

const NavbarSearch: React.FunctionComponent = props => {
  const node = useRef<HTMLInputElement>(null);
  const [showSearchResult, setShowSearchResult] = useState(false);

  useOutsideClick(node, () => {
    setShowSearchResult(false);
  });

  return (
    <div className="NavbarSearch">
      <InstantSearch searchClient={searchClient} indexName={ENVIRONMENT_ALGOLIA_INDEX_NAME} refresh>
        <Configure hitsPerPage={5} />

        <SearchBox
          onChange={() => {
            setShowSearchResult(true);
          }}
        />

        {showSearchResult && (
          <div ref={node}>
            <SearchResult
              onClickHit={() => {
                setShowSearchResult(false);
              }}
            />
          </div>
        )}
      </InstantSearch>
    </div>
  );
};

export default NavbarSearch;

import React from "react";
import { IQueryError } from "../../../interfaces/apollo/IQueryError";
import { ENVIRONMENT_SHOW_LOGGING } from "../../../helpers/environmentHelpers";

const QueryError: React.FunctionComponent<IQueryError> = (props) => {
  if (ENVIRONMENT_SHOW_LOGGING) {
    console.error(props.apolloError);
  }

  return (
    <div className="alert alert-danger" role="alert">
      {ENVIRONMENT_SHOW_LOGGING ? props.apolloError.message : "Det har oppstått en feil."}
    </div>
  );
};

export default QueryError;

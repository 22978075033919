import { IMutationResult } from "../interfaces/mutations/IMutationResult";
import { toast } from "react-toastify";
import { ENVIRONMENT_SHOW_LOGGING } from "./environmentHelpers";

export const mutationIsSuccessful = (mutationResult: IMutationResult) => {
  if (mutationResult && mutationResult.success) {
    return true;
  } else {
    return false;
  }
};

export const handleMutationSuccess = (data?: any, message?: string) => {
  if (ENVIRONMENT_SHOW_LOGGING) {
    data ? console.info(message, data) : console.info(message);
  }

  const msg = message ? message : "Suksess";
  toast.success(msg);
};

export const handleMutationError = (error?: any, message?: string) => {
  if (ENVIRONMENT_SHOW_LOGGING) {
    error ? console.error(message, error) : console.error(message);
  }

  const msg = message ? message : "En feil har oppstått";
  toast.error(msg);
};

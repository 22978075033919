import React, { useState } from "react";
import AttachEntityReferenceForm from "./Forms/AttachEntityReferenceForm";
import CreateEntityReferenceForm from "./Forms/CreateEntityReferenceForm";
import { IEntityReferenceTypeEnum } from "../../../interfaces/forms/IEntityReferenceForm";
import { FiLink, FiFilePlus } from "react-icons/fi";

interface AddEntityReferenceProps {
  entityType: IEntityReferenceTypeEnum;
  entityId: number;
  handleClose: () => void;
}

enum AddEntityReferenceFormTypeEnum {
  ATTACH = "ATTACH",
  CREATE = "CREATE",
}

const AddEntityReference: React.FunctionComponent<AddEntityReferenceProps> = (props) => {
  const [displayFormType, setDisplayFormType] = useState<AddEntityReferenceFormTypeEnum | undefined>(undefined);

  if (displayFormType === AddEntityReferenceFormTypeEnum.ATTACH) {
    return (
      <AttachEntityReferenceForm
        entityType={props.entityType}
        entityId={props.entityId}
        handleClose={props.handleClose}></AttachEntityReferenceForm>
    );
  }

  if (displayFormType === AddEntityReferenceFormTypeEnum.CREATE) {
    return (
      <CreateEntityReferenceForm
        entityType={props.entityType}
        entityId={props.entityId}
        handleClose={props.handleClose}></CreateEntityReferenceForm>
    );
  }

  return (
    <div className="row justify-content-center">
      <div className="col-sm-6">
        <div className="card">
          <div className="card-body text-center">
            <FiLink size={40} className="text-secondary mb-2"></FiLink>

            <p className="lead">Tilknytt mot eksisterende referanse</p>

            <button
              className="btn btn-primary"
              onClick={() => {
                setDisplayFormType(AddEntityReferenceFormTypeEnum.ATTACH);
              }}>
              Tilknytt
            </button>
          </div>
        </div>
      </div>

      <div className="col-sm-6">
        <div className="card">
          <div className="card-body text-center">
            <FiFilePlus size={40} className="text-secondary mb-2"></FiFilePlus>

            <p className="lead">Opprett ny referanse</p>

            <button
              className="btn btn-primary"
              onClick={() => {
                setDisplayFormType(AddEntityReferenceFormTypeEnum.CREATE);
              }}>
              Opprett
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEntityReference;

import { useMutation, useQuery } from "@apollo/client";
import { GET_QUESTION_QUERY } from "../../../graphql/queries";
import { IGetQuestionQueryFilter, IGetQuestionQueryResult } from "../../../interfaces/queries/IGetQuestionQuery";
import { useAuth } from "../../../lib/hooks/useAuth";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import { Button, ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { IDeleteQuestionMutationInput, IDeleteQuestionMutationResult } from "../../../interfaces/mutations/IDeleteQuestionMutation";
import { DELETE_QUESTION_MUTATION, TOGGLE_PUBLISH_QUESTION_MUTATION } from "../../../graphql/mutations";
import { NavLink, useHistory } from "react-router-dom";
import { GET_QUESTION_REFERENCES_ROUTE, GET_QUESTION_ROUTE } from "../../../lib/Router/routes";
import { QuestionRouter } from "../../../lib/Router/Router";
import { GET_ASSIGNMENT_ROUTE } from "../../../lib/Router/routes";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { FiTrash2 } from "react-icons/fi";
import {
  ITogglePublishQuestionMutationInput,
  ITogglePublishQuestionMutationResult,
} from "../../../interfaces/mutations/ITogglePublishQuestionMutation";

interface IEditQuestionProps {
  id: number;
}

const EditQuestion: React.FunctionComponent<IEditQuestionProps> = (props) => {
  const { isAdmin } = useAuth();
  const history = useHistory();

  const { loading, error, data } = useQuery<IGetQuestionQueryResult, IGetQuestionQueryFilter>(GET_QUESTION_QUERY, {
    variables: { filter: { id: props.id } },
  });

  const [deleteQuestion] = useMutation<IDeleteQuestionMutationResult, IDeleteQuestionMutationInput>(DELETE_QUESTION_MUTATION);
  const [togglePublishQuestion, { loading: togglePublishQuestionLoading }] = useMutation<
    ITogglePublishQuestionMutationResult,
    ITogglePublishQuestionMutationInput
  >(TOGGLE_PUBLISH_QUESTION_MUTATION);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.question) return <DataNotFound />;

  const onDeleteQuestion = async (questionId: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette undervisningsmodulen?`);

    if (deleteConfirmed) {
      await deleteQuestion({
        variables: {
          input: {
            id: questionId,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteQuestion)) {
            handleMutationSuccess(result.data);

            history.push(GET_ASSIGNMENT_ROUTE(data.question.assignmentId));
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  const onTogglePublishQuestion = async (questionId: number, publish: boolean) => {
    await togglePublishQuestion({
      variables: {
        input: {
          id: questionId,
          publish: publish,
        },
      },
      refetchQueries: [{ query: GET_QUESTION_QUERY, variables: { filter: { id: questionId } } }],
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.togglePublishQuestion)) {
          handleMutationSuccess(result.data);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <h1 className="h1">{data.question.title}</h1>

        <div>
          {isAdmin() && (
            <ButtonToolbar className="d-flex justify-content-end">
              {data.question.publishStatus.isPublished ? (
                <Button
                  variant="warning"
                  size="sm"
                  className="mr-1"
                  disabled={togglePublishQuestionLoading}
                  onClick={(event: any) => {
                    event.preventDefault();
                    onTogglePublishQuestion(data.question.id, !data.question.publishStatus.isPublished);
                  }}>
                  {togglePublishQuestionLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Avpubliserer...</span>
                    </>
                  ) : (
                    <span> Avpubliser</span>
                  )}
                </Button>
              ) : (
                <Button
                  variant="success"
                  size="sm"
                  className="mr-1"
                  disabled={togglePublishQuestionLoading}
                  onClick={(event: any) => {
                    event.preventDefault();
                    onTogglePublishQuestion(data.question.id, !data.question.publishStatus.isPublished);
                  }}>
                  {togglePublishQuestionLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span> Publiserer...</span>
                    </>
                  ) : (
                    <span> Publiser</span>
                  )}
                </Button>
              )}
              <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
                <Dropdown.Item
                  className="text-danger"
                  eventKey="1"
                  onClick={() => {
                    onDeleteQuestion(data.question.id);
                  }}>
                  <FiTrash2 /> Slett fag
                </Dropdown.Item>
              </DropdownButton>
            </ButtonToolbar>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <NavLink to={GET_QUESTION_ROUTE(data.question.id)} exact className="nav-link" activeClassName="active bg-white">
                Generelt
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={GET_QUESTION_REFERENCES_ROUTE(data.question.id)} exact className="nav-link" activeClassName="active bg-white">
                Referanser
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <QuestionRouter question={data.question}></QuestionRouter>
        </div>
      </div>
    </div>
  );
};

export default EditQuestion;

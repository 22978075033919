import React from "react";
import { useParams } from "react-router-dom";
import EditTeachingCategory from "../components/TeachingCategory/EditTeachingCategory/EditTeachingCategory";
import PageNotFound from "../components/_common/PageNotFound/PageNotFound";

const TeachingCategoryPage: React.FunctionComponent = (props) => {
  let { id }: any = useParams();

  if (!id) {
    return <PageNotFound />;
  }

  return <EditTeachingCategory id={Number(id)}></EditTeachingCategory>
};

export default TeachingCategoryPage;

import React from "react";
import "./LayoutPublic.scss";

const LayoutPublic: React.FunctionComponent = props => {
  return (
    <main className="container">
      <div className="LayoutPublic row justify-content-md-center align-items-center">
        <div className="col-lg-5 col-md-8">{props.children}</div>
      </div>
    </main>
  );
};

export default LayoutPublic;

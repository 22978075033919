import React from "react";
import { IPerson } from "../../../interfaces/domain/IPerson";
import Search from "../../_common/Search/Search";
import { SearchFilterObjectTypeEnum } from "../../../interfaces/algolia/ISearchResultHit";

interface HistoryPersonsProps {
  historyPersons: Array<IPerson>;
  historyId: number;
}

const HistoryPersons: React.FunctionComponent<HistoryPersonsProps> = (props) => {
  const personSearchFilter = `${SearchFilterObjectTypeEnum.Person} AND histories:${props.historyId}`; 

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card shadow-sm">
          <div className="card-body">
            <h5 className="mb-3">Tilknyttede personer</h5>
            <Search defaultFilters={personSearchFilter} hideSearchFilter hideSearchBox></Search>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryPersons;

import React from "react";
import { FiPlusCircle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../lib/Router/routes";
import TeachingCategoriesList from "./TeachingCategoriesList";

interface ITeachingCategoriesProps {
  teachingModuleId: number;
}

const TeachingCategories: React.FunctionComponent<ITeachingCategoriesProps> = (props) => {
  return (<div>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h1 className="h2">Kategorier</h1>
      <div className="btn-toolbar mb-2 mb-md-0">
        <NavLink to={ROUTES.ADMIN.TEACHING_CATEGORY_CREATE_ROUTE} className="btn btn-sm btn-outline-dark" role="button" exact>
          <FiPlusCircle /> Legg til
        </NavLink>
      </div>
    </div>

    <div className="row">
      <div className="col-lg-6">
        <div className="card shadow-sm">
          <div className="card-body">
            <TeachingCategoriesList></TeachingCategoriesList>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default TeachingCategories;

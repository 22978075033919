import { useQuery } from "@apollo/client";
import React from "react";

import { GET_ROLE_QUERY } from "../../../graphql/queries";
import { IGetRoleQueryFilter, IGetRoleQueryResult } from "../../../interfaces/queries/IGetRoleQuery";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import EditRoleForm from "./EditRoleForm";

interface IEditRoleProps {
  id: number;
}

const EditRole: React.FunctionComponent<IEditRoleProps> = (props) => {
  const { loading, error, data } = useQuery<IGetRoleQueryResult, IGetRoleQueryFilter>(GET_ROLE_QUERY, {
    variables: { filter: { id: props.id } },
  });

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.role) return <DataNotFound />;

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">{data.role.name}</h1>
      </div>

      <div className="row">
        <div className="col-lg-5">
          <div className="card shadow-sm">
            <div className="card-body">
              <EditRoleForm role={data.role}></EditRoleForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRole;

import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { CREATE_TEACHING_SUBJECT_MUTATION } from "../../../graphql/mutations";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { teachingSubjectSchema } from "../../../helpers/validators/teachingSubjectSchema";
import { ITeachingSubjectForm } from "../../../interfaces/forms/ITeachingSubjectForm";
import {
  ICreateTeachingSubjectMutationInput,
  ICreateTeachingSubjectMutationResult,
} from "../../../interfaces/mutations/ICreateTeachingSubjectMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { GET_TEACHING_CATEGORY_ROUTE } from "../../../lib/Router/routes";
import { useHistory } from "react-router-dom";

interface ICreateTeachingSubjectProps {
  teachingCategoryId: number;
}

const CreateTeachingSubjectForm: React.FunctionComponent<ICreateTeachingSubjectProps> = (props) => {
  const history = useHistory();
  const { register, handleSubmit, errors, formState } = useForm<ITeachingSubjectForm>({
    validationSchema: teachingSubjectSchema,
    mode: "onChange",
  });

  const [createTeachingSubjectMutation] = useMutation<ICreateTeachingSubjectMutationResult, ICreateTeachingSubjectMutationInput>(
    CREATE_TEACHING_SUBJECT_MUTATION
  );

  const onSubmit = handleSubmit(async (formValues) => {
    await createTeachingSubjectMutation({
      variables: {
        input: {
          title: formValues.title,
          displayOrder: formValues.displayOrder,
          teachingCategoryId: props.teachingCategoryId,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createTeachingSubject)) {
          handleMutationSuccess(result.data, "Fag opprettet.");

          history.push(GET_TEACHING_CATEGORY_ROUTE(props.teachingCategoryId));
        } else {
          handleMutationError(undefined, "Kunne ikke opprette fag");
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="form-group">
            <label className="required-field" htmlFor="title">
              Tittel (Hvilket fag oppgavene passer under)
            </label>
            <input
              type="text"
              name="title"
              ref={register}
              className={`form-control ${errors.title ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />
            <FieldError error={errors.title}></FieldError>
          </div>

          <div className="form-group">
            <label className="required-field" htmlFor="displayOrder">
              Rekkefølge
            </label>
            <input
              type="number"
              name="displayOrder"
              ref={register}
              className={`form-control ${errors.displayOrder ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />
            <FieldError error={errors.displayOrder}></FieldError>
          </div>
          <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Oppretter...</span>
              </>
            ) : (
              <span>Opprett og tilknytt</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateTeachingSubjectForm;

import React, { useState, useEffect } from "react";
import { FieldError, NestDataObject, Control } from "react-hook-form/dist/types";
import { GetReadableName } from "../../../helpers/enumHelpers";
import { mapToSelectOption } from "../../../helpers/selectHelpers";
import { IReferenceResultHit } from "../../../interfaces/algolia/ISearchResultHit";
import { ISelectOption, ISelectOptions } from "../../../interfaces/domain/common/ISelectOption";
import { searchReferenceIds, searchReferences } from "../../../lib/algolia/searchQueries";
import AsyncSelect from "react-select/async";
import FieldValidationError from "../../_common/Form/FieldError/FieldError";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";

interface ReferenceSelectInputProps {
  fieldName: string;
  errors: NestDataObject<any, FieldError>;
  currentValue: ISelectOption;
  control: Control<any>;
  label?: string;
  required?: boolean;
}

const ReferenceSelectInput: React.FunctionComponent<ReferenceSelectInputProps> = (props) => {
  const [isIdSearch, setIsIdSearch] = useState(false);

  // On load
  useEffect(() => {
    let idSearch =  window.localStorage.getItem('idSearch')
    if (idSearch !== null) setIsIdSearch(JSON.parse(idSearch))
    else setIsIdSearch(false)
  }, []);

  // On state change
  useEffect(() => {
    window.localStorage.setItem('idSearch', isIdSearch ? 'true' : 'false' );
  }, [isIdSearch]);

  const handleToggle = () => setIsIdSearch(!isIdSearch);

  const fetchQueryOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(search(inputValue));
      }, 200);  // Slight delay to emphasize that the search has been updated
    });

  const search = async (query: string) => {
    let result;
    if (isIdSearch) result = await searchReferenceIds(query);
    else result = await searchReferences(query);
    return mapReferenceResultHitsToSelectOptions(result);
  };

  const mapReferenceResultHitsToSelectOptions = (referenceHits: Array<IReferenceResultHit>) => {
    let options: ISelectOptions = referenceHits.map((referenceHits) => {
      return mapToSelectOption(referenceHits.id, `#${referenceHits.id} ${GetReadableName(referenceHits.title)}`);
    });
    return options;
  };
  return (
    <div className="form-group">
      {props.label && (
        <label className={props.required ? "required-field mb-1" : "mb-1"} htmlFor={props.fieldName}>
          {props.label}
        </label>
      )}
        <Form.Check
            className="mb-2"
            type="switch"
            id="custom-switch"
            label={<small>Søk kun på ID-nummer</small>}
            checked={isIdSearch}
            onChange={handleToggle}
        />
        <Controller
          as={AsyncSelect}
          control={props.control}
          name={props.fieldName}
          value={props.currentValue ? props.currentValue : null}
          loadOptions={fetchQueryOptions}
          defaultOptions={false}
          required={props.required}
          placeholder={isIdSearch ? "Søk etter referansens ID-nummer..." : "Søk etter referanse..."}
          loadingMessage={() => {
            return "Henter...";
          }}
          noOptionsMessage={() => {
            return "Ingen valg";
          }}
        />

      <FieldValidationError error={props.errors[props.fieldName]}></FieldValidationError>
    </div>
  );
};

export default ReferenceSelectInput;

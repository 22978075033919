import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { DELETE_ARTICLE_MUTATION } from "../../../graphql/mutations";
import { GET_ARTICLE_QUERY } from "../../../graphql/queries";
import { IDeleteArticleMutationInput, IDeleteArticleMutationResult } from "../../../interfaces/mutations/IDeleteArticleMutation";
import { IGetArticleQueryFilter, IGetArticleQueryResult } from "../../../interfaces/queries/IGetArticleQuery";
import Audit from "../../_common/Audit/Audit";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";
import EditArticleForm from "./EditArticleForm/EditArticleForm";
import { ROUTES } from "../../../lib/Router/routes";
import { useAuth } from "../../../lib/hooks/useAuth";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface IEditArticleProps {
  id: number;
}

const EditArticle: React.FunctionComponent<IEditArticleProps> = (props) => {
  const { isAdmin } = useAuth();
  const history = useHistory();

  const { loading, error, data } = useQuery<IGetArticleQueryResult, IGetArticleQueryFilter>(GET_ARTICLE_QUERY, {
    variables: { filter: { id: props.id } },
  });

  const [deleteArticle] = useMutation<IDeleteArticleMutationResult, IDeleteArticleMutationInput>(DELETE_ARTICLE_MUTATION);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.article) return <DataNotFound />;

  const onDeleteArticle = async (Id: number) => {
    const deleteConfirmed = window.confirm(`Er du sikker på at du vil slette artikkel: ${data.article.title}?`);

    if (deleteConfirmed) {
      await deleteArticle({
        variables: {
          input: {
            id: Id,
          },
        },
      })
        .then((result) => {
          if (result.data && mutationIsSuccessful(result.data.deleteArticle)) {
            handleMutationSuccess(result.data);

            history.push(ROUTES.ADMIN.ARTICLES_ROUTE);
          } else {
            handleMutationError();
          }
        })
        .catch((error) => {
          handleMutationError(error);
        });
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h1">{data.article.title}</h1>

        <div>
          {isAdmin() && (
            <ButtonToolbar className="d-flex justify-content-end">
              <DropdownButton size="sm" variant="secondary" alignRight id="dropdown-basic-button" title="Flere valg">
                <Dropdown.Item
                  className="text-danger"
                  eventKey="1"
                  onClick={() => {
                    onDeleteArticle(data.article.id);
                  }}>
                  <FiTrash2 /> Slett artikkel
                </Dropdown.Item>
              </DropdownButton>
            </ButtonToolbar>
          )}

          <Audit audit={data.article.audit}></Audit>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <EditArticleForm article={data.article}></EditArticleForm>
        </div>
      </div>
    </div>
  );
};

export default EditArticle;

import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { GET_TEACHING_SUBJECTS_QUERY } from "../../../graphql/queries";
import { IGetTeachingSubjectsQueryResult } from "../../../interfaces/queries/IGetTeachingSubjectsQuery";
import { GET_TEACHING_SUBJECT_ROUTE } from "../../../lib/Router/routes";
import DataNotFound from "../../_common/DataNotFound/DataNotFound";
import Loading from "../../_common/Loading/Loading";
import QueryError from "../../_common/QueryError/QueryError";

const TeachingSubjectsList: React.FunctionComponent = () => {
  const { loading, error, data } = useQuery<IGetTeachingSubjectsQueryResult>(GET_TEACHING_SUBJECTS_QUERY);

  if (error) return <QueryError apolloError={error} />;
  if (loading) return <Loading />;
  if (!data || !data.teachingSubjects || !data.teachingSubjects.length) return <DataNotFound />;

  return (<div className="TeachingSubjectsList">
    <table className="table table-hover table-bordered">
      <thead>
        <tr>
          <th scope="col">Tittel</th>
          <th scope="col">Fag</th>
          <th scope="col">Rekkefølge</th>
        </tr>
      </thead>

      <tbody>
        {data.teachingSubjects.map((teachingSubject) => (
          <tr key={teachingSubject.id}>
            <td>
              <Link to={GET_TEACHING_SUBJECT_ROUTE(teachingSubject.id)}>{teachingSubject.title}</Link>
            </td>
            <td>{teachingSubject.title}</td>
            <td>{teachingSubject.displayOrder}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
}

export default TeachingSubjectsList;

import * as yup from "yup";
import { passwordSchema } from "./passwordSchema";

export const userSchema = yup.object({
  email: yup
    .string()
    .email("Må være en gyldig e-post")
    .required("Må fylles ut"),
  password: passwordSchema,
  fullName: yup.string()
});

import { useMutation } from "@apollo/client";
import WYSIWYGEditor from "../../_common/Form/WYSIWYGEditor/WYSIWYGEditor";
import { useForm } from "react-hook-form";
import { CREATE_ASSIGNMENT_MUTATION } from "../../../graphql/mutations";
import { handleMutationError, handleMutationSuccess, mutationIsSuccessful } from "../../../helpers/formHelpers";
import { assignmentSchema } from "../../../helpers/validators/assignmentSchema";
import { IAssignmentForm } from "../../../interfaces/forms/IAssignmentForm";
import { ICreateAssignmentMutationInput, ICreateAssignmentMutationResult } from "../../../interfaces/mutations/ICreateAssignmentMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { GET_TEACHING_SUBJECT_ROUTE } from "../../../lib/Router/routes";
import { useHistory } from "react-router-dom";

interface ICreateAssignmentProps {
  teachingSubjectId: number;
}

const CreateAssignmentForm: React.FunctionComponent<ICreateAssignmentProps> = (props) => {
  const history = useHistory();
  const { register, handleSubmit, errors, formState, getValues, setValue } = useForm<IAssignmentForm>({
    validationSchema: assignmentSchema,
    mode: "onChange",
  });

  const [createAssignmentMutation] = useMutation<ICreateAssignmentMutationResult, ICreateAssignmentMutationInput>(
    CREATE_ASSIGNMENT_MUTATION
  );

  const onSubmit = handleSubmit(async (formValues) => {
    await createAssignmentMutation({
      variables: {
        input: {
          title: formValues.title,
          displayOrder: formValues.displayOrder,
          description: formValues.description,
          hint: formValues.hint,
          teachingSubjectId: props.teachingSubjectId,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createAssignment)) {
          handleMutationSuccess(result.data, "Oppgave opprettet.");

          history.push(GET_TEACHING_SUBJECT_ROUTE(props.teachingSubjectId));
        } else {
          handleMutationError(undefined, "Kunne ikke opprette oppgave");
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="form-group">
            <label className="required-field" htmlFor="title">
              Oppgavetittel
            </label>
            <input
              type="text"
              name="title"
              ref={register}
              className={`form-control ${errors.title ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />
            <FieldError error={errors.title}></FieldError>
          </div>

          <div className="form-group">
            <label className="required-field" htmlFor="displayOrder">
              Rekkefølge
            </label>
            <input
              type="number"
              name="displayOrder"
              ref={register}
              className={`form-control ${errors.displayOrder ? "is-invalid" : "valid"}`}
              autoComplete="off"
            />
            <FieldError error={errors.displayOrder}></FieldError>
          </div>
          {/*
          <div className="form-group">
            <label className="required-field" htmlFor="description">Beskrivelse</label>
            <textarea name="description" ref={register} className={`form-control ${errors.description ? "is-invalid" : "valid"}`} autoComplete="off" />
            <FieldError error={errors.description}></FieldError>
          </div> */}

          <div className="form-group">
            <label className="required-field" htmlFor="hint">
              Hints
            </label>
            <WYSIWYGEditor
              fieldName="hint"
              label="Innhold"
              register={register}
              errors={errors}
              setValue={setValue}
              currentValue={getValues().hint}
            />
            <FieldError error={errors.hint}></FieldError>
          </div>

          <WYSIWYGEditor
            fieldName="description"
            label="Innhold"
            register={register}
            errors={errors}
            setValue={setValue}
            currentValue={getValues().description}
          />

          <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
            {formState.isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span> Oppretter...</span>
              </>
            ) : (
              <span>Opprett og tilknytt</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateAssignmentForm;

import { ICertaintyEnum } from "../interfaces/domain/enums/ICertaintyEnum";
import { IGenderEnum } from "../interfaces/domain/enums/IGenderEnum";
import { ICivilStatusEnum } from "../interfaces/domain/enums/ICivilStatusEnum";
import { IYearOfOriginEnum } from "../interfaces/domain/enums/IYearOfOriginEnum";
import { IEndingEnum } from "../interfaces/domain/enums/IEndingEnum";
import { IReferenceCategoryEnum } from "../interfaces/domain/enums/IReferenceCategoryEnum";
import { IEventTypeEnum } from "../interfaces/domain/enums/IEventTypeEnum";
import { ISelectOption } from "../interfaces/domain/common/ISelectOption";

export const GetReadableCertaintyName = (certainty: ICertaintyEnum) => {
  switch (certainty) {
    case ICertaintyEnum.CERTAIN:
      return "Eksakt";
    case ICertaintyEnum.ASSUMED:
      return "Antatt";
    case ICertaintyEnum.APPROXIMATELY:
      return "Cirka";
    case ICertaintyEnum.UNCERTAIN:
      return "Usikker";
    case ICertaintyEnum.BEFORE:
      return "Før";
    case ICertaintyEnum.AFTER:
      return "Etter";
    default:
      return "";
  }
};

export const GetReadableGenderName = (gender: IGenderEnum) => {
  switch (gender) {
    case IGenderEnum.MALE:
      return "Mann";
    case IGenderEnum.FEMALE:
      return "Kvinne";
    case IGenderEnum.UNKNOWN:
      return "Ukjent";
    default:
      return "";
  }
};

export const GetReadableverifiedFromSourceName = (verified: boolean) => {
  switch (verified) {
    case true:
      return "Ja";
    case false:
      return "Nei";
    default:
      return "";
  }
};

export const GetReadableYearOfOrigin = (year: IYearOfOriginEnum) => {
  switch (year) {
    case IYearOfOriginEnum.YEAR2020:
      return "2020";
    case IYearOfOriginEnum.YEAR1947:
      return "1947";
    default:
      return "";
  }
};

export const GetReadableName = (name: string) => {
  if (name != null) {
    return name;
  } else {
    return "";
  }
};

export const GetReadableCivilStatusName = (civilStatus: ICivilStatusEnum) => {
  switch (civilStatus) {
    case ICivilStatusEnum.MARRIED:
      return "Gift";
    case ICivilStatusEnum.NOTMARRIED:
      return "Ugift";
    case ICivilStatusEnum.DIVORCED:
      return "Skilt";
    case ICivilStatusEnum.SEPARATED:
      return "Separert";
    case ICivilStatusEnum.WIDOW:
      return "Enke";
    case ICivilStatusEnum.WIDOWER:
      return "Enkemann";
    case ICivilStatusEnum.UNKNOWN:
      return "Ukjent";
    default:
      return "";
  }
};

export const GetReadableEndingName = (gender: IEndingEnum) => {
  switch (gender) {
    case IEndingEnum.PASSED:
      return "Død";
    case IEndingEnum.RELEASED:
      return "Løslatt";
    case IEndingEnum.TRANSFER:
      return "Overført";
    case IEndingEnum.UNTIL_PEACE:
      return "Til freden";
    case IEndingEnum.ESCAPED:
      return "Rømt";
    case IEndingEnum.FREED:
      return "Befridd";
    case IEndingEnum.FLED:
      return "Flyktet";
    case IEndingEnum.EXECUTED:
      return "Henrettet";
    case IEndingEnum.UNKNOWN:
      return "Ukjent";
    default:
      return "";
  }
};

export const GetReadableReferenceCategoryName = (referenceCategory: IReferenceCategoryEnum) => {
  switch (referenceCategory) {
    case IReferenceCategoryEnum.DOCUMENT:
      return "Dokument";
    case IReferenceCategoryEnum.ARCHIVE:
      return "Arkiv";
    case IReferenceCategoryEnum.BOOK:
      return "Bok";
    case IReferenceCategoryEnum.ARTICLE:
      return "Artikkel";
    case IReferenceCategoryEnum.ITEM:
      return "Gjenstand";
    case IReferenceCategoryEnum.IMAGE:
      return "Bilde";
    case IReferenceCategoryEnum.VIDEO:
      return "Video";
    case IReferenceCategoryEnum.WEBSITE:
      return "Nettsted";
    case IReferenceCategoryEnum.NEWSPAPER:
      return "Avis";
    case IReferenceCategoryEnum.SOUND:
      return "Lyd";
    case IReferenceCategoryEnum.OTHER:
      return "Annet";
    default:
      return "";
  }
};

export const GetCertaintyEnumAsSelectOptions = () => {
  return Object.values(ICertaintyEnum).map((value) => {
    let selectOption: ISelectOption = {
      value: value,
      label: GetReadableCertaintyName(value as ICertaintyEnum),
    };

    return selectOption;
  });
};

export const GetGenderEnumAsSelectOptions = () => {
  return Object.values(IGenderEnum).map((value) => {
    let selectOption: ISelectOption = {
      value: value,
      label: GetReadableGenderName(value as IGenderEnum),
    };

    return selectOption;
  });
};

export const GetCivilStatusEnumAsSelectOptions = () => {
  return Object.values(ICivilStatusEnum).map((value) => {
    let selectOption: ISelectOption = {
      value: value,
      label: GetReadableCivilStatusName(value as ICivilStatusEnum),
    };

    return selectOption;
  });
};

export const GetEndingEnumAsSelectOptions = () => {
  return Object.values(IEndingEnum).map((value) => {
    let selectOption: ISelectOption = {
      value: value,
      label: GetReadableEndingName(value as IEndingEnum),
    };

    return selectOption;
  });
};

export const GetReferenceCategoryEnumAsSelectOptions = () => {
  return Object.values(IReferenceCategoryEnum).map((value) => {
    let selectOption: ISelectOption = {
      value: value,
      label: GetReadableReferenceCategoryName(value as IReferenceCategoryEnum),
    };

    return selectOption;
  });
};

export const CheckCapativityRelatedEventType = (eventType: string) => {
  switch (eventType) {
    case IEventTypeEnum.ARREST:
      return true;
    default:
      return false;
  }
};

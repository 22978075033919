import * as yup from "yup";
import { passwordSchema } from "./passwordSchema";

export const resetPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Må være en gyldig e-post")
    .required("Må fylles ut"),
  resetToken: yup.string().required("Må fylles ut"),
  newPassword: passwordSchema
});

import React from "react";

const NoAccess: React.FunctionComponent = props => {
  return (
    <div className="d-flex justify-content-center">
      <div className="m-5 text-center">
        <h1 className="display-4 text-dark">Ingen tilgang...</h1>
        <p className="lead">Du har ikke tilgang til denne siden.</p>
      </div>
    </div>
  );
};

export default NoAccess;

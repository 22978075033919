import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { UPDATE_NATIONALITY_MUTATION } from "../../../graphql/mutations";
import { nationalitySchema } from "../../../helpers/validators/nationalitySchema";
import { INationality } from "../../../interfaces/domain/INationality";
import { INationalityForm } from "../../../interfaces/forms/INationalityForm";
import {
  IUpdateNationalityMutationInput,
  IUpdateNationalityMutationResult,
} from "../../../interfaces/mutations/IUpdateNationalityMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { ROUTES } from "../../../lib/Router/routes";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

interface IEditNationalityFormProps {
  nationality: INationality;
}

const EditNationalityForm: React.FunctionComponent<IEditNationalityFormProps> = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState } = useForm<INationalityForm>({
    validationSchema: nationalitySchema,
    mode: "onChange",
    defaultValues: {
      name: props.nationality.name,
    },
  });

  const [updateNationality] = useMutation<IUpdateNationalityMutationResult, IUpdateNationalityMutationInput>(UPDATE_NATIONALITY_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await updateNationality({
      variables: {
        input: {
          id: props.nationality.id,
          name: formValues.name,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.updateNationality)) {
          handleMutationSuccess(result.data);
          history.push(ROUTES.ADMIN.NATIONALITIES_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Lagrer...</span>
          </>
        ) : (
          <span>Lagre</span>
        )}
      </button>
    </form>
  );
};

export default EditNationalityForm;

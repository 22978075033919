import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { CREATE_ROLE_MUTATION } from "../../../graphql/mutations";
import { roleSchema } from "../../../helpers/validators/roleSchema";
import { IRoleForm } from "../../../interfaces/forms/IRoleForm";
import { ICreateRoleMutationInput, ICreateRoleMutationResult } from "../../../interfaces/mutations/ICreateRoleMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { ROUTES } from "../../../lib/Router/routes";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";

const CreateRoleForm: React.FunctionComponent = (props) => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState } = useForm<IRoleForm>({
    validationSchema: roleSchema,
    mode: "onChange",
  });

  const [createRole] = useMutation<ICreateRoleMutationResult, ICreateRoleMutationInput>(CREATE_ROLE_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await createRole({
      variables: {
        input: {
          name: formValues.name,
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createRole)) {
          handleMutationSuccess(result.data);
          history.push(ROUTES.ADMIN.ROLES_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Oppretter...</span>
          </>
        ) : (
          <span>Opprett</span>
        )}
      </button>
    </form>
  );
};

export default CreateRoleForm;

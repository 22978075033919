export enum IEndingEnum {
  TRANSFER = "TRANSFER",
  RELEASED = "RELEASED",
  UNTIL_PEACE = "UNTIL_PEACE",
  PASSED = "PASSED",
  ESCAPED = "ESCAPED",
  FREED = "FREED",
  EXECUTED = "EXECUTED",
  FLED = "FLED",
  UNKNOWN = "UNKNOWN"
}

import React from "react";
import { IEntityReference } from "../../../interfaces/domain/IEntityReference";
import EntityReferences from "../../Reference/EntityReferences/EntityReferences";
import { IPrisonCamp } from "../../../interfaces/domain/IPrisonCamp";
import { IEntityReferenceTypeEnum } from "../../../interfaces/forms/IEntityReferenceForm";

interface PrisonCampReferencesProps {
  prisonCampReferences: Array<IEntityReference<IPrisonCamp>>;
  prisonCampId: number;
}

const PrisonCampReferences: React.FunctionComponent<PrisonCampReferencesProps> = props => {
  return (
    <EntityReferences
      entityReferences={props.prisonCampReferences}
      entityType={IEntityReferenceTypeEnum.PRISON_CAMP}
      entityId={props.prisonCampId}></EntityReferences>
  );
};

export default PrisonCampReferences;

import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { CREATE_PROFESSION_MUTATION } from "../../../graphql/mutations";
import { professionSchema } from "../../../helpers/validators/professionSchema";
import { IProfessionForm } from "../../../interfaces/forms/IProfessionForm";
import { ICreateProfessionMutationInput, ICreateProfessionMutationResult } from "../../../interfaces/mutations/ICreateProfessionMutation";
import FieldError from "../../_common/Form/FieldError/FieldError";
import { ROUTES } from "../../../lib/Router/routes";
import { mutationIsSuccessful, handleMutationSuccess, handleMutationError } from "../../../helpers/formHelpers";
import IndustriesSelectInput from "../../Industry/IndustriesSelectInput/IndustriesSelectInput";
import { IIndustry } from "../../../interfaces/domain/IIndustry";

const CreateProfessionForm: React.FunctionComponent = () => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState, control, getValues } = useForm<IProfessionForm>({
    validationSchema: professionSchema,
    mode: "onChange",
  });

  const [createProfession] = useMutation<ICreateProfessionMutationResult, ICreateProfessionMutationInput>(CREATE_PROFESSION_MUTATION);

  const onSubmit = handleSubmit(async (formValues) => {
    await createProfession({
      variables: {
        input: {
          name: formValues.name,
          industries: formValues.industries?.map((industry) => {
            return { id: industry.value, name: industry.label } as IIndustry;
          }),
        },
      },
    })
      .then((result) => {
        if (result.data && mutationIsSuccessful(result.data.createProfession)) {
          handleMutationSuccess(result.data);
          history.push(ROUTES.ADMIN.PROFESSIONS_ROUTE);
        } else {
          handleMutationError();
        }
      })
      .catch((error) => {
        handleMutationError(error);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <label className="required-field" htmlFor="name">
          Navn
        </label>
        <input type="text" name="name" ref={register} className={`form-control ${errors.name ? "is-invalid" : "valid"}`} />

        <FieldError error={errors.name}></FieldError>
      </div>

      <div className="form-group">
        <IndustriesSelectInput
          fieldName="industries"
          label="Bransjer"
          control={control}
          errors={errors}
          currentValues={getValues().industries}
        />
      </div>

      <button type="submit" disabled={!formState.isValid || formState.isSubmitting} className="btn btn-primary">
        {formState.isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Oppretter...</span>
          </>
        ) : (
          <span>Opprett</span>
        )}
      </button>
    </form>
  );
};

export default CreateProfessionForm;

import * as yup from "yup";

export const createEntityReferenceSchema = yup.object({
  title: yup.string().required("Må fylles ut"),
  category: yup.object({
    value: yup.string().required("Må fylles ut"),
  }),
  showEmbedded: yup.bool(),
  url: yup.string().url("Nettadressen må være fullstendig. Eksempel: https://www.arkivet.no/")
    .when('showEmbedded', {
      is: (showEmbedded: boolean) => showEmbedded === true,
      then: yup.string().required("Nettadressen må spesifiseres før avspilleren kan vises")
    }),
  contextualUrl: yup.string().url("Nettadressen må være fullstendig. Eksempel: https://www.arkivet.no/"),
});
